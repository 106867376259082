import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Schema from "./Schema";
import {
  InvalidText,
  getLocalShop,
  messageError,
  messageSuccess,
} from "../../utils";
import { NumericFormat } from "react-number-format";
export default function EditData({ onSuccess, _data }: any) {
  const [show, setShow] = useState(false);
  const [localShop, setLocalShop] = useState<any>({});

  const [updateDataBraCode, { loading: createTing }]: any = useMutation(
    Schema.updateGenCode
  );
  useEffect(() => {
    setLocalShop(getLocalShop());
  }, []);
  const {
    handleChange,
    errors,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      title: "",
      description: "",
      price: 0,
    },
    enableReinitialize: false,
    validate: (values) => {
      const errors: any = {};
      if (!values.title) {
        errors.title = "ປ້ອນຊື່ສິນຄ້າ ບໍ່ຄວນເກີນ 50 ຕົວອັນສອນ";
      }
      if (!values.price) {
        errors.price = "ກະລຸນາປ້ອນແພັກເກັຈ";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await updateDataBraCode({
          variables: {
            data: {
              price: values.price,
              shop: localShop?._id,
              title: values.title,
              description: values.description,
            },
            where: {
              _id: _data?._id,
            },
          },
        });
        messageSuccess("ດຳເນີນງານສຳເລັດແລ້ວ");
        setShow(false);
        resetForm();
        onSuccess();
      } catch (error) {
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
      }
    },
  });
  useEffect(() => {
    if (!_data) return;
    setFieldValue("title", _data?.title);
    setFieldValue("price", _data?.price);
    setFieldValue("description", _data?.description);
  }, [_data]);
  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm"
        onClick={() => setShow(true)}
      >
        <i className="icon-edit" />
      </button>
      <Modal show={show} size="lg">
        <Modal.Header
          onHide={() => {
            setShow(false);
            resetForm();
          }}
        >
          <h3 className="text-center">
            <i className="icon-plus-circle me-1" />
            ແກ້ໄຂຂໍ້ມູນ
          </h3>
          {createTing ? (
            <>
              <a href="javascript:void(0)" className="float-end">
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                href="javascript:void(0)"
                className="float-end"
                onClick={() => {
                  setShow(false);
                }}
              >
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <form>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ຊື່ສິນຄ້າ {InvalidText()}
                </label>
                <input
                  type="text"
                  autoFocus
                  className={
                    errors.title
                      ? "form-control form-control-lg is-invalid"
                      : "form-control form-control-lg  invalid"
                  }
                  placeholder="ປ້ອນຊື່ສິນຄ້າ ບໍ່ຄວນເກີນ 50 ຕົວອັນສອນ"
                  name="title"
                  value={String(values.title)}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ລາຄາ {InvalidText()}
                </label>
                <NumericFormat
                  className={
                    errors.price
                      ? "form-control form-control-lg is-invalid"
                      : "form-control form-control-lg  invalid"
                  }
                  allowLeadingZeros={true}
                  thousandSeparator={true}
                  decimalScale={2}
                  name="price"
                  placeholder="..."
                  onValueChange={(e) => {
                    setFieldValue("price", e.floatValue);
                  }}
                  value={values.price}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ຄຳອະທີບາຍ
                </label>
                <textarea
                  name="description"
                  className="form-control form-control-lg"
                  placeholder="ຄຳອະທີບາຍ"
                  rows={4}
                  value={String(values.description)}
                  onChange={handleChange}
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-lg"
            disabled={createTing}
            onClick={() => handleSubmit()}
          >
            <i className="icon-check-circle" />
            <span className="ms-1">ບັນທຶກ</span>
          </button>
          <button
            type="button"
            className="btn btn-light btn-lg"
            disabled={createTing}
            onClick={() => (setShow(false), resetForm())}
          >
            <i className="icon-x" />
            <span className="ms-1">ຍົກເລີກ</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
