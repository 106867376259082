import React, { useEffect, useRef, useState } from "react";
import {
  currency,
  formatDateTime,
  getLocalShop,
  getStaffLogin,
} from "../../utils";
import "./index.scss";
import { formatDate } from "../../utils";
import logo from "../../assets/images/cart.png";
import Barcode from "react-barcode";
import { url_path_images } from "../../utils/url";

export default function PrintBill({ data }: any) {
  const [localShop, setLocalShop] = useState<any>();
  useEffect(() => {
    setLocalShop(getLocalShop());
  }, []);
  const barCodeOptions: any = {
    format: "CODE128",
    // displayValue: false,
    fontSize: 20,
    background: "#ffffff",
    lineColor: "#000000",
    margin: 0,
    marginRight: 5,
  };

  return (
    <>
      <div id="barcode-bill">
        <table
          style={{
            width: "100%",
          }}
        >
          <tbody>
            <tr
              style={{
                borderTop: "1px solid black",
              }}
            >
              <td
                width="20px"
                style={{
                  border: "0px solid",
                  borderTop: "0px",
                  borderLeft: "0px",
                  borderBottom: "0px",
                  paddingTop: "0px",
                }}
              >
                {/* <img
                  src={logo}
                  style={{
                    display: "block",
                    maxHeight: "100px",
                    width: "auto",
                    marginBottom: "10px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                /> */}
                {localShop?.shop?.profileImage ? (
                  <>
                    <img
                      className="img-xl  center"
                      style={{
                        display: "block",
                        maxHeight: "100px",
                        width: "auto",
                        marginBottom: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      src={
                        localShop?.shop?.profileImage
                          ? `${url_path_images}${localShop?.shop?.profileImage}`
                          : logo
                      }
                      alt="Profile"
                      loading="lazy"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={logo}
                      style={{
                        display: "block",
                        maxHeight: "100px",
                        width: "auto",
                        marginBottom: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </>
                )}
              </td>

              <td
                width="50%"
                style={{
                  border: "0px solid",
                  borderTop: "0px",
                  borderBottom: "0px",
                  color: "white",
                  verticalAlign: "middle",
                }}
                colSpan={1}
              >
                <b
                  className="text-dark"
                  style={{
                    fontWeight: 500,
                    margin: "-23px",
                    fontFamily: '"LAOS"',
                    color: "black",
                    textAlign: "start",
                    fontSize: "19px",
                  }}
                >
                  <strong>
                    ຮ້ານ: {data?.shop?.title ? data?.shop?.title : "HappyShop"}
                  </strong>
                </b>
                <br />
                <b
                  className="text-dark"
                  style={{
                    fontWeight: 500,
                    margin: "-23px",
                    fontFamily: '"LAOS"',
                    color: "black",
                    textAlign: "start",
                    fontSize: "19px",
                  }}
                >
                  <strong>
                    FB:{" "}
                    {data?.shop?.pageName ? data?.shop?.pageName : "HappyShop"}
                  </strong>
                </b>
                <br />
                <b
                  className="text-dark"
                  style={{
                    fontWeight: 500,
                    margin: "-23px",
                    fontFamily: '"LAOS"',
                    color: "black",
                    textAlign: "start",
                    fontSize: "19px",
                  }}
                >
                  <strong>
                    ໂທ & ແອັບ:{" "}
                    {data?.shop?.phoneNumber ? data?.shop?.phoneNumber : ""}
                  </strong>
                </b>
              </td>
            </tr>
            <tr
              style={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <td width="50%" className="text-nowrap">
                <strong style={{ fontSize: "18px", fontWeight: 500 }}>
                  ລະຫັດອໍເດີ່ :{" "}
                  <b className="customFontCode fs-4">
                    {" "}
                    {data?.trackingId ? data?.trackingId : data?.orderID}
                  </b>
                </strong>
                <br />
                <strong style={{ fontSize: "16px" }}>
                  ວັນທີ່ຝາກ: {formatDate(data?.createdAt)}
                </strong>
              </td>
              <td
                style={{
                  borderLeft: "1px solid black",
                  fontWeight: 500,
                }}
                width="10px"
              >
                <center>
                  <Barcode
                    value={data?.orderID}
                    {...barCodeOptions}
                    height={36}
                  />
                </center>
              </td>
            </tr>
            <tr>
              <td width="50%" colSpan={2} height="100%">
                <b style={{ fontSize: "18px", fontWeight: 500 }}>
                  {data?.description
                    .split("\n", 5)
                    .map((i: any, index: number) => {
                      return (
                        <>
                          <strong key={index}>{i}</strong> <br />
                        </>
                      );
                    })}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
