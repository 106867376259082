import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { endOfMonth, startOfMonth, startOfMonthThree, toDayDate } from "..";
type DateRangeProps = {
  hideStartDate?: boolean;
  hideEndDate?: boolean;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  isToday?: boolean;
};
export default function SearchDateRange({
  hideStartDate,
  hideEndDate,
  containerClass,
  containerStyle,
  isToday,
}: DateRangeProps) {
  const [defaultStartDate, setDefaultStartDate] = useState("");
  const [defaultEndDate, setDefaultEndDate] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const _start = searchParams.get("startDate");
    const _end = searchParams.get("endDate");
    const _isToday = isToday ? toDayDate("YYYY-MM-DD") : startOfMonthThree();
    const _isEndDate = isToday ? toDayDate("YYYY-MM-DD") : endOfMonth();

    setDefaultStartDate(_start ? _start : _isToday);
    setDefaultEndDate(_end ? _end : _isEndDate);
  }, [searchParams, isToday]);

  return (
    <div className={`search-date-range ${containerClass}`} style={containerStyle}>
      <div className="input-group">
        {!hideStartDate && (
          <Form.Control
            type="date"
            className="form-control form-control-lg"
            value={defaultStartDate}
            onChange={(e) => {
              if (searchParams.get("page")) {
                searchParams.set("page", "1");
              }
              searchParams.set("startDate", e.target.value);
              setSearchParams(searchParams);
            }}
          />
        )}
        {!hideEndDate && (
          <Form.Control
            type="date"
            className="form-control form-control-lg"
            value={defaultEndDate}
            onChange={(e) => {
              if (searchParams.get("page")) {
                searchParams.set("page", "1");
              }
              searchParams.set("endDate", e.target.value);
              setSearchParams(searchParams);
            }}
          />
        )}
        {/* <Button
          disabled={!defaultStartDate || !defaultEndDate}
          onChange={() => {
            if (searchParams.get("page")) {
              searchParams.set("page", "1");
            }
            searchParams.set("startDate", defaultStartDate);
            searchParams.set("endDate", defaultEndDate);
            setSearchParams(searchParams);
          }}
        >
          <i className="icon-search1 me-1 fs-5" />
          ຄົ້ນຫາ
        </Button> */}
      </div>
    </div>
  );
}
