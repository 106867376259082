import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "./Schema";
import { useEffect, useState } from "react";
import "./index.scss";
import ResponsivePagination from "react-responsive-pagination";
import {
  ItemSelect,
  NO,
  closeLoadingScreen,
  currency,
  formatDateTime,
  getLocalShop,
  getStaffLogin,
  loadingScreen,
  messageError,
  messageSuccess,
  messageWarning,
} from "../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootVariable } from "../../utils";
import LimitData from "../../utils/components/LimitData";
import Notiflix, { Loading } from "notiflix";
import { Paths } from "../../routes/Path";
import { getUserRole } from "../../utils";
import { Form, Spinner } from "react-bootstrap";
import NoData from "../../utils/components/NoData";
import moment from "moment";

export default function OrderDeleted() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [userAuth, setUserAuth] = useState<any>({});
  const [localShop, setLocalShop] = useState<any>();

  const [startDate, setStartDate]: any = useState(null);
  const [endDate, setEndDate]: any = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(RootVariable.limit);
  const [totalItem, setTotalItem] = useState(0);
  const [selectedAllItem, setSelectedAllItem] = useState<any>(0);
  const [status, setStatus] = useState("");
  const [listOrder, setListOrder] = useState([]);
  const [newRefresh, setNewRefresh] = useState(false);
  const [deleteOrder] = useMutation(Schema.deleteOrder);
  const [updateMany] = useMutation(Schema.updateManyItem);
  const today = moment().format("YYYY-MM-DD");
  useEffect(() => {
    setUserAuth(getStaffLogin());
    setLocalShop(getLocalShop());
  }, []);

  const [queryOrder, { loading: loadingData }] = useLazyQuery(
    Schema.queryOrder,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setListOrder(data?.orders?.data);
        setTotalItem(data?.orders?.total);
      },
    }
  );

  useEffect(() => {
    if (!startDate || !endDate) return;
    let _where = {};
    if (localShop) {
      _where = {
        shop: localShop?._id,
      };
    } else {
      _where = {
        shop: userAuth?.shop?._id,
      };
    }
    queryOrder({
      variables: {
        where: {
          ..._where,
          // createdAtBetween: [startDate, endDate],
          isDeleted: 1,
        },
        limit: numberRows,
        skip: numberRows * (currentPage - 1),
      },
    });
  }, [
    queryOrder,
    newRefresh,
    userAuth,
    localShop,
    numberRows,
    currentPage,
    startDate,
    endDate,
    status,
  ]);

  //pagination
  const totalPage = Math.ceil(totalItem / numberRows);
  useEffect(() => {
    let page = params.get("page");
    let rows = params.get("rows");
    let startDate = params.get("startDate");
    let endDate = params.get("endDate");
    let status = params.get("status");

    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(today);
    }
    if (endDate) {
      setEndDate(endDate);
    } else {
      setEndDate(today);
    }

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(RootVariable.limit);
    }
  }, [params]);

  const _deleteOrder = (id: number) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ລືບແລ້ວບໍ່ສາມາດກູ້ຕືນໄດ້ ຢືນຢັນລືບແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await deleteOrder({
            variables: {
              data: {
                isDeleted: 2, // ລືບຖາວອນ log
              },
              where: {
                _id: Number(id),
              },
            },
          });
          messageSuccess("ການດຳເນີນງານສຳເລັດ");
          setNewRefresh(!newRefresh);
        } catch (error) {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };
  const _deleteOrderSelect = async () => {
    if (!selectedAllItem) {
      return messageWarning("ທ່ານຍັງບໍ່ໄດ້ເລືອກຂໍ້ມູນທື່ລຶບ");
    }
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      `ລືບແລ້ວບໍ່ສາມາດກູ້ຕືນໄດ້  ${currency(
        selectedAllItem?.length
      )} ຢືນຢັນລືບແທ້ ຫຼື ບໍ່?`,
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await updateMany({
            variables: {
              data: {
                isDeleted: 2, // ລືບຖາວອນ ເກັບ ໄວ້ໃນ log
              },
              where: {
                idAll: selectedAllItem,
              },
            },
          });
          setSelectedAllItem(0);
          Notiflix.Loading.remove();
          messageSuccess("ລຶບສຳເລັດ");
          setNewRefresh(!newRefresh);
        } catch (error) {
          Notiflix.Loading.remove();
          messageError("ມີຂໍ້ຜິດພາເເກີດຂື້ນ ກະລູນາລອງໃໝ່");
          console.log(error);
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <div className="page-wrapper">
      <div className="content ms-2">
        <nav aria-label="breadcrumb" className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={() => navigate(Paths.home)}>
                ໜ້າຫຼັກ
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              ລາຍງານຂໍ້ມູນທີ່ລືບ
            </li>
          </ol>
        </nav>

        <div className="row my-3">
          {/* <div className="col-12 col-lg-4 ">
            <div className="input-group">
              <Form.Control
                type="date"
                className="form-control form-control-lg"
                defaultValue={startDate}
                onChange={(e: any) => {
                  params.set("startDate", e.target.value);
                  setParams(params);
                }}
              />
              <Form.Control
                type="date"
                className="form-control form-control-lg"
                value={endDate}
                onChange={(e: any) => {
                  params.set("endDate", e.target.value);
                  setParams(params);
                }}
              />
            </div>
          </div> */}
          {/* of web */}
          <div className="col-12 col-lg-8  hiddenDivOfApp">
            {getUserRole() === "SUPER_ADMIN" || getUserRole() === "OWNER" ? (
              <button
                className="btn btn-danger btn-lg ms-1 fs-5 "
                onClick={() => _deleteOrderSelect()}
                disabled={!selectedAllItem}
              >
                <i className="icon-trash" /> ລຶບບິນ{" "}
                <strong className="text-dark fs-5">
                  {currency(selectedAllItem.length)}
                </strong>{" "}
                ທີ່ເລືອກ
              </button>
            ) : null}
          </div>
          {/* end Of wev */}
          {/* oF Mobile */}
          <div className="col-12 col-lg-8  hiddenDivOfWeb mt-1 text-end">
            {getUserRole() === "SUPER_ADMIN" || getUserRole() === "OWNER" ? (
              <button
                className="btn btn-danger btn-lg ms-1 "
                onClick={() => _deleteOrderSelect()}
                disabled={!selectedAllItem}
                style={{ fontSize: "14px" }}
              >
                ລຶບ{" "}
                <strong className="text-dark fs-5">
                  {currency(selectedAllItem.length)}
                </strong>{" "}
                ບິນ
              </button>
            ) : null}
          </div>
          {/* end Of Mobile */}
        </div>
        <div className="row my-3">
          <LimitData
            numberRows={numberRows}
            totalPage={totalPage}
            currentPage={currentPage}
            total={totalItem}
            hiddenSearch={true}
            col={6}
          />
        </div>

        <div className="row">
          <div className="col-12 p-2 card">
            {loadingData ? loadingScreen() : closeLoadingScreen()}
            <>
              <div className="table-responsive">
                <table className="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="text-center">
                        <input
                          type="checkbox"
                          className="check-box"
                          id="selected-all"
                          onChange={(e) =>
                            setSelectedAllItem(ItemSelect.all(e))
                          }
                        />
                      </th>
                      <th>#</th>
                      <th>ລະຫັດອໍເດີ່</th>
                      <th>ລາຍລະອຽດອໍເດີ່</th>
                      <th>ສະຖານະອໍເດີ່</th>
                      <th>ວັນທີອໍເດີ່</th>
                      <th>ຮັບອໍເດີ່ໂດຍ</th>
                      <th>ຈັດການ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listOrder &&
                      listOrder?.map((item: any, index) => (
                        <tr
                          style={{
                            backgroundColor:
                              item?.orderStatus === "PRINTED" ? "#eafce6" : "",
                          }}
                        >
                          <td className="text-center" style={{ width: 20 }}>
                            <input
                              type="checkbox"
                              className="check-box item-checkbox"
                              value={item?._id}
                              onChange={() =>
                                setSelectedAllItem(ItemSelect.one())
                              }
                            />
                          </td>
                          <td
                            className="text-center text-nowrap"
                            style={{ width: 50 }}
                          >
                            {NO(index, currentPage, numberRows)}
                          </td>
                          <td
                            className="text-center text-nowrap"
                            style={{ width: 120 }}
                          >
                            {item?.orderID ?? "-"}
                          </td>
                          <td width={300}>{item?.description ?? ".."}</td>
                          <td
                            className="text-center text-nowrap"
                            style={{ width: 100 }}
                          >
                            <span
                              className={
                                item?.orderStatus === "PRINTED"
                                  ? "text-success"
                                  : item?.orderStatus === "CANCELED"
                                  ? "badges bg-lightred"
                                  : "text-info"
                              }
                            >
                              {item?.orderStatus === "PRINTED"
                                ? "ພິມບິນແລ້ວ"
                                : item?.orderStatus === "CANCELED"
                                ? "ຍົກເລີກ"
                                : "ລໍຖ້າພິມບິນ"}
                              {item?.createdBy?.fullName && (
                                <>
                                  {" "}
                                  <br /> ລຶບໂດຍ: {item?.updatedBy?.fullName}
                                </>
                              )}
                            </span>
                          </td>
                          <td
                            className="text-center text-nowrap"
                            style={{ width: 120 }}
                          >
                            {formatDateTime(item?.createdAt) ?? "- -"}
                          </td>
                          <td className="text-nowrap" style={{ width: 120 }}>
                            {item?.createdBy?.fullName ?? "- -"}
                            <br />
                            {item?.createdBy?.phoneNumber ?? "- -"}
                          </td>
                          <td className="text-center text-nowrap" width={200}>
                            <div className="btn-group btn-sm py-0">
                              {localShop?._id ? (
                                <>
                                  {getUserRole() === "SUPER_ADMIN" ||
                                  getUserRole() === "OWNER" ? (
                                    <button
                                      className="btn btn-outline-primary my-0"
                                      onClick={(e) => {
                                        _deleteOrder(item?._id);
                                      }}
                                      title="ລຶບບິນ"
                                    >
                                      ລຶບຖາວອນ
                                    </button>
                                  ) : null}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
            <div className="container mt-3">
              {(() => {
                return (
                  <ResponsivePagination
                    total={totalPage}
                    current={currentPage || 1}
                    onPageChange={(page: number) => {
                      params.set("page", page.toString());
                      setParams(params);
                    }}
                  />
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
