import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import Schema from "./Schema";
import { currency } from "..";

type ResponseOnChange = {
  value: number;
  label: string;
};

type ReceiverAddressProps = {
  containerStyle?: React.CSSProperties;
  containerClass?: string;
  disabled?: boolean;
  value?: number;
  getSearch: number;
  onChange?: (e: ResponseOnChange) => void;
  styleConfig?: StylesConfig;
};

export default function SelectPackage({
  containerStyle,
  containerClass,
  disabled,
  value,
  getSearch,
  onChange,
}: ReceiverAddressProps) {
  const [selectedOption, setSelectedOption] = useState<any>();

  const [items, setItems] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [fetchData, { data, loading }] = useLazyQuery(Schema.queryPackage, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
      fetchData({
        variables: {
          where: {
            packageName: searchValue ? searchValue : undefined,
          },
          limit: 30,
        },
      });
  }, [fetchData, searchValue]);

  useEffect(() => {
    if (!data?.packages?.data?.length) {
      setItems([]);
      return;
    }
    if (getSearch) {
      const newData = data?.packages?.data?.map((item: any) => {
        return {
          ...item,
          value: item?._id,
          label:
            item?.title + " |  " + item?.pageName + " | " + item?.phoneNumber,
        };
      });
      setItems([{ value: "", label: "ທັງໝົດ" }, ...newData]);
    } else {
      const newData = data?.packages?.data?.map((item: any) => {
        return {
          ...item,
          value: item?._id,
          label: item?.packageName + " | " + currency(item?.packagePrice),
        };
      });
      setItems(newData);
    }
  }, [data]);

  //set value
  useEffect(() => {
    if (value) {
      const result = items?.filter((item: any) => item?._id === Number(value));
      setSelectedOption(result[0] || null);
    } else {
      setSelectedOption(null);
    }
  }, [items, value]);

  return (
    <div
      style={{ minWidth: 200,height:35, ...containerStyle }}
      className={containerClass}
    >
      <Select
        isDisabled={disabled}
        value={selectedOption}
        isLoading={loading}
        placeholder={
          loading ? "ກຳລັງໂຫຼດ..." : "ເລືອກແພັກເກັຈ"
        }
        onChange={(res: any) => {
          onChange?.(res);
        }}
        options={items}
      />
    </div>
  );
}
