import { gql } from "@apollo/client";

export default class Schema {
  static queryUser = gql`
    query Users($where: UserWhereInput, $limit: Int, $skip: Int) {
      users(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          userProfile
          fullName
          phoneNumber
          role
          isPublic
          shop {
            _id
            shopID
            title
          }
          createdAt
          createdBy {
            _id
            fullName
            phoneNumber
          }
          updatedAt
          updatedBy {
            _id
            fullName
            phoneNumber
          }
        }
      }
    }
  `;
  static createUser = gql`
    mutation CreateUser($data: UserInput!) {
      createUser(data: $data) {
        status
      }
    }
  `;

  static updateUser = gql`
    mutation UpdateUser($data: UserInput!, $where: WhereById!) {
      updateUser(data: $data, where: $where) {
        status
      }
    }
  `;
  static deleteUser = gql`
    mutation DeleteUser($where: WhereById!) {
      deleteUser(where: $where) {
        status
      }
    }
  `;
  static queryOrder = gql`
  query Orders($where: OrderWhereInput, $limit: Int, $skip: Int) {
    orders(where: $where, limit: $limit, skip: $skip) {
      total
      data {
        _id
        orderID
        receiveName
        receivePhone
        isPrinted
        isDeleted
        description
        orderStatus
        createdAt
        createdBy {
          _id
          fullName
          phoneNumber
        }
        shop {
          _id
          title
          shopID
          address
          description
          phoneNumber
          profileImage
        }
      }
    }
  }
`;
static queryShop = gql`
  query Shops($where: ShopWhereInput, $limit: Int, $skip: Int) {
    shops(where: $where, limit: $limit, skip: $skip) {
      total
      data {
        _id
        profileImage
        shopID
        title
        phoneNumber
        pageName
        address
        description
        packagePrice
        isPublic
        createdBy {
          _id
          fullName
          phoneNumber
        }
      }
    }
  }
  `;
  static createOrder = gql`
    mutation CreateOrder($data: OrderInput!) {
      createOrder(data: $data) {
        data
        status
      }
    }
  `;

  static createShop = gql`
    mutation CreateShop($data: ShopInput!) {
      createShop(data: $data) {
        status
      }
    }
  `;

  static updateShop = gql`
  mutation UpdateShop($data: ShopInput!, $where: WhereById!) {
    updateShop(data: $data, where: $where) {
      status
    }
  }
  `;
  static deleteShop = gql`
  mutation DeleteShop($where: WhereById!) {
    deleteShop(where: $where) {
      status
    }
  }
  `;
}
