import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "../routes/Path";
import Notiflix from "notiflix";
import {
  getLocalShop,
  getStaffLogin,
  getUserRole,
  placeholderImage,
  userRoles,
} from "../utils";
import ProfileInfo from "../utils/components/ProfileInfo";
import { url_path_images_user } from "../utils/url";
import male from "../assets/images/male.png";
import SelectShop from "../utils/components/selectShop";
import { IoMdLogOut } from "react-icons/io";
import { userRole } from "../utils";
import { IoReloadOutline } from "react-icons/io5";
import { CiGrid41 } from "react-icons/ci";
import { HiOutlineRefresh } from "react-icons/hi";

export default function NavBar() {
  const [userAuth, setUserAuth] = useState<any>({});
  const [localShop, setLocalShop] = useState<any>();

  useEffect(() => {
    setUserAuth(getStaffLogin());
    setLocalShop(getLocalShop());
  }, []);

  const navigator: any = useNavigate();
  // get path name from location
  const location = useLocation();
  const pathName = location.pathname;
  const [path, setPath] = useState(pathName);
  useEffect(() => {
    setPath(pathName);
  }, [pathName]);

  const _onLogout = async () => {
    Notiflix.Confirm.show(
      "ຢຶນຢັນ",
      "ທ່ານຕ້ອງການອອກຈາກລະບົບແທ້ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async function () {
        localStorage.clear();
        sessionStorage.clear();
        Notiflix.Loading.standard("ກຳລັງດຳເນີນການ...", { svgColor: "#ff5303" });
        setTimeout(() => {
          Notiflix.Loading.remove();
          window.location.replace("/login");
        }, 1500);
      },
      function () {}
    );
  };

  const _onReload = async () => {
    window.location.reload();
  };
  return (
    <>
      <div className="header">
        <div className="header-left active">
          <span className="logo logo-normal text-center">
            <span onClick={() => navigator(Paths.home)}>
              <img
                src="assets/img/logo.png"
                alt=""
                style={{ height: 55, width: "auto" }}
              />
            </span>
          </span>
          <span onClick={() => _onReload()} className="customOfWeb">
            <HiOutlineRefresh className="fs-4 ms-2 mt-2 text-primary" />
            <b style={{ opacity: 0.3 }}>Refresh</b>
          </span>
        </div>
        {/* end */}
        <ul className="nav user-menu">
          <li className="nav-item nav-searchinputs">
            <div className="top-nav-search">
              <div className="searchinputs">
                <h3 className="text-success fw-bolder">
                  ລະບົບຈັດການອໍເດີ່{" "}
                  <strong className="text-primary">Happy Shop</strong>
                </h3>
              </div>
            </div>
          </li>
          {getUserRole() === "SUPER_ADMIN" ? (
            <>
              <SelectShop
                value={localShop?._id ? localShop?._id : userAuth?.shop?._id}
                onChange={(obj: any) => {
                  if (obj?._id) {
                    setLocalShop(obj);
                    localStorage.setItem(
                      "LOCAL_BRANCH_ID",
                      JSON.stringify(obj)
                    );
                    window.location.reload();
                  }
                }}
                getSearch={0}
              />
            </>
          ) : (
            <>
              <SelectShop
                value={localShop?._id ? localShop?._id : userAuth?.shop?._id}
                onChange={(obj: any) => {
                  if (obj?._id) {
                    setLocalShop(obj);
                    localStorage.setItem(
                      "LOCAL_BRANCH_ID",
                      JSON.stringify(obj)
                    );
                    window.location.reload();
                  }
                }}
                disabled={true}
                getSearch={0}
              />
            </>
          )}
          <span className="user-letter bg-white ms-2">
            <img
              src={
                userAuth?.userProfile
                  ? `${url_path_images_user}${userAuth?.userProfile}`
                  : male
              }
              style={{ width: 40, height: 40, borderRadius: 10 }}
              alt=""
              className="img-fluid"
              data-darkbox={
                userAuth?.userProfile
                  ? url_path_images_user + userAuth?.userProfile
                  : placeholderImage(100, 100)
              }
              data-darkbox-group="two"
            />
          </span>
          <span className="user-detail ms-2">
            <span className="user-name">
              {userAuth?.fullName ? userAuth?.fullName : "-"}
            </span>
            <br />
            <span className="user-role">
              {userAuth?.role ? userRole(userAuth?.role) : "-"}
            </span>
          </span>
          <li
            className="nav-item nav-item-box"
            style={{
              height: "40px",
              borderRadius: "20%",
              width: "36px",
              backgroundColor: "#ededed",
            }}
          >
            <b onClick={() => _onLogout()}>
              <IoMdLogOut className="fs-4 ms-2 mt-2 text-primary" />
            </b>
          </li>
        </ul>
        <div className="dropdown mobile-user-menu">
          <a
            href="javascript:void(0);"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={() => navigator(Paths.settings)}
          >
            <CiGrid41 />
          </a>

          <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="javascript:void(0);">
              ໂປຣໄຟລ໌
            </a>
            <a
              className="dropdown-item"
              href="javascript:void(0);"
              onClick={() => _onLogout()}
            >
              {" "}
              ອອກຈາກລະບົບ
            </a>
          </div>
        </div>
        <div className="mobile-user-menu">
          <a
            href="javascript:void(0);"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={() => navigator(Paths.settings)}
          >
            <CiGrid41 />
          </a>
        </div>
      </div>
    </>
  );
}
