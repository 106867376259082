export const Paths = {
  home: "/home",
  login: "/login",
  landing: "/landing",
  register_shop: "/register/shop",
  register_user: "/register/user",
  shop: "/shop",
  member: "/member",
  orders: "/orders",
  invoices: "/invoices",
  settings: "/settings",
  print_all_bill: "/print_all_bill",
  list_order: "/list_order",
  detail_item: "/detail_item",
  detail_item_day: "/detail_item_day",
  list_order_day: "/list_order_day",
  order_deleted: "/order_deleted",
  generate_code: "/generate_code",
  print_generate_code: "/print_generate_code",
  customer_add_order: "/customer_add_order",
  customer_create: "/add",
  support_team: "/support_team",
  member_group: "/menubar_group",
  member_add_shop: "/member_add_shop",
  commission: "/commission",
  //stock
  category: "/category",
  items: "/items",
  importItems: "/import_items",
  exportItems: "/export_items",
  stockItems: "/stock_item",
};
