import { gql } from "@apollo/client";

export default class Schema {
  static Commissions = gql`
    query Commissions($limit: Int, $skip: Int, $where: CommissionWhereInput) {
      commissions(limit: $limit, skip: $skip, where: $where) {
        total
        data {
          _id
          billNO
          createdAt
          description
          isPaid
          month
          isDeleted
          paidBy {
            _id
            fullName
          }
          paidDate
          percent
          shop {
            _id
            accountName
            title
            packagePrice
            phoneNumber
          }
          totalAmount
          totalCommission
          totalShop
          year
          status
        }
      }
    }
  `;
  static createCommission = gql`
    mutation CreateCommission($data: CreateCommission!) {
      createCommission(data: $data) {
        status
      }
    }
  `;
  static updateCommission = gql`
    mutation UpdateCommission($data: CommissionInput!, $where: WhereById!) {
      updateCommission(data: $data, where: $where) {
        status
      }
    }
  `;
  static deleteCommission = gql`
    mutation DeleteCommission($where: WhereById!) {
      deleteCommission(where: $where) {
        status
      }
    }
  `;
  static SummaryCommission = gql`
    query SummaryCommission($where: CommissionWhereInput) {
      summaryCommission(where: $where) {
        totalShop
        totalCommission
        totalAmount
        total
      }
    }
  `;
}
