import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import { useEffect, useState } from "react";
import "./index.scss";
import {
  currency,
  getLocalShop,
  getStaffLogin,
} from "../../utils";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../routes/Path";
import { GrFormNextLink } from "react-icons/gr";

export default function NewInvoice() {
  const navigate = useNavigate();
  const [userAuth, setUserAuth] = useState<any>({});
  const [localShop, setLocalShop] = useState<any>();
  const [invoice, setInvoice]: any = useState([]);
 

  useEffect(() => {
    setUserAuth(getStaffLogin());
    setLocalShop(getLocalShop());
  }, []);

  const [queryInvoice] = useLazyQuery(Schema.InvoiceBill, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setInvoice(data?.invoices?.data);
    },
  });
  

  useEffect(() => {
    setUserAuth(getStaffLogin());
    setLocalShop(getLocalShop());
  }, []);
  
  
  useEffect(() => {
    let _where = {};
    if (localShop) {
      _where = {
        shop: localShop?._id,
      };
    } else {
      _where = {
        shop: userAuth?.shop?._id,
      };
    }
    queryInvoice({
      variables: {
        where: {
          isDeleted: 0,
          isPaid: 0,
          ..._where,
        },
      },
    });
  }, [queryInvoice, userAuth, localShop]);

  return (
    <>
      {invoice &&
        invoice?.map((data: any, index: number) => (
          <>
            <div
              key={index}
              className="card p-1  fa-fade"
              style={{
                cursor: "pointer",
                border: "1px dashed red",
                backgroundColor: "#fffdd1",
              }}
              onClick={() => {
                navigate(Paths.invoices);
              }}
            >
              <h6 className="text-secondary">
                ທ່ານມີບິນໃໝ່ທີ່ຕ້ອງຊຳລະ ເດືອນ ( {data?.forMoth} )
              </h6>
              <span className="my-1 d-flex justify-content-between">
                ເລກບິນ: {data?.invoiceID} , ຈຳນວນເງິນ: {data?.totalAmount ? currency(data?.totalAmount):0} KIP{" "}
                <GrFormNextLink />
              </span>
              <b style={{fontSize:"12px", color:"red"}}>
                ກະລຸນາຊຳລະບໍ່ໃຫ້ເກີນພາຍໃນ 7 ວັນ , ຂໍຂອບໃຈທີ່ໃຊ້ບໍລິການ
              </b>
            </div>
          </>
        ))}
    </>
  );
}
