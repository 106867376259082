import { Button, Card, Form } from "react-bootstrap";
import "./Style.scss";
import logo from ".";
import { useFormik } from "formik";
import {
  notifyError,
  MessageErrors,
  LocalStorageName,
  graphQLErrors,
} from "../../utils";
import { useMutation } from "@apollo/client";
import Notiflix from "notiflix";
import Schema from "./Schema";
import { useState } from "react";
import { Paths } from "../../routes/Path";
import { AiFillEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { FiUserPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

type Values = {
  phoneNumber: number;
  password: string;
};

type Errors = {
  phoneNumber?: string;
  password?: string;
};

export default function Login() {
  const [login] = useMutation(Schema.login);
  const [showPassword, setShowPassword] = useState(false);
  const navigator: any = useNavigate();

  const { handleChange, values, errors, handleSubmit } = useFormik<Values>({
    initialValues: { phoneNumber: 0, password: "" },
    validate: (values: Values) => {
      const errors: Errors = {};
      if (!values?.phoneNumber) errors.phoneNumber = "ກະລຸນາປ້ອນເບີໂທ";
      if (!values?.password) errors.password = "ກະລຸນາປ້ອນລະຫັດຜ່ານ";
      return errors;
    },
    onSubmit: async (values: Values) => {
      Notiflix.Loading.standard("ກຳລັງກວດສອບ...", { svgColor: "#ff5303" });
      try {
        const { data } = await login({
          variables: {
            data: {
              phoneNumber: values?.phoneNumber,
              password: values?.password,
            },
          },
        }).then((res) => res);
        Notiflix.Loading.remove();
        if (data?.userLogin?.accessToken) {
          localStorage.setItem(
            LocalStorageName.token,
            data?.userLogin?.accessToken
          );
          window.location.replace(Paths.home);
          return;
        }
        notifyError(MessageErrors.process);
      } catch (error) {
        Notiflix.Loading.remove();
        const _error = graphQLErrors(error);
        console.log(error);
        if (_error === "PASSWORD_INCORRECT")
          return notifyError(MessageErrors.invalidPassword);
        notifyError(MessageErrors.process);
      }
    },
  });

  return (
    <div className=" app-login">
      <Card>
        <Card.Header className="text-center">
          <img src="assets/img/logo.png" style={{ width: "80%" }} alt="" />
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-2">
              <Form.Label>
                <i className="fas fa-phone" />
                ເບີໂທລະສັບ
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="ເບີໂທລະສັບ"
                onWheel={(e) => e.currentTarget.blur()}
                name="phoneNumber"
                value={values?.phoneNumber || ""}
                onChange={handleChange}
                isInvalid={errors?.phoneNumber ? true : false}
                required
                className="form-control form-control-lg"
              />
              <Form.Control.Feedback type="invalid">
                {errors.phoneNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <i className="fas fa-lock" />
                ລະຫັດຜ່ານ
              </Form.Label>
              <div className="form-input-group-password">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="ລະຫັດຜ່ານ"
                  name="password"
                  value={values?.password}
                  onChange={handleChange}
                  // isInvalid={errors?.password ? true : false}
                  required
                  className="form-control form-control-lg"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <>
                      <AiOutlineEyeInvisible />
                    </>
                  ) : (
                    <>
                      <AiFillEye />
                    </>
                  )}
                </button>
              </div>
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <hr className="my-4" />
            <Button type="submit" variant="primary" className="w-100 btn-lg" disabled={!values?.phoneNumber || !values?.password}>
              <i className="fas fa-right-to-bracket" />
              ເຂົ້າສູ່ລະບົບ
            </Button>
          </Form>
        </Card.Body>
        <span  className=" mx-4 text-end"  onClick={()=>navigator('/register/shop')}>
            <FiUserPlus className="fs-6 text-info"/> <u className="fs-6 text-info">ສະໝັກສະມາຊິກໃໝ່</u>
          </span>
        <center className="my-3" style={{ color: "#bdbebf" }}>
          Version 1.0
        </center>
      </Card>
    </div>
  );
}
