import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as _ from "lodash";
import { Form, Modal } from "react-bootstrap";
import { BsPlusCircleDotted } from "react-icons/bs";
import Schema from "./Schema";
import { InvalidText, messageError, messageSuccess } from "../../utils";
import { MdCategory } from "react-icons/md";
export default function AddCategory({ onCompleted, setOnCompleted }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [createCategory, { loading: adding }] = useMutation(
    Schema.CreateCategory
  );

  const { handleChange, errors, values, handleSubmit, resetForm, isDisabled } =
    useFormik({
      initialValues: {
        title: "",
        description: "",
      },
      enableReinitialize: false,
      validate: (values) => {
        const errors = {};
        if (!values.title) {
          errors.title = "ກະລຸນາປ້ອນປະເພດສິນຄ້າ";
        }
        return errors;
      },
      onSubmit: async (values) => {
        try {
          const { data: created } = await createCategory({
            variables: {
              data: {
                title: values.title,
                description: values.description,
              },
            },
          });
          if (created.createCategory.status===200) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setShow(false);
            setOnCompleted(!onCompleted);
            resetForm();
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
        }
      },
    });

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => setShow(true)}
      >
        <BsPlusCircleDotted className="me-1" />
        ເພີ່ມປະເພດສິນຄ້າ
      </button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <MdCategory className="me-1 fs-4" /> ເພີ່ມປະເພດສິນຄ້າ
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>ປະເພດສິດຄ້າ{InvalidText()}</label>
                <input
                  name="title"
                  type="text"
                  placeholder="ຈັດການໝວດໝູ່(ລາວ)"
                  onChange={handleChange}
                  className={
                    errors.title ? "form-control is-invalid" : "form-control"
                  }
                  value={values.title}
                />
                <i className="text-danger">{errors.title}</i>
              </div>
              <div className="form-group">
                <label>ລາຍລະອຽດ</label>
                <textarea
                  name="description"
                  className="form-control"
                  rows={5}
                  values={values.description}
                  onChange={handleChange}
                  placeholder="ປ້ອນລາຍລະອຽດ(ລາວ)"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleSubmit()}
            disabled={isDisabled || !values.title}
          >
            <i className="icon-check-circle" /> ບັນທຶກ
          </button>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => handleClose()}
          >
            <i className="icon-x me-2" />
            ຍົກເລີກ
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
