import { useEffect, useState } from "react";
import { MdAddTask } from "react-icons/md";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useMutation } from "@apollo/client";
import Schema from "./Schema";
import {
  getLocalShop,
  messageError,
  messageSuccess,
  startOfMonth,
  toDayDash,
} from "../../utils";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import SelectShop from "../../utils/components/selectShop";

export default function CreateSummaryOne({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const [createCommission, { loading }] = useMutation(Schema.createCommission);
  const [getLimit, setLimit] = useState<any>();
  const [show, setShow] = useState<any>(false);
  const [startDate, setStartDate] = useState<any>(startOfMonth());
  const [endDate, setEndDate] = useState<any>(toDayDash());
  const [localShop, setLocalShop] = useState<any>({});
  useEffect(() => {
    setLocalShop(getLocalShop());
  }, []);

  const {
    handleChange,
    errors,
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      description: "",
      shop: "",
    },
    enableReinitialize: false,
    validate: (values) => {
      const errors: any = {};
      if (!localShop?._id) {
        errors.shop = "ກະລຸນາເລືອກຮ້ານ";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await createCommission({
          variables: {
            data: {
              startDate: startDate,
              endDate: endDate,
              description: values.description,
              shop: localShop?._id,
            },
          },
        });
        messageSuccess("ດຳເນີນງານສຳເລັດແລ້ວ");
        setShow(false);
        resetForm();
        onSuccess();
      } catch (error) {
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
      }
    },
  });


  return (
    <>
      <button
        type="button"
        className="btn btn-success btn-lg  ms-2"
        onClick={() => setShow(true)}
      >
        <MdAddTask className="me-1" />
        ສະຫຼຸບຕາມຮ້ານ
      </button>
      <Modal show={show} size="lg">
        <Modal.Header className="bg-primary text-white">
          <h3 className="text-center">
            <FaMoneyBillTrendUp className="me-1" />
            ຟອມສະຫຼຸບເງິນຄອມມິດຊັ່ນຕາມຮ້ານ
          </h3>
          {loading ? (
            <></>
          ) : (
            <>
              <span className="float-end" onClick={() => setShow(false)}>
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </span>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mt-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="fs-5">ວັນທີ່ເລີ່ມ</Form.Label>
                  <SelectShop
                    value={localShop?._id}
                    onChange={(obj: any) => {
                      setLocalShop(obj);
                    }}
                    getSearch={1}
                  />
                </Form.Group>
                <i className="text-danger">{errors.shop}</i>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mt-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="fs-5">ວັນທີ່ເລີ່ມ</Form.Label>
                  <Form.Control
                    type="date"
                    className="form-control form-control-lg border"
                    placeholder="..."
                    name="approvedAt"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mt-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="fs-5"> ວັນທີ່ສຸດທ້າຍ</Form.Label>
                  <Form.Control
                    type="date"
                    className="form-control form-control-lg border"
                    placeholder="..."
                    name="transferredAt"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Form.Group
                className="mt-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fs-5">ຄຳອະທິບາຍ</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="..."
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                  className={errors.description ? "is-invalid" : ""}
                />
              </Form.Group>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <>
              <Spinner animation="border" variant="primary" />
            </>
          ) : (
            <>
              <button
                className="btn btn-success btn-lg"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isSubmitting}
              >
                <AiOutlineCheckCircle style={{ fontSize: "28px" }} /> ຢືນຢັນສ້າງ
              </button>
              <button
                className="btn btn-danger btn-lg"
                onClick={() => {
                  setShow(false);
                }}
                disabled={isSubmitting}
              >
                <i className="icon-x me-1" /> ຍົກເລີກ
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
