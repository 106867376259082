import React, { useEffect, useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { currency, formatDateTime, userRole } from "../../utils";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";

export default function PrintData({ listOrder, total }: any) {
  const elmRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });

  let today = moment().format("YYYY-MM-DD");
  let time = moment().format("HH:mm:ss");
  const [show, setShow] = useState(false);
  return (
    <React.Fragment>
      <button
        className="btn btn-warning btn-lg ms-2 events"
        onClick={() => setShow(true)}
        style={{ fontSize: "20px" }}
      >
        <i className="icon-print" /> ພິມລາຍງານ {""} {currency(total)}
      </button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>
            <i className="fas fa-info-circle me-1" />
            ລາຍງານຂໍ້ມູນ
          </Modal.Title>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="btn btn-danger"
          >
            <i className="icon icon-x mr-1" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-md-12">
              <button
                onClick={handlePrint}
                type="button"
                className="btn btn-dark ml-3 me-2"
              >
                <i className="icon-print mr-1"></i> ພິມ
              </button>
              <DownloadTableExcel
                filename="users table"
                sheet="ລາຍງານຂໍ້ມູນ"
                currentTableRef={elmRef.current}
              >
                <button className="btn btn btn-success"> Export excel </button>
              </DownloadTableExcel>
            </div>
          </Row>
          <div id="table-to-xls" className="container-print" ref={elmRef}>
            <div style={{ paddingBottom: "5px" }} />
            <div className="table-responsive">
              <table className="table-sm " border={1}>
                <thead>
                  <tr>
                    <td
                      colSpan={5}
                      style={{ textAlign: "center", border: "0" }}
                    >
                      <h4>
                        {" "}
                        ລາຍງານຂໍ້ມູນອໍເດີ່ຂອງ ຮ້ານ {listOrder?.[0]?.shop?.title}
                      </h4>
                    </td>
                    <td style={{ border: "0", textAlign: "right" }} colSpan={2}>
                      <span className="text-center">ວັນທີພິມ: {today}</span>
                      <br />
                      <span className="text-center">ເວລາພິມ: {time}</span>
                    </td>
                  </tr>
                  <tr className="text-dark">
                    <th className="text-dark text-center">ລຳດັບ</th>
                    <th className="text-nowrap text-dark">ລະຫັດອໍເດີ່</th>
                    <th className="text-dark">ລະຫັດອໍເດີ່ຄິເອງ</th>
                    <th className="text-nowrap text-dark">ລາຍລະອຽດອໍເດີ່</th>
                    <th className="text-nowrap text-dark text-center">
                      ສະຖານະອໍເດີ່
                    </th>
                    <th className="text-nowrap text-dark">ວັນທີອໍເດີ່</th>
                    <th className="text-nowrap text-dark">ຮັບອໍເດີ່ໂດຍ</th>
                  </tr>
                </thead>
                <tbody>
                  {listOrder?.map(
                    (item: any, index: any) => (
                      (
                        <>
                          <tr>
                            <td
                              className="text-center text-nowrap"
                              style={{ width: 50 }}
                            >
                              {index + 1}
                            </td>
                            <td
                              className="text-center text-nowrap"
                              style={{ width: 120 }}
                            >
                              {item?.orderID ?? "-"}
                            </td>
                            <td style={{ width: 50 }}>{item?.trackingId ?? "- -"}</td>
                            <td width={300}>{item?.description ?? ".."}</td>
                            <td
                              className="text-center text-nowrap"
                              style={{ width: 100 }}
                            >
                              <span
                                className={
                                  item?.orderStatus === "PRINTED"
                                    ? "text-success"
                                    : item?.orderStatus === "CANCELED"
                                    ? "badges bg-lightred"
                                    : "text-info"
                                }
                              >
                                {item?.orderStatus === "PRINTED"
                                  ? "ພິມບິນແລ້ວ"
                                  : item?.orderStatus === "CANCELED"
                                  ? "ຍົກເລີກ"
                                  : "ລໍຖ້າພິມບິນ"}
                              </span>
                            </td>
                            <td
                              className="text-center text-nowrap"
                              style={{ width: 120 }}
                            >
                              {formatDateTime(item?.createdAt) ?? "- -"}
                            </td>
                            <td className="text-nowrap" style={{ width: 120 }}>
                              {item?.createdBy?.fullName ?? "- -"}
                              <br />
                              {item?.createdBy?.phoneNumber ?? "- -"}
                            </td>
                          </tr>
                        </>
                      )
                    )
                  )}
                  <br />
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
