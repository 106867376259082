import React from "react";
import { currency } from "..";

type InputNumberFormatProps = {
  value: number;
  onChange: (val: number) => void;
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  isInvalid?: boolean;
  disabled?: boolean;
};

export default function InputNumberFormat({
  value,
  onChange,
  className,
  style,
  placeholder,
  isInvalid,
  disabled,
}: InputNumberFormatProps) {
  return (
    <>
      <input
        type="text"
        className={`form-control ${className} ${isInvalid ? "is-invalid" : ""}`}
        style={style}
        value={value ? currency(value) : ""}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => {
          const text = e.target.value;
          const result = text.match(/[0-9]/g);
          let value = result?.join("");
          value = value?.replace(/,/g, "");
          const number = Number(value) || 0;
          onChange?.(number);
        }}
      />
    </>
  );
}
