import React, { useEffect, useState } from "react";
import { currency } from "../../utils";
import Schema from "./Schema";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ClockLoader } from "react-spinners";
export default function SummaryCommission({ startDate, endDate }: any) {
  const [transfer, setInvoiceTransfer]: any = useState([]);
  const [summaryAll, setSummaryAll]: any = useState([]);
  const [receiverMoney, setInvoiceReceiverMoney]: any = useState([]);

  const [queryInvoiceNot, { loading: loadingDataNot }] = useLazyQuery(
    Schema.SummaryCommission,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setSummaryAll(data?.summaryCommission);
      },
    }
  );

  const [querySummary, { loading: loadingData }] = useLazyQuery(
    Schema.SummaryCommission,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setInvoiceTransfer(data?.summaryCommission);
      },
    }
  );
  
  const [QueryReceiverMoney, { loading: loadDingReceiverMoney }] = useLazyQuery(
    Schema.SummaryCommission,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setInvoiceReceiverMoney(data?.summaryCommission);
      },
    }
  );
  useEffect(() => {
    queryInvoiceNot({
      variables: {
        where: {
          isDeleted: 0,
          paidDateBetWeen: [startDate, endDate],
        },
      },
    });
  }, [queryInvoiceNot, startDate, endDate]);

  useEffect(() => {
    QueryReceiverMoney({
      variables: {
        where: {
          isDeleted: 0,
          isPaid: 1,
          paidDateBetWeen: [startDate, endDate],
        },
      },
    });
  }, [QueryReceiverMoney, startDate, endDate]);


  useEffect(() => {
    querySummary({
      variables: {
        where: {
          isDeleted: 0,
          isPaid: 1,
          paidDateBetWeen: [startDate, endDate],
        },
      },
    });
  }, [querySummary, startDate, endDate]);



  return (
    <div className="row">
      <div className="col-md-4 col-12 mt-1">
        <div className="card p-3">
          <div className="row">
            <div className="col-3">
              <svg
                width="80px"
                height="80px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    opacity="0.4"
                    d="M3.66992 2.5V14.47C3.66992 15.45 4.12992 16.38 4.91992 16.97L10.1299 20.87C11.2399 21.7 12.7699 21.7 13.8799 20.87L19.0899 16.97C19.8799 16.38 20.3399 15.45 20.3399 14.47V2.5H3.66992Z"
                    fill="#ef0b0b"
                  ></path>{" "}
                  <path
                    d="M22 3.25H2C1.59 3.25 1.25 2.91 1.25 2.5C1.25 2.09 1.59 1.75 2 1.75H22C22.41 1.75 22.75 2.09 22.75 2.5C22.75 2.91 22.41 3.25 22 3.25Z"
                    fill="#ef0b0b"
                  ></path>{" "}
                  <path
                    d="M16 8.75H8C7.59 8.75 7.25 8.41 7.25 8C7.25 7.59 7.59 7.25 8 7.25H16C16.41 7.25 16.75 7.59 16.75 8C16.75 8.41 16.41 8.75 16 8.75Z"
                    fill="#ef0b0b"
                  ></path>{" "}
                  <path
                    d="M16 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25H16C16.41 12.25 16.75 12.59 16.75 13C16.75 13.41 16.41 13.75 16 13.75Z"
                    fill="#ef0b0b"
                  ></path>{" "}
                </g>
              </svg>
            </div>
            <div className="col-9">
              <label className="text-danger">ຍອດເງິນທັງໝົດ</label>
              {loadingData ? (
                <ClockLoader color="#ffff" size={20} />
              ) : (
                <>
                  <h4 className="fw-bolder mt-2">
                    ຍອດທັງໝົດ{" "}
                    {summaryAll?.totalAmount
                      ? currency(summaryAll?.totalAmount)
                      : 0}
                  </h4>
                  <h4 className="fw-bolder mt-2">
                    ລວມຄ່າຄອມມິດຊັ່ນ{" "}
                    {summaryAll?.totalCommission
                      ? currency(summaryAll?.totalCommission)
                      : 0}
                  </h4>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-12 mt-1">
        <div className="card p-3">
          <div className="row">
            <div className="col-3">
              <svg
                width="80px"
                height="80px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    opacity="0.4"
                    d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"
                    fill="#ff5900"
                  ></path>{" "}
                  <path
                    d="M15.5 2V9.85999C15.5 10.3 14.98 10.52 14.66 10.23L12.34 8.09C12.15 7.91 11.85 7.91 11.66 8.09L9.34003 10.23C9.02003 10.53 8.5 10.3 8.5 9.85999V2H15.5Z"
                    fill="#ff5900"
                  ></path>{" "}
                  <path
                    d="M17.5 14.75H13.25C12.84 14.75 12.5 14.41 12.5 14C12.5 13.59 12.84 13.25 13.25 13.25H17.5C17.91 13.25 18.25 13.59 18.25 14C18.25 14.41 17.91 14.75 17.5 14.75Z"
                    fill="#ff5900"
                  ></path>{" "}
                  <path
                    d="M17.5 18.75H9C8.59 18.75 8.25 18.41 8.25 18C8.25 17.59 8.59 17.25 9 17.25H17.5C17.91 17.25 18.25 17.59 18.25 18C18.25 18.41 17.91 18.75 17.5 18.75Z"
                    fill="#ff5900"
                  ></path>{" "}
                </g>
              </svg>
            </div>
            <div className="col-9">
              <label className="text-warning">ຮັບເງິນແລ້ວເດືອນນີ້</label>
              {loadDingReceiverMoney ? (
                <ClockLoader color="#ffff" size={20} />
              ) : (
                <>
                  <h4 className="fw-bolder mt-2">
                    ຍອດທັງໝົດ{" "}
                    {receiverMoney?.totalAmount
                      ? currency(receiverMoney?.totalAmount)
                      : 0}
                  </h4>
                  <h4 className="fw-bolder mt-2">
                    ລວມຄ່າຄອມມິດຊັ່ນ{" "}
                    {receiverMoney?.totalCommission
                      ? currency(receiverMoney?.totalCommission)
                      : 0}
                  </h4>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-12 mt-1">
        <div className="card p-3">
          <div className="row">
            <div className="col-3">
              <svg
                width="80px"
                height="80px"
                viewBox="0 0 24.00 24.00"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#000000"
                stroke-width="0.00024000000000000003"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke="#CCCCCC"
                  stroke-width="0.048"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    opacity="0.4"
                    d="M16.8203 2H7.18031C5.05031 2 3.32031 3.74 3.32031 5.86V19.95C3.32031 21.75 4.61031 22.51 6.19031 21.64L11.0703 18.93C11.5903 18.64 12.4303 18.64 12.9403 18.93L17.8203 21.64C19.4003 22.52 20.6903 21.76 20.6903 19.95V5.86C20.6803 3.74 18.9503 2 16.8203 2Z"
                    fill="#1d9f3e"
                  ></path>{" "}
                  <path
                    d="M14.5 9.89996H12.75V8.20996C12.75 7.79996 12.41 7.45996 12 7.45996C11.59 7.45996 11.25 7.79996 11.25 8.20996V9.89996H9.5C9.09 9.89996 8.75 10.24 8.75 10.65C8.75 11.06 9.09 11.4 9.5 11.4H11.25V13.21C11.25 13.62 11.59 13.96 12 13.96C12.41 13.96 12.75 13.62 12.75 13.21V11.4H14.5C14.91 11.4 15.25 11.06 15.25 10.65C15.25 10.24 14.91 9.89996 14.5 9.89996Z"
                    fill="#1d9f3e"
                  ></path>{" "}
                </g>
              </svg>
            </div>
            <div className="col-9">
              <label className="text-success">ຮັບເງິນແລ້ວທັງໝົດ</label>
              {loadingDataNot ? (
                <ClockLoader color="#ffff" size={20} />
              ) : (
                <>
                  <h4 className="fw-bolder mt-2">
                    ຍອດທັງໝົດ{" "}
                    {transfer?.totalAmount
                      ? currency(transfer?.totalAmount)
                      : 0}
                  </h4>
                  <h4 className="fw-bolder mt-2">
                    ລວມຄ່າຄອມມິດຊັ່ນ{" "}
                    {transfer?.totalCommission
                      ? currency(transfer?.totalCommission)
                      : 0}
                  </h4>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
