import { gql } from "@apollo/client";

export default class Schema {
  static queryOrder = gql`
    query Orders($where: OrderWhereInput, $limit: Int, $skip: Int) {
      orders(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          orderID
          receiveName
          receivePhone
          isPrinted
          isDeleted
          description
          orderStatus
          createdAt
          trackingId
          createdBy {
            _id
            fullName
            phoneNumber
          }
          shop {
            _id
            title
            shopID
            address
            phoneNumber
            profileImage
            pageName
          }
        }
      }
    }
  `;
  static createOrder = gql`
    mutation CreateOrder($data: OrderInput!) {
      createOrder(data: $data) {
        data
        status
      }
    }
  `;
  static updateOrder = gql`
    mutation UpdateOrder($data: OrderInput!, $where: WhereById!) {
      updateOrder(data: $data, where: $where) {
        status
      }
    }
  `;
  static deleteOrder = gql`
    mutation DeleteOrder($where: WhereById!) {
      deleteOrder(where: $where) {
        status
      }
    }
  `;

  static updateManyItem = gql`
    mutation UpdateManyOrder($data: OrderInput!, $where: allID!) {
      updateManyOrder(data: $data, where: $where) {
        status
      }
    }
  `;
  static summaryTotal = gql`
    query SummaryTotal($where: OrderWhereInput) {
      summaryTotal(where: $where) {
        totalNexOrder
        totalPrint
        totalSuccess
      }
    }
  `;
}
