import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import {
  InvalidText,
  LocalStorageName,
  getUserRole,
  messageError,
  messageSuccess,
  onLoading,
  placeholderImage,
} from "../../utils";
import Schema from "./Schema";
import SelectShop from "../../utils/components/selectShop";
import compressImage from "../../utils/UploadFile";
import {
  url_path_images,
  url_path_images_user,
  url_upload_images_user,
} from "../../utils/url";
import axios from "axios";
import { set } from "lodash";

export default function UpdateUser({ onSuccess, _data }: any) {
  const [show, setShow] = useState(false);
  const [image, setImage] = useState<any>("");
  const [uploading, setUploading] = useState(false);

  const [updateMember, { loading: upDateTing }]: any = useMutation(
    Schema.updateUser
  );

  type Values = {
    fullName: string;
    phoneNumber: number;
    role: string;
    shop: number;
    userProfile: string;
  };
  type Errors = {
    fullName?: string;
    phoneNumber?: string;
    role?: string;
    shop?: string;
  };

  const handleChangeImageUpload = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      setUploading(true);
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setImage(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res: any = await axios.post(url_upload_images_user, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setImage(filename);
        } else {
          setImage("");
          messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  // useFormik
  const {
    values,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik<Values>({
    initialValues: {
      fullName: "",
      phoneNumber: 0,
      role: "",
      shop: 0,
      userProfile: "",
    },
    validate: (values: Values) => {
      const errors: Errors = {};
      if (!values?.fullName) errors.fullName = "ກາລຸນາປ້ອນຊື່";
      if (!values?.phoneNumber) errors.phoneNumber = "ກາລຸນາປ້ອນເບີໂທ";
      if (!values?.role) errors.role = "ກະລຸນາເລືອກສິດການນຳໃຊ້";
      if (!values?.shop) errors.shop = "ກະລຸນາເລືອກຊື່ຮ້ານ";
      return errors;
    },
    onSubmit: async (values: Values) => {
      try {
        await updateMember({
          variables: {
            data: {
              userProfile: String(image),
              fullName: values?.fullName,
              phoneNumber: values?.phoneNumber,
              role: values?.role,
              shop: values?.shop,
            },
            where: {
              _id: _data?._id,
            },
          },
        });
        messageSuccess("ດຳເນີນງານສຳເລັດແລ້ວ");
        setImage("");
        setShow(false);
        resetForm();
        if (onSuccess) onSuccess();
      } catch (error) {
        console.log(error);
        messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
      }
    },
  });
  useEffect(() => {
    if (!_data && !show) return;
    setFieldValue("phoneNumber", _data?.phoneNumber);
    setFieldValue("role", _data?.role);
    setFieldValue("shop", _data?.shop?._id);
    setFieldValue("fullName", _data?.fullName);
    setFieldValue("packagePrice", _data?.packagePrice);
    setImage(_data?.userProfile);
  }, [_data]);

  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm"
        onClick={() => setShow(true)}
      >
        <i className="icon-edit" />
      </button>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <h3 className="text-center">
            <i className="icon-user-plus me-1" />
            ແກ້ໄຂຂໍ້ມູນສະມາຊິກ
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <form>
              <div className="col-12 col-lg-4 col-md-12 col-sm-12">
                <div className="form-group">
                  <div style={{ textAlign: "center" }}>
                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleChangeImageUpload}
                      name="file"
                      accept="image/png, image/gif, image/jpeg"
                      hidden
                    />
                    <label htmlFor="file-upload">
                      <div
                        style={{
                          height: 200,
                          width: 200,
                          cursor: "pointer",
                          display: "flex",
                          backgroundImage: `url(${onLoading})`,
                          justifyContent: "center",
                          backgroundPosition: "center",
                          backgroundSize: "100%",
                          textAlign: "center",
                          borderRadius: 7,
                        }}
                      >
                        {image ? (
                          <img
                            src={
                              image
                                ? url_path_images_user + image
                                : placeholderImage(200, 200)
                            }
                            alt={image}
                            style={{
                              height: 200,
                              width: 200,
                              borderRadius: 7,
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              backgroundImage: `url(${placeholderImage(
                                200,
                                200
                              )})`,
                              display: "flex",
                              height: 200,
                              width: 200,
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 7,
                              backgroundSize: "100%",
                            }}
                          ></div>
                        )}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <Form.Group className="mb-2">
                <Form.Label>ຊື່ ແລະ ນາມສະກຸນ {InvalidText()}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ຊື່ ແລະ ນາມສະກຸນ"
                  onWheel={(e) => e.currentTarget.blur()}
                  name="fullName"
                  value={values?.fullName || ""}
                  onChange={handleChange}
                  isInvalid={errors?.fullName ? true : false}
                  required
                  className="form-control form-control-lg"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.fullName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label>ເບີຕິດຕໍ່ {InvalidText()}</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="ປ້ອນເບີຕິດຕໍ່"
                  onWheel={(e) => e.currentTarget.blur()}
                  name="phoneNumber"
                  value={values?.phoneNumber || ""}
                  onChange={handleChange}
                  isInvalid={errors?.phoneNumber ? true : false}
                  required
                  className="form-control form-control-lg"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>ບ່ອນປະຈຳການ {InvalidText()}</Form.Label>
                <SelectShop
                  value={values?.shop}
                  onChange={(res) => {
                    setFieldValue("shop", res?.value);
                  }}
                  getSearch={0}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.shop}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>ເລືອກສິດການນຳໃຊ້ {InvalidText()}</Form.Label>
                <Form.Select
                  name="role"
                  onChange={handleChange}
                  value={values.role}
                  isInvalid={errors?.role ? true : false}
                  required
                  className="form-control"
                >
                  <option value="">ເລືອກສິດການໃຊ້ງານ</option>
                  {getUserRole() === "SUPER_ADMIN" && (
                    <>
                      <option value="SUPER_ADMIN">ບໍລິຫານສູງສຸດ</option>
                      <option value="OWNER">ເຈົ້າຂອງຮ້ານ</option>
                    </>
                  )}
                  <option value="ADMIN">ແອັດມິນ</option>
                  {/* <option value="SUPER_USERS">ຫົວໜ້າໜ່ວຍງານ</option> */}
                  {/* <option value="USERS">ພະນັກງານທົ່ວໄປ</option> */}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.role}
                </Form.Control.Feedback>
              </Form.Group>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary shadow-none btn-lg"
            disabled={upDateTing}
            onClick={() => handleSubmit()}
          >
            <i className="icon-check-circle" />
            <span className="ms-1">ບັນທຶກ</span>
          </button>
          <button
            type="button"
            className="btn btn-light btn-lg"
            disabled={upDateTing}
            onClick={() => (setShow(false), resetForm())}
          >
            <i className="icon-x" />
            <span className="ms-1">ຍົກເລີກ</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
