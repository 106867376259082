import ApolloClientProvider from "./configs/ApolloClientProvider";
import Routes from "./routes";
import "./App.scss";

function App() {

  return (
    <ApolloClientProvider>
      <ApolloClientProvider>
        <Routes />
      </ApolloClientProvider>
    </ApolloClientProvider>
  );
}

export default App;
