import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Schema from "./Schema";
import { InvalidText, messageError, messageSuccess } from "../../utils";
import axios from "axios";
import compressImage from "../../utils/UploadFile";
import { NumericFormat } from "react-number-format";
import SelectPackage from "../../utils/components/selectPackage";

export default function EditData({ _data, onSuccess }: any) {
  const [show, setShow] = useState(false);
  const [createOrderByCustomer, { loading: createTing }]: any = useMutation(
    Schema.editData
  );

  const {
    handleChange,
    errors,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      title: "",
      name: "",
      tell: "",
      shop: "",
      companySend: "",
      cod: 0,
    },
    enableReinitialize: false,
    validate: (values) => {
      const errors: any = {};
      if (!values.title) {
        errors.title = "ກະລຸນາປ້ອນຊື່ລູກຄ້າ";
      }
      if (!values.name) {
        errors.name = "ກະລຸນາປ້ອນຂໍ້ມູນຕິດຕໍ່";
      }
      if (!values.tell) {
        errors.tell = "ກະລຸນາປ້ອນເບີໂທ";
      }
      if (!values.companySend) {
        errors.companySend = "ກະລຸນາປ້ອນບ່ອນສົ່ງ";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await createOrderByCustomer({
          variables: {
            data: {
              title: values.title,
              name: values.name,
              tell: String(values.tell),
              companySend: values.companySend,
              cod: values.cod,
              isConfirm: 0,
            },
            where: {
              _id: parseInt(_data?._id),
            },
          },
        });
        messageSuccess("ດຳເນີນງານສຳເລັດແລ້ວ");
        setShow(false);
        resetForm();
        onSuccess();
      } catch (error) {
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (!_data) return;
    setFieldValue("title", _data?.title);
    setFieldValue("name", _data?.name);
    setFieldValue("tell", _data?.tell);
    setFieldValue("companySend", _data?.companySend);
    setFieldValue("cod", _data?.cod);
  }, [_data]);

  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm"
        onClick={() => setShow(true)}
      >
        <i className="icon-edit" />
      </button>
      <Modal show={show} size="lg">
        <Modal.Header
          onHide={() => {
            setShow(false);
            resetForm();
          }}
        >
          <h3 className="text-center">
            <i className="icon-edit1 me-1" />
            ແກ້ໄຂໍ້ມູນອໍເດີ່
          </h3>
          {createTing ? (
            <>
              <a href="javascript:void(0)" className="float-end">
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                href="javascript:void(0)"
                className="float-end"
                onClick={() => {
                  setShow(false);
                }}
              >
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <form>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ຊື່ລູກຄ້າ {InvalidText()}
                </label>
                <input
                  type="text"
                  autoFocus
                  className={
                    errors.name
                      ? "form-control form-control-lg is-invalid"
                      : "form-control form-control-lg  invalid"
                  }
                  placeholder="ປ້ອນຊື່ລູກຄ້າ"
                  name="name"
                  value={String(values.name)}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ເບີໂທ {InvalidText()}
                </label>
                <input
                  type="number"
                  className={
                    errors.tell
                      ? "form-control form-control-lg is-invalid"
                      : "form-control form-control-lg  invalid"
                  }
                  onWheel={(e: any) => e.target.blur()}
                  placeholder="ປ້ອນເບີຕິດຕໍ່"
                  name="tell"
                  value={values.tell}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ຊື່ສິນຄ້າ {InvalidText()}
                </label>
                <input
                  type="search"
                  className={
                    errors.title
                      ? "form-control form-control-lg is-invalid"
                      : "form-control form-control-lg  invalid"
                  }
                  onWheel={(e: any) => e.target.blur()}
                  placeholder="ປ້ອນຊື່ສິນຄ້າ"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  COD {InvalidText()}
                </label>
                <input
                  type="number"
                  className={
                    errors.cod
                      ? "form-control form-control-lg is-invalid"
                      : "form-control form-control-lg  invalid"
                  }
                  onWheel={(e: any) => e.target.blur()}
                  placeholder="ປ້ອນ COD"
                  name="cod"
                  value={values.cod}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ບ່ອນຝາກ {InvalidText()}
                </label>
                <textarea
                  rows={3}
                  className={
                    errors.companySend
                      ? "form-control form-control-lg is-invalid"
                      : "form-control form-control-lg  invalid"
                  }
                  onWheel={(e: any) => e.target.blur()}
                  placeholder="ປ້ອນບ່ອນຕ້ອງການຝາກ"
                  name="companySend"
                  value={values.companySend}
                  onChange={handleChange}
                ></textarea>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-lg"
            disabled={createTing}
            onClick={() => handleSubmit()}
          >
            <i className="icon-check-circle" />
            <span className="ms-1">ບັນທຶກ</span>
          </button>
          <button
            type="button"
            className="btn btn-light btn-lg"
            disabled={createTing}
            onClick={() => (setShow(false), resetForm())}
          >
            <i className="icon-x" />
            <span className="ms-1">ຍົກເລີກ</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
