import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Paths } from "../../routes/Path";
export default function StockItemsScreen() {
  const navigate = useNavigate();


  return (
    <div className="page-wrapper">
      <div className="content ms-2">
        <nav aria-label="breadcrumb" className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={() => navigate(Paths.home)}>
                ໜ້າຫຼັກ
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
             ສາງສິນຄ້າ
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12 col-lg-6">
          .....
          </div>
        </div>
      </div>
    </div>
  );
}
