import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "./Schema";
import { useEffect, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import {
  NO,
  closeLoadingScreen,
  currency,
  getStaffLogin,
  getUserRole,
  loadingScreen,
  notifyError,
  notifySuccess,
  placeholderImage,
} from "../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootVariable } from "../../utils";
import SearchDateRange from "../../utils/components/SearchDateRange";
import LimitData from "../../utils/components/LimitData";
import AddShops from "./AddShop";
import UpdateShops from "./UpdateShop";
import { Confirm } from "notiflix";
import ExportShop from "./ExportShop";
import { Paths } from "../../routes/Path";
import PreviewFileUpLoad from "../../utils/components/PreviewFileUpLoad";
import SelectShop from "../../utils/components/selectShop";
import FileTransfer from "./FileTransfer";
import SummaryTotal from "./Summary";
import moment from "moment";
import { formatDateTime } from "../../utils";
import ConFirmUse from "./ComfirmUse";
import { url_path_images } from "../../utils/url";

export default function ShopScreen() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(RootVariable.limit);
  const [totalItem, setTotalItem] = useState(0);
  const [searchValue, setSearchValues] = useState("");
  const [listShop, setListShop] = useState([]);
  const [newRefresh, setNewRefresh] = useState(false);
  const [userAuth, setUserAuth]: any = useState({});

  const [localShop, setLocalShop] = useState<any>();

  const [deleteShopPing, { loading: deleteTing }]: any = useMutation(
    Schema.deleteShop
  );
  const [access, { loading: accessLoading }]: any = useMutation(
    Schema.updateShop
  );

  useEffect(() => {
    setUserAuth(getStaffLogin());
  }, []);

  const [queryShop, { loading: loadingData }] = useLazyQuery(Schema.queryShop, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setListShop(data?.shops?.data);
      setTotalItem(data?.shops?.total);
    },
  });
  const getRole = getUserRole() === "SUPER_ADMIN";
  useEffect(() => {
    if (getRole) {
      queryShop({
        variables: {
          where: {
            title: searchValue ? searchValue : undefined,
            _id: localShop?._id,
          },
          limit: numberRows,
          skip: numberRows * (currentPage - 1),
        },
      });
    } else {
      queryShop({
        variables: {
          where: {
            _id: userAuth?.shop?._id,
          },
          limit: numberRows,
          skip: numberRows * (currentPage - 1),
        },
      });
    }
  }, [
    queryShop,
    newRefresh,
    searchValue,
    userAuth,
    numberRows,
    currentPage,
    localShop,
  ]);

  //pagination
  const totalPage = Math.ceil(totalItem / numberRows);
  useEffect(() => {
    let page = params.get("page");
    let rows = params.get("rows");
    const _search = params.get("search");

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(RootVariable.limit);
    }
    setSearchValues(_search ? String(_search) : "");
  }, [params]);

  const deleteShop = (id: number) => {
    Confirm.show(
      "ເເຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລຶບລາຍການນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        const deleteShop = await deleteShopPing({
          variables: {
            where: {
              _id: id,
            },
          },
        });
        if (deleteShop) {
          notifySuccess("ການດຳເນີນງານສຳເລັດ");
          setNewRefresh(!newRefresh);
        } else {
          notifyError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  const _turnOffTheShop = (id: number) => {
    Confirm.show(
      "ເເຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການປິດລາຍການນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        const confirmShop = await access({
          variables: {
            data: {
              isPublic: 0,
            },
            where: {
              _id: id,
            },
          },
        });
        if (confirmShop) {
          notifySuccess("ການດຳເນີນງານສຳເລັດ");
          setNewRefresh(!newRefresh);
        } else {
          notifyError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  const countDay = (date: any) => {
    const curDate = moment().format("YYYY-MM-DD");
    const _date = moment(date || new Date()).format("YYYY-MM-DD");
    let _count: any = moment(curDate).diff(_date, "days");
    _count = String(_count)?.replace("-", "");
    return parseInt(_count);
  };

  const checkDay = (date: any) => {
    const curDate = moment().format("YYYY-MM-DD");
    const _date = moment(date || new Date()).format("YYYY-MM-DD");
    let _count: any = moment(curDate).diff(_date, "days");
    _count = String(_count)?.replace("-", "");
    return parseInt(_count);
  };

  return (
    <div className="page-wrapper">
      <div className="content ms-2">
        <nav aria-label="breadcrumb" className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={() => navigate(Paths.home)}>
                ໜ້າຫຼັກ
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              ຈັດການຮ້ານຄ້າ
            </li>
          </ol>
        </nav>
        {/* ofWeb */}
        {userAuth?.role === "SUPER_ADMIN" ? <SummaryTotal /> : null}
        <div className="row my-3 hiddenDivOfApp">
          <div className="col-md-3 mb-2">
            <AddShops
              onSuccess={() => {
                setNewRefresh(!newRefresh);
              }}
            />
            <ExportShop searchValue={searchValue} numberRows={numberRows} />
          </div>
          <div className="col-md-9 mb-2">
            <input
              type="search"
              placeholder="ຄົ້ນຫາ..."
              className="form-control search-input"
              value={searchValue}
              onChange={(e) => setSearchValues(e.target.value)}
            />
          </div>
        </div>
        {/* of App */}

        <div className="row my-3 hiddenDivOfWeb">
          <div className="col-md-12  mt-4">
            <div className="row">
              <div className="col-6">
                <AddShops
                  onSuccess={() => {
                    setNewRefresh(!newRefresh);
                  }}
                />
              </div>
              <div className="col-6">
                <ExportShop searchValue={searchValue} numberRows={numberRows} />
              </div>
            </div>
          </div>
          <div className="col-12" style={{ marginTop: 50 }}>
            <SearchDateRange isToday />
          </div>
          <div className="col-md-3 mt-1">
            <div className="form-group">
              <label>ຄົນຫາດ້ວຍຊື່</label>
              <input
                type="search"
                placeholder="ຄົ້ນຫາດ້ວຍຊື່..."
                className="form-control form-control-lg"
                onChange={(e) => {
                  params.set("search", e.target.value);
                  setParams(params);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row my-2">
          <LimitData
            numberRows={numberRows}
            totalPage={totalPage}
            currentPage={currentPage}
            total={totalItem}
            hiddenSearch="hiddenSearch"
            col={6}
          />
        </div>

        <div className="row">
          <div className="col-12 p-2 card">
            {loadingData ? loadingScreen() : closeLoadingScreen()}
            <>
              <div className="table-responsive">
                <table className="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="text-nowrap">ໂປຣໄຟລ໌</th>
                      <th className="text-nowrap">ລະຫັດຮ້ານ</th>
                      <th className="text-nowrap">ຊື່ຮ້ານ</th>
                      <th className="text-nowrap">ເບີໂທຕິດຕໍ່</th>
                      <th className="text-nowrap">ເພຈ໌</th>
                      <th className="text-nowrap">ທີ່ຢູ່</th>
                      <th className="text-nowrap">ສະຖານະ</th>
                      <th className="text-nowrap">ລາຍລະອຽດ</th>
                      <th className="text-nowrap">ແພັກເກັຈ</th>
                      <th className="text-nowrap">ລະຫັດແນະນຳ</th>
                      <th className="text-nowrap">ລະຫັດຮ້ານແນະນຳ</th>
                      <th className="text-nowrap">ການຕະຫຼາດ</th>
                      <th className="text-nowrap">ສ້າງໂດຍ</th>
                      <th className="text-nowrap">ຈັດການ</th>
                    </tr>
                  </thead>

                  <tbody>
                    {listShop?.map((item: any, index) => (
                      <tr
                        className={
                          checkDay(item?.createdAt) >= 7 &&
                          item?.status === "DEMO" &&
                          item?.isPublic === 1
                            ? "table-warning"
                            : item?.isPublic === 0
                            ? "table-danger"
                            : ""
                        }
                      >
                        <td className="text-center text-nowrap">
                          {NO(index, currentPage, numberRows)}
                        </td>
                        <td>
                          <div className="profile-image">
                            <img
                              src={
                                item?.profileImage
                                  ? url_path_images + item?.profileImage
                                  : placeholderImage(100, 100)
                              }
                              className="image"
                              data-darkbox={
                                item?.profileImage
                                  ? url_path_images + item?.profileImage
                                  : placeholderImage(520, 520)
                              }
                              data-darkbox-group="two"
                            />
                          </div>
                        </td>
                        <td className="text-nowrap" width={120}>
                          {item?.shopID ?? " "}
                          {checkDay(item?.createdAt) >= 7 &&
                          item?.isPublic === 0 &&
                          getUserRole() === "SUPER_ADMIN" ? (
                            <>
                              <hr className="my-1" />
                              {item?.isPublic === 0 ? (
                                <>
                                  <button
                                    className="btn btn-danger btn-sm my-0"
                                    onClick={() => _turnOffTheShop(item?._id)}
                                    disabled={item?.isPublic === 1}
                                  >
                                    <i className="icon-x-circle" /> ປິດໃຊ້ງານ
                                  </button>
                                </>
                              ) : (
                                <>
                                  <span>
                                    <i className="icon-x-circle" /> ໃຊ້ງານແລ້ວ
                                  </span>
                                </>
                              )}
                            </>
                          ) : null}
                        </td>
                        <td className="text-start text-nowrap">
                          {item?.title ?? " "}
                          <hr className="my-1" />
                          <strong
                            className={
                              checkDay(item?.createdAt) >= 7 &&
                              item?.status === "DEMO" &&
                              item?.isPublic === 1
                                ? "text-danger"
                                : "text-success"
                            }
                          >
                            ເຂົ້າຮ່ວມ {countDay(item?.createdAt)} ມື້ແລ້ວ
                          </strong>
                        </td>
                        <td className="text-center text-nowrap">
                          {item?.phoneNumber ?? " "}
                        </td>
                        <td className="text-nowrap">{item?.pageName ?? " "}</td>
                        <td className="text-nowrap">{item?.address ?? " "}</td>
                        <td className="text-center">
                          {item?.status === "DEMO" &&
                          getUserRole() === "SUPER_ADMIN" ? (
                            <>
                              {/* <button
                              className="btn  btn-primary btn-sm my-0"
                              onClick={() => _comFirmAccess(item?._id)}
                              disabled={item?.isPublic === 0}
                            >
                              <i className="icon-check-circle" />{" "}
                              ຢືນຢັນໃຊ້ງານຈິງ
                            </button> */}
                              <ConFirmUse
                                idShop={item?._id}
                                onSuccess={() => {
                                  setNewRefresh(!newRefresh);
                                }}
                              />
                            </>
                          ) : (
                            <>
                              {item?.status === "LIVE" ? (
                                <>
                                  <strong className="text-success">
                                    <i className="icon-check-circle" />{" "}
                                    ກຳລັງໃຊ້ງານ
                                  </strong>
                                </>
                              ) : (
                                <>
                                  <strong className="text-danger">
                                    <i className="icon-check-circle" />{" "}
                                    ຍັງບໍ່ມີຜູ້ນຳໃຊ້
                                  </strong>
                                </>
                              )}
                            </>
                          )}
                        </td>
                        <td width={300}>{item?.description ?? "-"}</td>
                        <td className="text-nowrap text-end">
                          <strong className="fs-5">
                            {item?.package?.packageName
                              ? item?.package?.packageName
                              : "-"}{" "}
                          </strong>
                          <span className="text-success fs-5">
                            {" "}
                            [
                            {item?.package?.packagePrice
                              ? currency(item?.package?.packagePrice)
                              : 0}{" "}
                            ກີບ]
                          </span>
                        </td>
                        <td className="text-nowrap text-center">
                          {item?.providerCode ?? " "}
                        </td>
                        <td className="text-nowrap text-center text-danger">
                          {item?.inviteCode ?? " "}
                        </td>
                        <td className="text-nowrap text-center text-primary">
                          {item?.percent ?? " "}%
                        </td>

                        <td className="text-nowrap">
                          {item?.createdBy?.fullName ?? "-"}
                          <hr className="my-1" />
                          {item?.createdAt
                            ? formatDateTime(item?.createdAt)
                            : "- -"}
                        </td>
                        <td className="text-center text-nowrap" width={100}>
                          <div className="btn-group btn-sm py-0">
                            {getUserRole() === "SUPER_ADMIN" ||
                            getUserRole() === "OWNER" ? (
                              <>
                                <UpdateShops
                                  onSuccess={() => {
                                    setNewRefresh(!newRefresh);
                                  }}
                                  _data={item}
                                />
                              </>
                            ) : null}
                            {getUserRole() === "SUPER_ADMIN" && (
                              <>
                                <button
                                  className="btn btn-outline-primary btn-sm my-0"
                                  type="button"
                                  disabled={deleteTing}
                                  onClick={() => deleteShop(item?._id)}
                                >
                                  <i className="icon-trash" />
                                </button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="container mt-3">
                {(() => {
                  return (
                    <ResponsivePagination
                      total={totalPage}
                      current={currentPage || 1}
                      onPageChange={(page: number) => {
                        params.set("page", page.toString());
                        setParams(params);
                      }}
                    />
                  );
                })()}
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
