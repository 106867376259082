import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Schema from "./Schema";
import {
  InvalidText,
  getStaffLogin,
  messageError,
  messageSuccess,
} from "../../utils";

export default function AddAccount({ _data, onSuccess }: any) {
  const [show, setShow] = useState(false);
  const [userAuth, setUserAuth] = useState<any>({});

  const [updateShop, { loading: createTing }]: any = useMutation(
    Schema.updateShopAccount
  );
  useEffect(() => {
    setUserAuth(getStaffLogin());
  }, []);
  const {
    handleChange,
    errors,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      accountName: "",
      accountKIP: "",
      accountTHB: "",
      accountUSD: "",
    },
    enableReinitialize: false,
    validate: (values) => {
      const errors: any = {};
      if (!values.accountName) errors.accountName = "ກະລຸນາປ້ອນຊື່ບັນຊີຂອງທ່ານ";
      if (!values.accountKIP && !values?.accountTHB && !values?.accountUSD)
        errors.accountKIP = "ກະລຸນາປ້ອນເລກບັນຊີໄດໜື່ງຂອງທ່ານ";
      //detect bank account
      if (
        values?.accountKIP &&
        String(values?.accountKIP)?.length !== 13 &&
        String(values?.accountKIP)?.length !== 18
      ) {
        errors.accountKIP =
          "ກະລຸນາກວດສອບເລກບັນຊີ (ເລກບັນຊີກີບຈະຕ້ອງເປັນ 13 ຫຼື 18 ຕົວເລກ) ທ່ານປ້ອນແມ່ນ " +
          String(values?.accountKIP)?.length +
          " ຕົວເລກ";
      }
      if (
        values?.accountTHB &&
        String(values?.accountTHB)?.length < 13 &&
        String(values?.accountTHB)?.length > 19
      ) {
        errors.accountTHB =
          "ກະລຸນາກວດສອບເລກບັນຊີ (ເລກບັນຊີບາດຈະຕ້ອງເປັນ 13-19 ຕົວເລກ) ທ່ານປ້ອນແມ່ນ " +
          String(values?.accountTHB)?.length +
          " ຕົວເລກ";
      }
      if (
        values?.accountUSD &&
        String(values?.accountUSD)?.length !== 13 &&
        String(values?.accountUSD)?.length !== 18
      ) {
        errors.accountUSD =
          "ກະລຸນາກວດສອບເລກບັນຊີ (ເລກບັນຊີໂດລ່າຈະຕ້ອງເປັນ 13-18 ຕົວເລກ) ທ່ານປ້ອນແມ່ນ " +
          String(values?.accountUSD)?.length +
          " ຕົວເລກ";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await updateShop({
          variables: {
            data: {
              accountName: values.accountName,
              accountKIP: String(values.accountKIP),
              accountTHB: String(values.accountTHB),
              accountUSD: String(values.accountUSD),
            },
            where: {
              _id: parseInt(userAuth?.shop?._id),
            },
          },
        });
        messageSuccess("ດຳເນີນງານສຳເລັດແລ້ວ");
        setShow(false);
        resetForm();
        onSuccess();
      } catch (error) {
        console.log(error);
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
      }
    },
  });

  return (
    <>
      <button className="btn btn-success btn-lg" onClick={() => setShow(true)}>
        <i className="icon-edit1 me-1" />
        ເພີ່ມເລກບັນຊີຮ້ານ
      </button>
      <Modal show={show} size="lg">
        <Modal.Header
          onHide={() => {
            setShow(false);
            resetForm();
          }}
        >
          <h3 className="text-center">
            <i className="icon-edit1 me-1" />
            ຟອມເພີ່ມເລກບັນຊີຮ້ານ
          </h3>
          {createTing ? (
            <>
              <a href="javascript:void(0)" className="float-end">
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                href="javascript:void(0)"
                className="float-end"
                onClick={() => {
                  setShow(false);
                }}
              >
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <form>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ຊື່ບັນຊີກີບ {InvalidText()}
                </label>
                <input
                  type="text"
                  autoFocus
                  className={
                    errors.accountName
                      ? "form-control form-control-lg is-invalid"
                      : "form-control form-control-lg  invalid"
                  }
                  placeholder="ປ້ອນຊື່ລູກຄ້າ"
                  name="accountName"
                  value={String(values.accountName)}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ເລກບັນຂີກີບ {InvalidText()}
                </label>
                <input
                  type="number"
                  className={
                    errors.accountKIP
                      ? "form-control form-control-lg is-invalid"
                      : "form-control form-control-lg  invalid"
                  }
                  onWheel={(e: any) => e.target.blur()}
                  placeholder="ປ້ອນເລກບັນຊີ"
                  name="accountKIP"
                  value={values.accountKIP}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ເລກບັນຊີບາດ {InvalidText()}
                </label>
                <input
                  type="search"
                  className={
                    errors.accountTHB
                      ? "form-control form-control-lg is-invalid"
                      : "form-control form-control-lg  invalid"
                  }
                  onWheel={(e: any) => e.target.blur()}
                  placeholder="ປ້ອນຊື່ສິນຄ້າ"
                  name="accountTHB"
                  value={values.accountTHB}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ເລກບັນຊີໂດລ່າ {InvalidText()}
                </label>
                <input
                  type="number"
                  className={
                    errors.accountUSD
                      ? "form-control form-control-lg is-invalid"
                      : "form-control form-control-lg  invalid"
                  }
                  onWheel={(e: any) => e.target.blur()}
                  placeholder="ປ້ອນ COD"
                  name="accountUSD"
                  value={values.accountUSD}
                  onChange={handleChange}
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-lg"
            disabled={createTing}
            onClick={() => handleSubmit()}
          >
            <i className="icon-check-circle" />
            <span className="ms-1">ບັນທຶກ</span>
          </button>
          <button
            type="button"
            className="btn btn-light btn-lg"
            disabled={createTing}
            onClick={() => (setShow(false), resetForm())}
          >
            <i className="icon-x" />
            <span className="ms-1">ຍົກເລີກ</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
