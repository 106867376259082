import { gql } from "@apollo/client";

export default class Schema {
  static login = gql`
    mutation UserLogin($data: UserLoginInput!) {
      userLogin(data: $data) {
        accessToken
      }
    }
  `;
}
