import React, { useEffect, useRef, useState } from "react";
import { currency, getLocalShop } from "../../utils";
import { formatDate } from "../../utils";
import logo from "../../assets/images/cart.png";
import Barcode from "react-barcode";
import { url_path_images } from "../../utils/url";
import Notiflix from "notiflix";
import { useLazyQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import Schema from "./Schema";

export default function PrintBranCode({ data }: any) {
  const [items, setItems] = useState<any>([]);
  const [searchParams] = useSearchParams();
  const itemIds = searchParams.get("getIds");
  const getLimit: any = searchParams.get("limit_print");
  const [fetchData, { data: itemsData, loading }] = useLazyQuery(
    Schema.queryBarcode,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  useEffect(() => {
    if (!itemIds) return;
    fetchData({
      variables: {
        where: {
          allID: itemIds,
        },
        limit: parseInt(getLimit),
      },
    });
  }, [itemIds, getLimit]);
  useEffect(() => {
    const results = itemsData?.generateCodes?.data;
    setItems(results || []);
    if (!results) return;
    Notiflix.Loading.remove();
    // setTimeout(() => {
    //   window.print();
    // }, 400);
  }, [itemsData]);

  useEffect(() => {
    if (loading) {
      Notiflix.Loading.standard("ກຳລັງໂຫຼດຂໍ້ມູນ...");
    } else {
      Notiflix.Loading.remove();
    }
  }, [loading]);

  const barCodeOptions: any = {
    format: "CODE128",
    // displayValue: false,
    fontSize: 20,
    background: "#ffffff",
    lineColor: "#000000",
    margin: 0,
    marginRight: 2,
  };
  return (
    <>
      {items?.map((data: any, index: any) => (
        <div id="barcode-bill">
          <table
            style={{
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td width="50%" colSpan={2} height="100%">
                  <center>
                    <strong>
                      {data.title} : {data?.price ? currency(data?.price) : 0} ກີບ
                    </strong>
                    <br />
                    <b style={{ fontSize: "18px", fontWeight: 500 }}>
                      <Barcode
                        value={data?.code}
                        {...barCodeOptions}
                        height={25}
                      />
                    </b>
                  </center>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </>
  );
}
