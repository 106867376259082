import { gql } from "@apollo/client";

export default class Schema {
  static queryCustomer = gql`
    query Customers($skip: Int, $limit: Int, $where: CustomerWhereInput) {
      customers(skip: $skip, limit: $limit, where: $where) {
        data {
          id_list
          full_name
          contact_info
        }
        total
      }
    }
  `;
  static getProvinces = gql`
    query Provinces {
      provinces {
        total
        data {
          _id
          title
        }
      }
    }
  `;

  static getDeliveryPrice = gql`
    query DeliveryPrices(
      $where: DeliveryPriceWhereInput
      $limit: Int
      $skip: Int
    ) {
      deliveryPrices(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          title
          price
        }
      }
    }
  `;

  static getDistricts = gql`
    query Districts($where: DistrictWhereInput, $limit: Int) {
      districts(where: $where, limit: $limit) {
        data {
          _id
          title
        }
      }
    }
  `;

  static getVillages = gql`
    query Query($where: VillageWhereInput, $limit: Int, $skip: Int) {
      villages(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          title
          deliveryPrice {
            _id
            price
            discountCustomerCreate
            discountCustomerDeposit
            shipperCommission
          }
          branch {
            _id
          }
        }
      }
    }
  `;

  static getBranches = gql`
    query Data($where: BranchWhereInput, $limit: Int) {
      branches(where: $where, limit: $limit) {
        data {
          _id
          title
        }
      }
    }
  `;

  static selectShop = gql`
    query Shops($where: ShopWhereInput, $limit: Int) {
      shops(where: $where, limit: $limit) {
        data {
          _id
          title
          shopID
          pageName
          phoneNumber
          inviteCode
        }
      }
    }
  `;

  static getReceiverAddress = gql`
    query Villages($where: VillageWhereInput, $limit: Int) {
      villages(where: $where, limit: $limit) {
        data {
          _id
          title
          province {
            _id
            title
          }
          district {
            _id
            title
          }
        }
      }
    }
  `;

  static getUserShipper = gql`
    query Users($where: UserWhereInput, $limit: Int) {
      users(where: $where, limit: $limit) {
        data {
          _id
          firstName
          lastName
        }
      }
    }
  `;

  static getDeliveryRoute = gql`
    query Data($where: DeliveryRouteWhereInput, $limit: Int) {
      deliveryRoutes(where: $where, limit: $limit) {
        data {
          _id
          title
        }
      }
    }
  `;

  static queryPackage = gql`
    query Packages($where: PackageWhereInput, $limit: Int, $skip: Int) {
      packages(where: $where, limit: $limit, skip: $skip) {
        data {
          _id
          countTime
          createdAt
          packagePrice
          updatedAt
          packageName
        }
        total
      }
    }
  `;
}
