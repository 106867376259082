import { gql } from "@apollo/client";

export default class Schema {
  static queryUser = gql`
    query Users($where: UserWhereInput, $limit: Int, $skip: Int) {
      users(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          userProfile
          fullName
          phoneNumber
          role
          isPublic
          shop {
            _id
            shopID
            title
          }
          createdAt
          createdBy {
            _id
            fullName
            phoneNumber
          }
          updatedAt
          updatedBy {
            _id
            fullName
            phoneNumber
          }
        }
      }
    }
  `;
  static createUser = gql`
    mutation CreateUser($data: UserInput!) {
      createUser(data: $data) {
        status
      }
    }
  `;

  static updateUser = gql`
    mutation UpdateUser($data: UserInput!, $where: WhereById!) {
      updateUser(data: $data, where: $where) {
        status
      }
    }
  `;
  static deleteUser = gql`
    mutation DeleteUser($where: WhereById!) {
      deleteUser(where: $where) {
        status
      }
    }
  `;
}
