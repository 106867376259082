import React from "react";
import { currency } from "..";
import ResponsivePagination from "react-responsive-pagination";
import { useSearchParams } from "react-router-dom";
export default function LimitData({
  numberRows,
  totalPage,
  currentPage,
  total,
  hiddenSearch,
  col,
  next_page,
}: any) {
  const [params, setParams] = useSearchParams();
  return (
    <>
      <div className="col-12 col-lg-5 my-2">
        <span className="fs-6">
          ສະເເດງ{" "}
          <span className="text-primary">
            {numberRows * currentPage - numberRows + 1} {"-"}{" "}
            {numberRows * currentPage}
          </span>{" "}
          ລາຍການ ໃນຂໍ້ມູນທັງໜົດ{" "}
          <span className="text-primary">{currency(total ?? 0)}</span> ລາຍການ
        </span>
      </div>

      {next_page === 0 ? (
        <></>
      ) : (
        <>
          <div
            className="col-12 col-lg-5 my-2"
            hidden={hiddenSearch === "hiddenSearch" ? true : false}
          >
            {(() => {
              return (
                <ResponsivePagination
                  total={totalPage}
                  current={currentPage || 1}
                  onPageChange={(page: number) => {
                    params.set("page", page.toString());
                    setParams(params);
                  }}
                />
              );
            })()}
          </div>
        </>
      )}

      {next_page === 1 ? (
        <></>
      ) : (
        <>
          <div className="col-12 col-lg-2 my-2">
            <select
              id="sel-rows"
              className="form-control form-select ml-2"
              onChange={(e) => {
                params.set("rows", e.target.value);
                setParams(params);
              }}
              value={numberRows}
            >
              <option value="50" selected={numberRows === 50 ? true : false}>
                50 ລາຍການ
              </option>
              <option value="100" selected={numberRows === 100 ? true : false}>
                100 ລາຍການ
              </option>
              <option value="300" selected={numberRows === 300 ? true : false}>
                300 ລາຍການ
              </option>
              <option value="500" selected={numberRows === 500 ? true : false}>
                500 ລາຍການ
              </option>
            </select>
          </div>
        </>
      )}
    </>
  );
}
