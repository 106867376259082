import { gql } from "@apollo/client";

export default class Schema {
  static queryShop = gql`
    query Shops($where: ShopWhereInput, $limit: Int, $skip: Int) {
      shops(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          profileImage
          firstFileMoney
          comFirmPaid
          comFirmDate
          shopID
          title
          providerCode
          percent
          inviteCode
          phoneNumber
          pageName
          address
          description
          packagePrice
          isPublic
          total_user
          status
          createdAt
          createdBy {
            _id
            fullName
            phoneNumber
          }
          package {
            _id
            packageName
            packagePrice
            countTime
          }
        }
      }
    }
  `;
  static summaryShop = gql`
    query SummaryShop {
      summaryShop {
        activeTotal
        demoTotal
        inActiveTotal
        liveTotal
      }
    }
  `;

  static createOrder = gql`
    mutation CreateOrder($data: OrderInput!) {
      createOrder(data: $data) {
        data
        status
      }
    }
  `;

  static createShop = gql`
    mutation CreateShop($data: ShopInput!) {
      createShop(data: $data) {
        data
        status
      }
    }
  `;

  static updateShop = gql`
    mutation UpdateShop($data: ShopInput!, $where: WhereById!) {
      updateShop(data: $data, where: $where) {
        status
      }
    }
  `;
  static deleteShop = gql`
    mutation DeleteShop($where: WhereById!) {
      deleteShop(where: $where) {
        status
      }
    }
  `;
}
