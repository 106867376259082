import React, {useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { currency, formatDateTime } from "../../utils";
import { DownloadTableExcel } from "react-export-table-to-excel";

export default function PrintData({ listData, total }: any) {
  const elmRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });
  let today = moment().format("YYYY-MM-DD");
  let time = moment().format("HH:mm:ss");
  const [show, setShow] = useState(false);
  return (
    <React.Fragment>
      <button
        className="btn btn-warning btn-lg ms-2 events"
        onClick={() => setShow(true)}
        style={{ fontSize: "20px" }}
      >
        <i className="icon-print" /> ພິມລາຍງານ {""} {currency(total)}
      </button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>
            <i className="fas fa-info-circle me-1" />
            ລາຍງານຂໍ້ມູນ
          </Modal.Title>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="btn btn-danger"
          >
            <i className="icon icon-x mr-1" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-md-12">
              <button
                onClick={handlePrint}
                type="button"
                className="btn btn-dark ml-3 me-2"
              >
                <i className="icon-print mr-1"></i> ພິມ
              </button>
              <DownloadTableExcel
                filename="users table"
                sheet="ລາຍງານຂໍ້ມູນ"
                currentTableRef={elmRef.current}
              >
                <button className="btn btn btn-success"> Export excel </button>
              </DownloadTableExcel>
            </div>
          </Row>
          <div id="table-to-xls" className="container-print" ref={elmRef}>
            <div style={{ paddingBottom: "5px" }} />
            <div className="table-responsive">
              <table className="table-sm " border={1}>
                <thead>
                  <tr>
                    <td
                      colSpan={5}
                      style={{ textAlign: "center", border: "0" }}
                    >
                      <h4 className="text-black">
                        {" "}
                        ລາຍງານຂໍ້ມູນອໍເດີ່ຂອງ ຮ້ານ {listData?.[0]?.shop?.title}
                      </h4>
                    </td>
                    <td style={{ border: "0", textAlign: "right" }} colSpan={2}>
                      <span className="text-center">ວັນທີພິມ: {today}</span>
                      <br />
                      <span className="text-center">ເວລາພິມ: {time}</span>
                    </td>
                  </tr>
                  <tr>
                    <th className="text-black text-center">#</th>
                    <th className="text-center text-black">barcode</th>
                    <th className="text-nowrap text-black">ຊື່ສິນຄ້າ</th>
                    <th className="text-nowrap text-black">ລາຄາ</th>
                    <th className="text-nowrap text-black">ຄຳອະທິບາຍ</th>
                    <th className="text-black text-center">ວັນທີ</th>
                    <th className="text-black">ຜູ້ສ້າງ</th>
                  </tr>
                </thead>
                <tbody>
                  {listData?.map(
                    (item: any, index: any) => (
                      (
                        <>
                          <tr>
                            <td
                              className="text-center text-nowrap"
                            >
                              {index + 1}
                            </td>
                            <td
                              className="text-center text-nowrap"
                            >
                              {item?.code ?? "-"}
                            </td>
                            <td
                              className=" text-nowrap"
                            >
                              {item?.title ?? "-"}
                            </td>
                            <td >{currency(item?.price) ?? ".."}</td>
                            <td >{currency(item?.description) ?? ".."}</td>
                            <td
                              className="text-center text-nowrap"
                            >
                              {formatDateTime(item?.createdAt) ?? "- -"}
                            </td>
                            <td className="text-nowrap" style={{ width: 120 }}>
                              {item?.createdBy?.fullName ?? "- -"}
                            </td>
                          </tr>
                        </>
                      )
                    )
                  )}
                  <br />
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
