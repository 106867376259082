import React, { useEffect, useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { formatDateTime } from "../../utils";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";

export default function ExportShop({
  numberRows,
  startDate,
  endDate,
  searchValue,
}: any) {
  const [reload, setReload] = useState(false);
  const [listShop, setListShop] = useState([]);
  let today = moment().format("YYYY-MM-DD");
  let time = moment().format("HH:mm:ss");
  const [show, setShow] = useState(false);

  const [queryOrder, { data }] = useLazyQuery(Schema.queryShop, {
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (!startDate || !endDate) return;
    queryOrder({
      variables: {
        where: {
          title: searchValue ? searchValue : undefined,
          // createdAtBetween: [startDate, endDate],
        },
        limit: numberRows,
      },
    });
  }, [queryOrder, reload, searchValue, startDate, endDate]);

  useEffect(() => {
    if (!show) return;
    setListShop(data?.shops?.data || []);
  }, [data, show]);

  const elmRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-secondary shadow-none me-2"
        style={{ position: "relative", top: "0px" }}
        onClick={() => setShow(true)}
      >
        <i className="icon-print me-2" />
        ພິມລາຍງານ
      </button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fas fa-info-circle me-1" />
            ລາຍງານຂໍ້ມູນ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-md-12">
              <button
                onClick={handlePrint}
                type="button"
                className="btn btn-dark ml-3 me-2"
              >
                <i className="icon-print mr-1"></i> ພິມ
              </button>
              <DownloadTableExcel
                filename="users table"
                sheet="ລາຍງານຂໍ້ມູນ"
                currentTableRef={elmRef.current}
              >
                <button className="btn btn btn-success"> Export excel </button>
              </DownloadTableExcel>
            </div>
          </Row>
          <div id="table-to-xls" className="container-print" ref={elmRef}>
            <div style={{ paddingBottom: "5px" }} />
            <div className="table-responsive">
              <table className="table-sm" border={1}>
                <thead>
                  <tr>
                    <td colSpan={7} style={{ textAlign: "left", border: "0" }}>
                      <img
                        //   src={logo}
                        style={{
                          width: 150,
                          marginBottom: "1rem",
                          marginTop: 20,
                        }}
                        alt=""
                      />
                      <br />
                      ຮ້ານ HAPPY SHOP ບ້ານ ໂພນໄຊ, ເມືອງ ໄຊເສດຖາ,
                    </td>
                    <td style={{ border: "0", textAlign: "right" }}>
                      <span className="text-center">
                        ວັນທີ: {formatDateTime(today)}
                      </span>
                      <br />
                      <span className="text-center">ເວລາ: {time}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>#</th>
                    <th>ລະຫັດຮ້ານ</th>
                    <th>ຊື່ຮ້ານ</th>
                    <th>ເບີໂທຕິດຕໍ່</th>
                    <th>ທີ່ຢູ່</th>
                    <th>ເພຈ໌</th>
                    <th>ລາຍລະອຽດ</th>
                    <th>ສ້າງໂດຍ</th>
                  </tr>
                </thead>
                <tbody>
                  {listShop?.map((item: any, index: any) => (
                    <tr key={index}>
                      <td className="text-center text-nowrap">{index + 1}</td>
                      <td className="text-center text-nowrap">
                        {item?.shopID ?? " "}
                      </td>
                      <td className="text-start text-nowrap">
                        {item?.title ?? " "}
                      </td>
                      <td className="text-center text-nowrap">
                        {item?.phoneNumber ?? " "}
                      </td>
                      <td className="text-start">{item?.address ?? " "}</td>
                      <td className="text-start">{item?.pageName ?? " "}</td>
                      <td width={300}>{item?.description ?? "-"}</td>
                      <td className="text-start">
                        {item?.createdBy?.fullName ?? "-"}
                      </td>
                    </tr>
                  ))}

                  <br />
                  <tr>
                    <td
                      colSpan={2}
                      className="text-center"
                      style={{ border: "0" }}
                    >
                      ເຊັນຜູ້ອະນຸມັດ
                    </td>
                    <td
                      colSpan={4}
                      className="text-center"
                      style={{ border: "0" }}
                    >
                      ເຊັນຜູ້ກວດສອບ
                    </td>
                    <td
                      colSpan={2}
                      className="text-center"
                      style={{ border: "0" }}
                    >
                      ເຊັນຜູ້ສະເໜີ
                    </td>
                  </tr>
                  <br />
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
