import React, { useEffect, useState } from "react";
import "./Style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "../routes/Path";
import { getUserRole } from "../utils";
import { BsShop } from "react-icons/bs";
import { MdCategory, MdSettings } from "react-icons/md";
import { FaBarcode, FaBoxOpen, FaBoxes, FaUserCog } from "react-icons/fa";
import { BsCalendar2CheckFill } from "react-icons/bs";
import { BiCategory, BiCategoryAlt, BiNotepad } from "react-icons/bi";
import { MdDateRange } from "react-icons/md";
import { IoTrashSharp } from "react-icons/io5";
import { MdHelp } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi";
import {
  TbBuildingWarehouse,
  TbPackageExport,
  TbPackageImport,
} from "react-icons/tb";
import { FaCircleDollarToSlot } from "react-icons/fa6";
export default function Sidebar() {
  const navigator: any = useNavigate();
  // get path name from location
  const location = useLocation();
  const pathName = location.pathname;
  const [path, setPath] = useState(pathName);

  useEffect(() => {
    setPath(pathName);
  }, [pathName]);

  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="submenu-open">
                <ul>
                  <li className={path === Paths.home ? "active" : ""}>
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigator(Paths.home)}
                    >
                      <BiCategory size={25} />
                      <span>ໜ້າຫຼັກ/ເພີ່ມອໍເດີ່</span>
                    </a>
                  </li>
                  <li
                    className={
                      path === Paths.customer_add_order ? "active" : ""
                    }
                  >
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigator(Paths.customer_add_order)}
                    >
                      <FaBoxOpen size={25} />
                      <span>ອໍເດີ່ລູກຄ້າສັ່ງຊື້</span>
                    </a>
                  </li>
                  <li className={path === Paths.list_order_day ? "active" : ""}>
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigator(Paths.list_order_day)}
                    >
                      <BiNotepad size={25} />
                      <span>ອໍເດີ່ລາຍວັນ</span>
                    </a>
                  </li>
                  <li className={path === Paths.list_order ? "active" : ""}>
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigator(Paths.list_order)}
                    >
                      <MdDateRange size={25} />
                      <span>ອໍເດີ່ລາຍເດືອນ</span>
                    </a>
                  </li>
                </ul>
              </li>
              {/* end */}
              {/* mannament Money */}
              {getUserRole() === "SUPER_ADMIN" || getUserRole() === "OWNER" ? (
                <li className="submenu-open">
                  <ul>
                    <li className={path === Paths.shop ? "active" : ""}>
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigator(Paths.shop)}
                      >
                        <BsShop size={25} />
                        <span>ຈັດການຮ້ານຄ້າ</span>
                      </a>
                    </li>

                    {getUserRole() === "SUPER_ADMIN" && (
                      <>
                        <li
                          className={
                            path === Paths.member_group ? "active" : ""
                          }
                        >
                          <a
                            href="javascript:void(0);"
                            onClick={() => navigator(Paths.member_group)}
                          >
                            <HiUserGroup size={25} />
                            <span>
                              ກຸ່ມຮ້ານຄ້າ <i className="fa-solid fa-user"></i>
                            </span>
                          </a>
                        </li>
                      </>
                    )}
                    <li className={path === Paths.member ? "active" : ""}>
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigator(Paths.member)}
                      >
                        <FaUserCog size={25} />
                        <span>ຈັດການສະມາຊິກ</span>
                      </a>
                    </li>
                  </ul>
                </li>
              ) : null}

              <li className="submenu-open">
                <ul>
                  <li className={path === Paths.invoices ? "active" : ""}>
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigator(Paths.invoices)}
                    >
                      <BsCalendar2CheckFill size={30} />
                      <span>ຊຳລະຄ່າບໍລິການ</span>
                    </a>
                  </li>
                  {getUserRole() === "SUPER_ADMIN" && (
                    <>
                      <li className={path === Paths.commission ? "active" : ""}>
                        <a
                          href="javascript:void(0);"
                          onClick={() => navigator(Paths.commission)}
                        >
                          <FaCircleDollarToSlot size={20} />
                          <span>ຄອມມິສຊັ່ນ</span>
                        </a>
                      </li>
                    </>
                  )}
                </ul>
              </li>
              {/* {getUserRole() === "SUPER_ADMIN" && (
                <>
                  <li className="submenu-open">
                    <ul>
                      <li className={path === Paths.category ? "active" : ""}>
                        <a
                          href="javascript:void(0);"
                          onClick={() => navigator(Paths.category)}
                        >
                          <MdCategory size={30} />
                          <span>ໝວດສິນຄ້າ</span>
                        </a>
                      </li>
                      <li className={path === Paths.items ? "active" : ""}>
                        <a
                          href="javascript:void(0);"
                          onClick={() => navigator(Paths.items)}
                        >
                          <FaBoxes size={20} />
                          <span>ລາຍການສິນຄ້າ</span>
                        </a>
                      </li>
                      <li
                        className={path === Paths.importItems ? "active" : ""}
                      >
                        <a
                          href="javascript:void(0);"
                          onClick={() => navigator(Paths.importItems)}
                        >
                          <TbPackageImport size={20} />
                          <span>ນຳເຂົ້າສິນຄ້າ</span>
                        </a>
                      </li>
                      <li
                        className={path === Paths.exportItems ? "active" : ""}
                      >
                        <a
                          href="javascript:void(0);"
                          onClick={() => navigator(Paths.exportItems)}
                        >
                          <TbPackageExport size={20} />
                          <span>ນຳອອກສິນຄ້າ</span>
                        </a>
                      </li>
                      <li className={path === Paths.stockItems ? "active" : ""}>
                        <a
                          href="javascript:void(0);"
                          onClick={() => navigator(Paths.stockItems)}
                        >
                          <TbBuildingWarehouse size={20} />
                          <span>ສາງສິນຄ້າ</span>
                        </a>
                      </li>
                     
                    </ul>
                  </li>
                </>
              )} */}
              <li className={path === Paths.generate_code ? "active" : ""}>
                <a
                  href="javascript:void(0);"
                  onClick={() => navigator(Paths.generate_code)}
                >
                  <FaBarcode size={25} />
                  <span>Barcode ສິນຄ້າ</span>
                </a>
              </li>

              <li className="submenu-open">
                <ul>
                  {getUserRole() === "SUPER_ADMIN" ||
                  getUserRole() === "OWNER" ? (
                    <>
                      <li
                        className={path === Paths.order_deleted ? "active" : ""}
                      >
                        <a
                          href="javascript:void(0);"
                          onClick={() => navigator(Paths.order_deleted)}
                        >
                          <IoTrashSharp size={25} />
                          <span>ຖັງຂີ້ເຫຍື້ອ</span>
                        </a>
                      </li>
                      <li className={path === Paths.settings ? "active" : ""}>
                        <a
                          href="javascript:void(0);"
                          onClick={() => navigator(Paths.settings)}
                        >
                          <MdSettings size={25} />
                          <span>ການຕັ້ງຄ່າ</span>
                        </a>
                      </li>
                      <li
                        className={path === Paths.support_team ? "active" : ""}
                      >
                        <a
                          href="javascript:void(0);"
                          onClick={() => navigator(Paths.support_team)}
                        >
                          <MdHelp size={25} />
                          <span>ຊ່ວຍເຫຼືອ</span>
                        </a>
                      </li>
                    </>
                  ) : null}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
