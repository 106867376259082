import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { url_path_IBank } from "../../utils/url";

export default function FileTransfer({ sentPathImage, width, height }) {
  const [show, setShow] = useState(false);

  return (
    <React.Fragment>
      <img
        onClick={() => setShow(true)}
        className="center"
        style={{
          borderRadius: 10,
          cursor: "pointer",
          inlineSize: `${width}px!important`,
          blockSize: `${height}px!important`,
        }}
        src={sentPathImage ? url_path_IBank + sentPathImage : "ddd"}
        alt="Profile"
        loading="lazy"
      />
      <Modal size="xl" show={show} animation={false}>
        <Modal.Header>
          <h3 className="text-center">ຫຼັກຖານການຊຳລະ</h3>
          <a
            href="javascript:void(0)"
            className="float-end"
            onClick={() => {
              setShow(false);
            }}
          >
            <i
              className="icon icon-x text-white"
              style={{ fontSize: "55px" }}
            />
          </a>
        </Modal.Header>
        <Modal.Body className="">
          <img
            src={sentPathImage ? url_path_IBank + sentPathImage : "ddd"}
            className="w-100"
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
