import { gql } from "@apollo/client";

export default class Schema {
  static Categories = gql`
    query Categories($where: CategoryWhereInput, $limit: Int, $skip: Int) {
      categories(where: $where, limit: $limit, skip: $skip) {
        data {
          _id
          title
          img
          description
          isPublic
          createdAt
          shop {
            _id
            title
            shopID
            phoneNumber
            providerCode
            inviteCode
          }
        }
        total
      }
    }
  `;
  static CreateCategory = gql`
    mutation CreateCategory($data: CategoryInput!) {
      createCategory(data: $data) {
        status
      }
    }
  `;
  static UpdateCategory = gql`
    mutation UpdateCategory($data: CategoryInput!, $where: WhereById!) {
      updateCategory(data: $data, where: $where) {
        status
      }
    }
  `;
  static DeleteCategory = gql`
    mutation DeleteCategory($where: WhereById!) {
      deleteCategory(where: $where) {
        status
      }
    }
  `;
}
