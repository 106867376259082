import { gql } from "@apollo/client";

export default class Schema {
  static queryOrder = gql`
    query Orders($where: OrderWhereInput, $limit: Int, $skip: Int) {
      orders(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          orderID
          receiveName
          receivePhone
          isPrinted
          isDeleted
          description
          orderStatus
          createdAt
          trackingId
          createdBy {
            _id
            fullName
            phoneNumber
          }
          updatedBy {
            _id
            fullName
            phoneNumber
          }
          shop {
            _id
            title
            shopID
            address
            description
            phoneNumber
            profileImage
          }
        }
      }
    }
  `;
  static createOrder = gql`
    mutation CreateOrder($data: OrderInput!) {
      createOrder(data: $data) {
        data
        status
      }
    }
  `;
  static updateOrder = gql`
    mutation UpdateOrder($data: OrderInput!, $where: WhereById!) {
      updateOrder(data: $data, where: $where) {
        status
      }
    }
  `;
  static deleteOrder = gql`
    mutation DeleteOrder($where: WhereById!) {
      deleteOrder(where: $where) {
        status
      }
    }
  `;

  static updateManyItem = gql`
    mutation UpdateManyOrder($data: OrderInput!, $where: allID!) {
      updateManyOrder(data: $data, where: $where) {
        status
      }
    }
  `;
  static summaryTotal = gql`
    query SummaryTotal($where: OrderWhereInput) {
      summaryTotal(where: $where) {
        totalNexOrder
        totalPrint
        totalSuccess
      }
    }
  `;

  //bill
  static InvoiceBill = gql`
    query Invoices($where: InvoiceWhereInput) {
      invoices(where: $where) {
        data {
          totalAmount
          forMoth
          forYear
          invoiceID
        }
      }
    }
  `;
  //shop
  static queryShop = gql`
    query Shops($where: ShopWhereInput) {
      shops(where: $where) {
        total
      }
    }
  `;

static queryUser = gql`
query Users($where: UserWhereInput, $limit: Int, $skip: Int) {
  users(where: $where, limit: $limit, skip: $skip) {
    total
    data {
      _id
      userProfile
      fullName
      phoneNumber
      role
      isPublic
      shop {
        _id
        shopID
        title
      }
      createdAt
      createdBy {
        _id
        fullName
        phoneNumber
      }
      updatedAt
      updatedBy {
        _id
        fullName
        phoneNumber
      }
    }
  }
}
`;
}
