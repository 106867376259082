//google
// export const url_upload_images = "http://localhost:2024/upload";
export const url_upload_images = "https://api.happyshope.live/upload";
export const url_path_images = "https://storage.googleapis.com/happyshops/img/";

// export const url_upload_images_user= "http://localhost:2024/userProfile";
export const url_upload_images_user= "https://api.happyshope.live/userProfile";
export const url_path_images_user = "https://storage.googleapis.com/happyshops/user/";

// url money
// export const url_upload_IBank= "http://localhost:2024/fileMoney";
export const url_upload_IBank ="https://api.happyshope.live/fileMoney";
export const url_path_IBank ="https://storage.googleapis.com/happyshops/fileMoney/";

export const url_upload_IBank_Order ="https://api.happyshope.live/file_buy_order";
export const url_path_IBank_Order ="https://storage.googleapis.com/happyshops/file_buy_order/";


