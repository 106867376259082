import { gql } from "@apollo/client";

export default class Schema {
  static Invoice = gql`
    query Invoices($skip: Int, $limit: Int, $where: InvoiceWhereInput) {
      invoices(skip: $skip, limit: $limit, where: $where) {
        total
        data {
          _id
          invoiceID
          invoiceDate
          shop {
            _id
            title
          }
          totalAmount
          isPaid
          isDeleted
          paidDate
          package {
            _id
            packageName
            packagePrice
          }
          paidBy {
            _id
            fullName
          }
          description
          forMoth
          forYear
          nextPayDate
          checkSummary
          isReceive
          receiveMoneyName
          fileMoney
          total_user
          price_of_user
        }
      }
    }
  `;
  static createInvoice = gql`
    mutation CreateInvoice($data: InvoiceInput!) {
      createInvoice(data: $data) {
        status
      }
    }
  `;
  static updateInvoice = gql`
    mutation UpdateInvoice($data: InvoiceInput!, $where: WhereById!) {
      updateInvoice(data: $data, where: $where) {
        status
      }
    }
  `;
  static deleteInvoice = gql`
    mutation DeleteInvoice($where: WhereById!) {
      deleteInvoice(where: $where) {
        status
      }
    }
  `;
  static SummaryTransfer = gql`
    query SummaryInvoice($where: InvoiceWhereInput) {
      summaryInvoice(where: $where) {
        totalAmount
      }
    }
  `;
  static SummaryNotTransfer = gql`
    query SummaryInvoice($where: InvoiceWhereInput) {
      summaryInvoice(where: $where) {
        totalAmount
      }
    }
  `;
}
