import { useLazyQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Schema from "./Schema";
import {
  InvalidText,
  LocalStorageName,
  getUserRole,
  messageError,
  messageSuccess,
  onLoading,
  placeholderImage,
} from "../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputNumberFormat from "../../utils/components/InputNumberFormat";
import compressImage from "../../utils/UploadFile";
import axios from "axios";
import {
  url_path_IBank_Order,
  url_path_images,
  url_upload_IBank_Order,
  url_upload_images,
} from "../../utils/url";

export default function AddMemberGroup({ onSuccess }: any) {
  const [fileMoney, setFileMoney] = useState<any>("");
  const [getIsPaid, setGetIsPaid] = useState<any>(0);
  const [getCOD, setGetCOD] = useState<any>(0);
  const [params, setParams] = useSearchParams();
  const [image, setImage] = useState("");
  const [getCode, setGetCode] = useState<any>();
  const navigator: any = useNavigate();

  const [createNewShop, { loading: createTing }]: any = useMutation(
    Schema.createShop
  );

  const handleChangeImageUpload = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setImage(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res: any = await axios.post(url_upload_images, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setImage(filename);
        } else {
          setImage("");
          messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  const {
    handleChange,
    errors,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      profileImage: "",
      title: "",
      phoneNumber: "",
      pageName: "",
      address: "",
      description: "",
      status: "DEMO",
      package: "",
      inviteCode: "",
    },
    enableReinitialize: false,
    validate: (values) => {
      const errors: any = {};
      if (!values.title) {
        errors.title = "ກະລຸນາປ້ອນຊື່ຮ້ານ";
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = "ກະລຸນາປ້ອນຂໍ້ມູນຕິດຕໍ່";
      }
      if (!values.pageName) {
        errors.pageName = "ກະລຸນາປ້ອນເພຈ";
      }
      if (!values.address) {
        errors.address = "ກະລຸນາປ້ອນທີ່ຢູ່";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const { data: created } = await createNewShop({
          variables: {
            data: {
              profileImage: String(image),
              title: values?.title,
              phoneNumber: values?.phoneNumber,
              pageName: values?.pageName,
              address: values?.address,
              description: values?.description,
              status: values?.status,
              package: 7,
              packagePrice: 0,
              inviteCode: getCode,
            },
          },
        });
        console.log("created",created)
        messageSuccess("ສັ່ງສຳເລັດແລ້ວ");
        setGetCOD(0);
        resetForm();
        localStorage.setItem("SHOP", JSON.stringify(created?.createShop?.data));
        setTimeout(() => {
          navigator("/register/user");
        }, 300);
        setFileMoney("");
      } catch (error) {
        console.log(error);
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
      }
    },
  });

  useEffect(() => {
    if (getIsPaid === 1) {
      setGetCOD(0);
    }
    if (getIsPaid === 0) {
      setFileMoney("");
    }
    let getId = params.get("shop");
    setGetCode(getId);
  }, [params, getIsPaid, getCOD]);

  const handleChangeMoneyUpload = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setFileMoney(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res: any = await axios.post(url_upload_IBank_Order, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setFileMoney(filename);
        } else {
          setFileMoney("");
          messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  return (
    <>
      <div
        className="header"
        // style={{ backgroundColor: "#ff5303" }}
      >
        <div className="">
          <div className="row">
            <div className="col-3 text-start">
              <img
                src="assets/img/logo.png"
                alt=""
                style={{ height: 55, width: "auto" }}
              />
            </div>
            <div className="col-6 text-center">
              <h2 className="text-center pt-2">ແບບຟອມບັນທືກຮ້ານຄ້າ</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5  p-3 ">
        <div className="row">
          <form>
            <div className="col-12 col-lg-4 col-md-12 col-sm-12">
              <div className="form-group">
                <div style={{ textAlign: "center" }}>
                  <input
                    type="file"
                    id="file-upload"
                    onChange={handleChangeImageUpload}
                    name="file"
                    accept="image/png, image/gif, image/jpeg"
                    hidden
                  />
                  <label htmlFor="file-upload">
                    <div
                      style={{
                        height: 200,
                        width: 200,
                        cursor: "pointer",
                        display: "flex",
                        backgroundImage: `url(${onLoading})`,
                        justifyContent: "center",
                        backgroundPosition: "center",
                        backgroundSize: "100%",
                        textAlign: "center",
                        borderRadius: 7,
                      }}
                    >
                      {image ? (
                        <img
                          src={
                            image
                              ? url_path_images + image
                              : placeholderImage(200, 200)
                          }
                          alt={image}
                          style={{
                            height: 200,
                            width: 200,
                            borderRadius: 7,
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundImage: `url(${placeholderImage(
                              200,
                              200
                            )})`,
                            display: "flex",
                            height: 200,
                            width: 200,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 7,
                            backgroundSize: "100%",
                          }}
                        ></div>
                      )}
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label>
                <i className="fas fa-layer-group me-1" />
                ລະຫັດແນະນຳ {InvalidText()}
              </label>
              <input
                type="text"
                autoFocus
                disabled={true}
                className={
                  errors.inviteCode
                    ? "form-control form-control-lg is-invalid"
                    : "form-control form-control-lg  invalid"
                }
                placeholder="ປ້ອນລະຫັດແນະນຳ"
                value={getCode}
                onChange={(e: any) => {
                  setGetCode(e.target.value);
                }}
              />
            </div>
            <div className="mb-3">
              <label>
                <i className="fas fa-layer-group me-1" />
                ຊື່ຮ້ານ {InvalidText()}
              </label>
              <input
                type="text"
                autoFocus
                className={
                  errors.title
                    ? "form-control form-control-lg is-invalid"
                    : "form-control form-control-lg  invalid"
                }
                placeholder="ປ້ອນຊື່ຮ້ານ"
                name="title"
                value={String(values.title)}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label>
                <i className="fas fa-layer-group me-1" />
                ເບີຕິດຕໍ່ {InvalidText()}
              </label>
              <input
                type="number"
                className={
                  errors.phoneNumber
                    ? "form-control form-control-lg is-invalid"
                    : "form-control form-control-lg  invalid"
                }
                onWheel={(e: any) => e.target.blur()}
                placeholder="ປ້ອນເບີຕິດຕໍ່"
                name="phoneNumber"
                value={values.phoneNumber}
                onChange={handleChange}
              />
            </div>
            {getUserRole() === "SUPER_ADMIN" && (
              <>
                <div className="mb-3">
                  <label>
                    <i className="fas fa-layer-group me-1" />
                    ຊື່ເພຈ໌ {InvalidText()}
                  </label>
                  <input
                    type="text"
                    className={
                      errors.pageName
                        ? "form-control form-control-lg is-invalid"
                        : "form-control form-control-lg  invalid"
                    }
                    placeholder="ຊື່ເພຈ໌"
                    name="pageName"
                    value={String(values.pageName)}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
            <div className="mb-3">
              <label>
                <i className="fas fa-layer-group me-1" />
                ທີ່ຢູ່ {InvalidText()}
              </label>
              <textarea
                name="address"
                className={
                  errors.address
                    ? "form-control form-control-lg is-invalid"
                    : "form-control form-control-lg  invalid"
                }
                placeholder="ປ້ອນທີ່ຢູ່"
                rows={2}
                value={String(values.address)}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label>
                <i className="fas fa-layer-group me-1" />
                ຄຳອະທີບາຍເພີ່ມເຕີມ
              </label>
              <textarea
                name="description"
                className="form-control form-control-lg"
                placeholder="ຖ້າມີ"
                rows={4}
                value={String(values.description)}
                onChange={handleChange}
              />
            </div>
          </form>
        </div>
        <div className="text-end">
          <button
            type="button"
            className="btn btn-success btn-lg w-100"
            disabled={createTing}
            onClick={() => handleSubmit()}
          >
            <i className="icon-check-circle" />
            <span className="ms-1">ຢືນຢັນສັ່ງຊື້</span>
          </button>
        </div>
      </div>
    </>
  );
}
