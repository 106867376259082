import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "./Schema";
import { useEffect, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import {
  ItemSelect,
  NO,
  closeLoadingScreen,
  currency,
  getStaffLogin,
  getUserRole,
  loadingScreen,
  messageError,
  messageSuccess,
  messageWarning,
} from "../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootVariable } from "../../utils";
import SearchDateRange from "../../utils/components/SearchDateRange";
import LimitData from "../../utils/components/LimitData";
import { Paths } from "../../routes/Path";
import SelectShop from "../../utils/components/selectShop";
import SummaryTotal from "./Summary";
import NavBar from "../../layouts/Navbar";
import Sidebar from "../../layouts/Sidebar";
import AddOrder from "./AddOrder";
import EditData from "./EditData";
import { FaRegShareSquare } from "react-icons/fa";
import Notiflix from "notiflix";
import FileBuy from "./FileBuy";
import AddAccount from "./AddAccount";

export default function CustomerAdd() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(RootVariable.limit);
  const [totalItem, setTotalItem] = useState(0);
  const [searchValue, setSearchValues] = useState("");
  const [listOder, setListOder] = useState([]);
  const [newRefresh, setNewRefresh] = useState(false);
  const [userAuth, setUserAuth]: any = useState({});
  const [localShop, setLocalShop] = useState<any>();
  const [selectedAllItem, setSelectedAllItem] = useState<any>(0);

  useEffect(() => {
    setUserAuth(getStaffLogin());
  }, []);
  const [updateManyOrder] = useMutation(Schema.updateManyOrder);
  const [queryCustomerAdd, { loading: loadingData }] = useLazyQuery(
    Schema.queryCreateOrder,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        console.log("====", data);
        setListOder(data?.orderCustomerCreates?.data);
        setTotalItem(data?.orderCustomerCreates?.total);
      },
    }
  );

  const getRole = getUserRole() === "SUPER_ADMIN";
  useEffect(() => {
    if (getRole) {
      queryCustomerAdd({
        variables: {
          where: {
            tell: searchValue ? searchValue : undefined,
            isConfirm: 0,
          },
          limit: numberRows,
          skip: numberRows * (currentPage - 1),
        },
      });
    } else {
      queryCustomerAdd({
        variables: {
          where: {
            shop: parseInt(userAuth?.shop?._id),
            isConfirm: 0,
          },
          limit: numberRows,
          skip: numberRows * (currentPage - 1),
        },
      });
    }
  }, [
    queryCustomerAdd,
    newRefresh,
    searchValue,
    userAuth,
    numberRows,
    currentPage,
    localShop,
  ]);
  const ConfirmSuccess = async () => {
    if (!selectedAllItem) {
      return messageWarning("ທ່ານຍັງບໍ່ໄດ້ເລືອກຂໍ້ມູນທື່ພິມສຳເລັດ");
    }
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      `ຕ້ອງການຢືນຢັນຮັບເຄື່ອງ ${currency(
        selectedAllItem?.length
      )} ແທ້ ຫຼື ບໍ່?`,
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await updateManyOrder({
            variables: {
              data: {
                isConfirm: 1,
              },
              where: {
                ids: selectedAllItem,
              },
            },
          });
          messageSuccess("ດຳເນີນງານສຳເລັດແລ້ວ");
          setSelectedAllItem("");
          setNewRefresh(!newRefresh);
          Notiflix.Loading.remove();
        } catch (error) {
          messageError("ມີຂໍ້ຜິດພາເເກີດຂື້ນ ກະລູນາລອງໃໝ່");
          Notiflix.Loading.remove();
          setNewRefresh(!newRefresh);
          console.log(error);
        }
      },
      () => {
        return false;
      }
    );
  };

  const ConfirmSuccessOne = async (id: number) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      `ຕ້ອງການຢືນຢັນຮັບເຄື່ອງແທ້ ຫຼື ບໍ່?`,
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await updateManyOrder({
            variables: {
              data: {
                isConfirm: 1,
              },
              where: {
                ids: id,
              },
            },
          });
          messageSuccess("ດຳເນີນງານສຳເລັດແລ້ວ");
          setSelectedAllItem("");
          setNewRefresh(!newRefresh);
          Notiflix.Loading.remove();
        } catch (error) {
          messageError("ມີຂໍ້ຜິດພາເເກີດຂື້ນ ກະລູນາລອງໃໝ່");
          Notiflix.Loading.remove();
          setNewRefresh(!newRefresh);
          console.log(error);
        }
      },
      () => {
        return false;
      }
    );
  };

  //pagination
  const totalPage = Math.ceil(totalItem / numberRows);
  useEffect(() => {
    let page = params.get("page");
    let rows = params.get("rows");
    const _search = params.get("search");

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(RootVariable.limit);
    }
    setSearchValues(_search ? String(_search) : "");
  }, [params]);
  return (
    <>
      <div className="page-wrapper">
        <NavBar />
        <Sidebar />
        <div className="content ms-2">
          <nav aria-label="breadcrumb" className="mb-4">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a
                  href="javascript:void(0)"
                  onClick={() => navigate(Paths.home)}
                >
                  ໜ້າຫຼັກ
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                ລາຍງານອໍ້ເດີ້ລູກຄ້າສັ່ງຊື້ສີນຄ້າ
              </li>
            </ol>
          </nav>
          {/* ofWeb */}
          {getUserRole() === "SUPER_ADMIN" || getUserRole() === "OWNER" ? (
            <SummaryTotal newRefresh={newRefresh} />
          ) : null}
          {getUserRole() === "SUPER_ADMIN" || getUserRole() === "OWNER" ? (
            <>
              <AddAccount
                onSuccess={() => {
                  setNewRefresh(!newRefresh);
                }}
              />
            </>
          ) : null}
          <div className="row">
            <div className="col-md-6 hiddenDivOfApp" style={{ marginTop: 28 }}>
              <button
                className="btn btn-success btn-lg w-100 my-1 events "
                onClick={() => ConfirmSuccess()}
                disabled={!selectedAllItem}
              >
                <i className="icon-check-circle" /> ຢືນຢັນເຄື່ອງທີ່ເລືອກ
                {selectedAllItem.length > 0 ? (
                  <strong className="box-total">
                    {currency(selectedAllItem.length)}
                  </strong>
                ) : null}
              </button>
            </div>
            <div className="col-md-6 mt-1">
              <div className="form-group">
                <label>ຄົ້ນຫາຕາມເບີລູກຄ້າ</label>
                <input
                  type="search"
                  placeholder="ປ້ອນເບີເພື່ອຄົ້ນຫາ..."
                  className="form-control form-control-lg"
                  onChange={(e) => {
                    params.set("search", e.target.value);
                    setParams(params);
                  }}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-3">
            <LimitData
              numberRows={numberRows}
              totalPage={totalPage}
              currentPage={currentPage}
              total={totalItem}
              hiddenSearch="hiddenSearch"
              col={6}
            />
          </div>

          <div className="row">
            <div className="col-12 p-2 card">
              {loadingData ? loadingScreen() : closeLoadingScreen()}
              <>
                <div className="table-responsive">
                  <table className="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="text-center" style={{ width: 20 }}>
                          <input
                            type="checkbox"
                            className="check-box"
                            id="selected-all"
                            onChange={(e) =>
                              setSelectedAllItem(ItemSelect.all(e))
                            }
                          />
                        </th>
                        <th>#</th>
                        <th className="text-nowrap">ຊື່ລູກຄ້າ</th>
                        <th className="text-nowrap">ຊື່ສິນຄ້າ</th>
                        <th className="text-nowrap">ຈັດການ</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listOder?.map((item: any, index) => (
                        <tr>
                          <td className="text-center" style={{ width: 20 }}>
                            <input
                              type="checkbox"
                              className="check-box item-checkbox"
                              value={item?._id}
                              onChange={() =>
                                setSelectedAllItem(ItemSelect.one())
                              }
                            />
                          </td>
                          <td className="text-center text-nowrap">
                            {NO(index, currentPage, numberRows)}
                          </td>
                          <td className="text-nowrap" width={120}>
                            {item?.name ?? " "} {item?.tell ?? " "}
                            <br />
                            {item?.isConfirm === 1 ? (
                              <>
                                <strong className="text-success">
                                  <i className="icon-check-circle me-1" />{" "}
                                  ຮັບເຄື່ອງແລ້ວ
                                </strong>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-success btn-sm w-100 my-1 events "
                                  onClick={() => ConfirmSuccessOne(item?._id)}
                                >
                                  <i className="icon-check-circle" />{" "}
                                  ຢືນຢັນເຄື່ອງ
                                </button>
                              </>
                            )}
                          </td>
                          <td className="text-start text-nowrap">
                            {item?.title ?? " "} COD: {item?.cod ?? " "}
                            <br />
                            ຂົນສົ່ງ:{item?.companySend ?? " "}
                            <br />
                            {item?.files_bank ? (
                              <>
                                <FileBuy
                                  sentPathImage={
                                    item?.files_bank ? item?.files_bank : ""
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <b className="text-danger"> ເກັບປາຍທາງ</b>
                              </>
                            )}
                          </td>
                          <td className="text-center text-nowrap" width={100}>
                            <div className="btn-group btn-sm py-0">
                              {getUserRole() === "SUPER_ADMIN" ||
                              getUserRole() === "OWNER" ? (
                                <>
                                  <EditData
                                    onSuccess={() => {
                                      setNewRefresh(!newRefresh);
                                    }}
                                    _data={item}
                                  />
                                </>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="container mt-3">
                  {(() => {
                    return (
                      <ResponsivePagination
                        total={totalPage}
                        current={currentPage || 1}
                        onPageChange={(page: number) => {
                          params.set("page", page.toString());
                          setParams(params);
                        }}
                      />
                    );
                  })()}
                </div>
              </>
            </div>
          </div>
        </div>
        <br />
        <div
          className="CustomBottom p-3"
          style={{ height: 60, paddingTop: 10, backgroundColor: "#f0f0f5" }}
        >
          <div
            className="w-100 text-center me-1"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              className="btn btn-success btn-lg my-1 events w-100  "
              onClick={() => ConfirmSuccess()}
              disabled={!selectedAllItem}
            >
              <i className="icon-check-circle" /> ຢືນຢັນເຄື່ອງທີ່ເລືອກ
              {selectedAllItem.length > 0 ? (
                <strong className="box-total">
                  {currency(selectedAllItem.length)}
                </strong>
              ) : null}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
