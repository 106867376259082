import { useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { currency, formatDate, getLocalShop } from "../../utils";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import logo from "../../assets/images/cart.png";
import { url_path_IBank } from "../../utils/url";

export default function InvoiceReportScreen({ data }: any) {
  const elmRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });
  const [localShop, setLocalShop] = useState<any>();
  useEffect(() => {
    setLocalShop(getLocalShop());
  }, []);

  const [show, setShow] = useState(false);

  return (
    <>
      <button
        className="btn btn-outline-primary my-0"
        onClick={() => setShow(true)}
      >
        <i className="icon-print" />
      </button>
      <Modal show={show} size="lg">
        <Modal.Header
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Title>
            <i className="icon-print me-1" />
            ພິມໃບຮັບເງິນ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ color: "#333", height: "100%", width: "100%" }}>
            <table
              // bgcolor="#f6f6f6"
              cellSpacing={0}
              style={{ borderCollapse: "collapse", padding: 40, width: "100%" }}
              ref={elmRef}
              id="invoice"
            >
              <tbody>
                <tr>
                  <td width="5px" style={{ padding: 0 }} />
                  <td
                    style={{
                      clear: "both",
                      display: "block",
                      margin: "0 auto",
                      maxWidth: 600,
                      padding: "10px 0",
                      border: "1px solid #000",
                    }}
                  >
                    <table
                      width="100%"
                      cellSpacing={0}
                      style={{ borderCollapse: "collapse" }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ padding: 0 }}>
                            <a
                              href="#"
                              style={{ color: "#348eda" }}
                              target="_blank"
                            >
                              <img
                                src="assets/img/logo.png"
                                alt="Bootdey.com"
                                style={{
                                  height: 50,
                                  maxWidth: "100%",
                                  width: 157,
                                }}
                                height={50}
                                width={157}
                              />
                            </a>
                            {/* <br/> */}
                            {/* <strong>tel & app (20 52556559)</strong> */}
                          </td>
                          <td
                            style={{
                              color: "#999",
                              fontSize: 12,
                              padding: 0,
                              textAlign: "right",
                            }}
                            align="right"
                          >
                            {data?.shop?.title ?? "-"} tel & app(
                            {data?.shop?.phoneNumber ?? "-"})
                            <br />
                            ເລກບິນ: {data?.billNO ?? "-"}
                            <br />
                            {data?.createdAt
                              ? formatDate(data?.createdAt)
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="5px" style={{ padding: 0 }} />
                </tr>

                <tr>
                  <td width="5px" style={{ padding: 0 }} />
                  <td
                    style={{
                      border: "1px solid #000",
                      borderTop: 0,
                      clear: "both",
                      display: "block",
                      margin: "0 auto",
                      maxWidth: 600,
                      padding: 0,
                    }}
                  >
                    <table
                      cellSpacing={0}
                      style={{
                        borderCollapse: "collapse",
                        margin: "0 auto",
                        maxWidth: 600,
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td valign="top">
                            <h3
                              style={{
                                borderBottom: "1px solid #000",
                                color: "#000",
                                fontFamily:
                                  '"Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
                                fontSize: 18,
                                fontWeight: "bold",
                                lineHeight: "1.2",
                                margin: 0,
                                marginBottom: 15,
                                paddingBottom: 5,
                              }}
                            >
                              ລາຍລະອຽດ
                            </h3>
                            <table
                              cellSpacing={0}
                              style={{
                                borderCollapse: "collapse",
                                marginBottom: 40,
                                width: "100%",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td style={{ padding: "5px 0" }}>ຈຳນວນ</td>
                                  <td
                                    align="right"
                                    style={{ padding: "5px 0" }}
                                  >
                                    {currency(data?.totalShop) ?? 0} ຮ້ານ
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: "5px 0" }}>
                                    ລວມຍອດຂາຍ
                                  </td>
                                  <td
                                    align="right"
                                    style={{ padding: "5px 0" }}
                                  >
                                    {currency(data?.totalAmount) ?? 0} ກິບ
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: "5px 0" }}>
                                    ເປີເຊັນແນະນຳ
                                  </td>
                                  <td
                                    align="right"
                                    style={{ padding: "5px 0" }}
                                  >
                                    {currency(data?.percent) ?? 0} %
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: "5px 0" }}>
                                    ລວມຍອດຄ່າຄອມມິສຊັ່ນ
                                  </td>
                                  <td
                                    align="right"
                                    style={{ padding: "5px 0" }}
                                  >
                                    {data?.totalCommission
                                      ? currency(data?.totalCommission)
                                      : 0}{" "}
                                    ກີບ
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      borderBottom: "2px solid #000",
                                      borderTop: "2px solid #000",
                                      fontWeight: "bold",
                                      padding: "5px 0",
                                    }}
                                  >
                                    ສະຫຼຸບຍອດລວມທີ່ຕ້ອງຈ່າຍ
                                  </td>
                                  <td
                                    align="right"
                                    style={{
                                      borderBottom: "2px solid #000",
                                      borderTop: "2px solid #000",
                                      fontWeight: "bold",
                                      padding: "5px 0",
                                    }}
                                  >
                                    {currency(data?.totalCommission) ?? 0} ກີບ
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="5px" style={{ padding: 0 }} />
                </tr>

                <tr>
                  <td width="5px" style={{ padding: 0 }} />
                  <td
                    style={{
                      border: "1px solid #000",
                      clear: "both",
                      display: "block",
                      margin: "0 auto",
                      maxWidth: 600,
                      padding: 0,
                    }}
                  >
                    <table
                      width="100%"
                      style={{
                        borderCollapse: "collapse",
                        color: "#999",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            align="center"
                            width="50%"
                            style={{ padding: 20 }}
                          >
                            ຂອບໃຈທີ່ໃຊ້ບໍລິການ HappyShop
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style={{ padding: 0 }} />
                  <td width="5px" style={{ padding: 0 }} />
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={handlePrint}
          >
            <i className="icon-print" />
            <span className="ms-1">ພິມ</span>
          </button>
          <button
            type="button"
            className="btn btn-light btn-lg"
            onClick={() => setShow(false)}
          >
            <i className="icon-x" />
            <span className="ms-1">ຍົກເລີກ</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
