import React, { useEffect, useState } from "react";
import {
  Form,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Paths } from "../../routes/Path";
import ResponsivePagination from "react-responsive-pagination";
import { GrNext } from "react-icons/gr";

import {
  NO,
  RootVariable,
  _month,
  closeLoadingScreen,
  currency,
  endOfMonth,
  getLocalShop,
  getStaffLogin,
  getYear,
  loadingScreen,
  startOfMonth,
} from "../../utils";
import Schema from "./Schema";
import { useLazyQuery, useMutation } from "@apollo/client";
import NoData from "../../utils/components/NoData";
import moment from "moment";
import { BsBox2Heart } from "react-icons/bs";
export default function ListOrder() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [startDate, setStartDate]: any = useState(null);
  const [endDate, setEndDate]: any = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(RootVariable.limit);
  const [totalItem, setTotalItem] = useState(0);
  const [localShop, setLocalShop] = useState<any>();
  const [userAuth, setUserAuth]: any = useState({});

  const [dataMapYear] = useState(getYear());
  const currentMonth = undefined;
  const currentYear = moment(new Date()).format("YYYY");

  const [getMothCurren, setGetMonth] = useState<any>();
  const [getYearCurren, setGetYear] = useState<any>(currentYear);

  const [listOrderSummary, setHistoryOrder]: any = useState([]);
  const [queryHistoryOrder, { loading: loadingData }] = useLazyQuery(
    Schema.queryOrderSummary,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (getSummary) => {
        setHistoryOrder(getSummary?.summaryTotalOFMonths?.getSummary);
      },
    }
  );
  useEffect(() => {
    setLocalShop(getLocalShop());
  }, []);
  useEffect(() => {
    setUserAuth(getStaffLogin());
  }, []);

  useEffect(() => {
    queryHistoryOrder({
      variables: {
        where: {
          forMoth: parseInt(getMothCurren)
            ? parseInt(getMothCurren)
            : undefined,
          forYear: parseInt(getYearCurren),
          shop: localShop?._id ? localShop?._id : userAuth?.shop?._id,
        },
        limit: numberRows,
        skip: numberRows * (currentPage - 1),
      },
    });
  }, [
    queryHistoryOrder,
    currentPage,
    numberRows,
    startDate,
    endDate,
    userAuth,
    localShop,
    getMothCurren,
    getYearCurren,
  ]);
  //pagination

  const totalPage = Math.ceil(totalItem / numberRows);
  useEffect(() => {
    let page = params.get("page");
    let rows = params.get("rows");
    let startDate = params.get("startDate");
    let endDate = params.get("endDate");
    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(startOfMonth());
    }
    if (endDate) {
      setEndDate(endDate);
    } else {
      setEndDate(endOfMonth());
    }

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(RootVariable.limit);
    }
  }, [params]);

  return (
    <div className="page-wrapper">
      <div className="content">
        <nav aria-label="breadcrumb" className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={() => navigate(Paths.home)}>
                ໜ້າຫຼັກ
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              ລາຍງານຍອດອໍເດີ່ລາຍເດືອນ
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="section mt-4 col-md-6">
            <label className="fs-5">ເລືອກເດືອນ/ປີ</label>
            <div className="input-group">
              <select
                className="form-select form-select-lg bg-white text-center "
                onChange={(e) => {
                  setGetMonth(e.target.value);
                }}
              >
                {_month?.map((item, index) => (
                  <option
                    key={index}
                    selected={item.id === currentMonth ? true : false}
                    value={item?.id}
                  >
                    {item?.month}
                  </option>
                ))}
              </select>
              <select
                className="form-select form-select-lg bg-white text-center"
                onChange={(e) => setGetYear(e.target.value)}
              >
                {dataMapYear?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row my-2">
          {/* <LimitData
            numberRows={numberRows}
            totalPage={totalPage}
            currentPage={currentPage}
            total={totalItem}
           hiddenSearch={true}
            col={6}
          /> */}
        </div>

        <div className="row">
          <div className="col-12  vh-100">
            {loadingData ? loadingScreen() : closeLoadingScreen()}
            <>
              {listOrderSummary?.map((item: any, index: number) => (
                <a
                  href="javascript:void(0)"
                  onClick={() =>
                    navigate(
                      `${Paths.detail_item}?get_id_s=${item?.shop}&for_m=${item?.forMoth}&of_y=${currentYear}`
                    )
                  }
                >
                  <div className="col-lg-12 col-12" key={index}>
                    <div
                      className="dash-count my-2"
                      style={{ backgroundColor: "#ededed", cursor: "pointer" }}
                    >
                      <div className="text-primary">
                        <BsBox2Heart size={60} />
                      </div>
                      <div className="dash-counts text-dark w-50">
                        <h3>ອໍເດີ່ຂອງເດືອນ</h3>
                        <h3>
                          {item?.forMoth} / {item?.forYear}
                        </h3>
                      </div>
                      <div className="text-right d-flex">
                        <h4 className="text-primary">
                          {item?.total ? currency(item?.total) : 0}
                        </h4>
                        <h4 className="ms-3">
                          <GrNext style={{ opacity: 0.6, cursor: "pointer" }} />
                        </h4>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </>
            <div className="container mt-3">
              {(() => {
                return (
                  <ResponsivePagination
                    total={totalPage}
                    current={currentPage || 1}
                    onPageChange={(page: number) => {
                      params.set("page", page.toString());
                      setParams(params);
                    }}
                  />
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
