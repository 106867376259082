import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as _ from "lodash";
import { Form, Modal } from "react-bootstrap";
import { MdCategory, MdOutlineEditNote } from "react-icons/md";
import {
  InvalidText,
  messageError,
  messageSuccess,
} from "../../utils";
import Schema from "./Schema";
export default function EditCategory({ onSuccess, item }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [updateCategory, { loading: adding }] = useMutation(
    Schema.UpdateCategory
  );
  const {
    handleChange,
    errors,
    values,
    handleSubmit,
    resetForm,
    isDisabled,
    setFieldValue,
  } = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    enableReinitialize: false,
    validate: (values) => {
      const errors = {};
      if (!values.title) {
        errors.title = "ກະລຸນາປ້ອນປະເພດສິນຄ້າ";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const { data: updated } = await updateCategory({
          variables: {
            data: {
              title: values.title,
              description: values.description,
            },
            where: {
              _id: parseInt(item?._id),
            },
          },
        });
        console.log(updated.updateCategory.status);
        if (updated.updateCategory.status === 200) {
          messageSuccess("ການດຳເນີນງານສຳເລັດ");
          setShow(false);
          onSuccess();
          resetForm();
        } else {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      } catch (error) {
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
      }
    },
  });

  useEffect(() => {
    if (item) {
      setFieldValue("title", item?.title, false);
      setFieldValue("description", item?.description, false);
    }
  }, [item]);

  return (
    <React.Fragment>
      <button
        className="btn btn-outline-primary btn-sm"
        onClick={() => setShow(true)}
      >
        <i className="icon-edit" />
      </button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton className="fs-6">
          <MdCategory className="me-1 fs-4" /> ແກ້ໄຂປະເພດສິນຄ້າ
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>ປະເພດສິດຄ້າ{InvalidText()}</label>
                <input
                  name="title"
                  type="text"
                  placeholder="ຈັດການໝວດໝູ່(ລາວ)"
                  onChange={handleChange}
                  className={
                    errors.title ? "form-control is-invalid" : "form-control"
                  }
                  value={values.title}
                />
                <i className="text-danger">{errors.title}</i>
              </div>
              <div className="form-group">
                <label>ລາຍລະອຽດ</label>
                <textarea
                  name="description"
                  className="form-control"
                  rows={5}
                  value={values.description}
                  onChange={handleChange}
                  placeholder="ປ້ອນລາຍລະອຽດ"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleSubmit()}
            disabled={isDisabled || !values.title}
          >
            <i className="icon-check-circle" /> ບັນທຶກ
          </button>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => handleClose()}
          >
            <i className="icon-x me-2" />
            ຍົກເລີກ
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
