import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "./Schema";
import { useEffect, useState } from "react";
import "./index.scss";
import ResponsivePagination from "react-responsive-pagination";
import {
  ItemSelect,
  NO,
  closeLoadingScreen,
  currency,
  formatDateTime,
  getLocalShop,
  getStaffLogin,
  loadingScreen,
  messageError,
  messageSuccess,
  messageWarning,
} from "../../utils";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootVariable } from "../../utils";
import LimitData from "../../utils/components/LimitData";
import Notiflix, { Loading } from "notiflix";
import SummaryTotal from "./Summary";
import { Paths } from "../../routes/Path";
import { getUserRole } from "../../utils";
import { Form, Spinner } from "react-bootstrap";
import { PiWarningCircleFill } from "react-icons/pi";
import EditItem from "./EditItem";
import moment from "moment";
import NewInvoice from "./NewInvoice";
import CountOrderByUser from "./countOrderByUser";

export default function HomeScreen() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [userAuth, setUserAuth] = useState<any>({});
  const [localShop, setLocalShop] = useState<any>();

  const [startDate, setStartDate]: any = useState(null);
  const [endDate, setEndDate]: any = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(100);
  const [selectedAllItem, setSelectedAllItem] = useState<any>(0);
  const [status, setStatus] = useState("");
  const [listOrder, setListOrder] = useState([]);
  const [newRefresh, setNewRefresh] = useState(false);
  const [obj, setObj]: any = useState({});
  const [updateOrder] = useMutation(Schema.updateOrder);
  const [deleteOrder] = useMutation(Schema.deleteOrder);
  const [updateMany] = useMutation(Schema.updateManyItem);
  const [createOrder, { loading }] = useMutation(Schema.createOrder);
  const [listUser, setListUser] = useState([]);
  const today = moment().format("YYYY-MM-DD");
  const [checkBox, setCheckBox] = useState<any>(0);
  const [description, setDescription] = useState<any>();
  const [trackingIds, setTrackingId] = useState<any>();
  const [shopData, setDataShop]: any = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setUserAuth(getStaffLogin());
    setLocalShop(getLocalShop());
  }, []);
  const [queryShop] = useLazyQuery(Schema.queryShop, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setDataShop(data?.shops?.total);
    },
  });
  const [queryOrder, { data: dataItems, loading: loadingData }] = useLazyQuery(
    Schema.queryOrder,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [queryUser, { loading: loadingUserData }] = useLazyQuery(
    Schema.queryUser,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setListUser(data?.users?.data);
      },
    }
  );

  useEffect(() => {
    let _where = {};
    if (localShop) {
      _where = {
        _id: localShop?._id,
      };
    } else {
      _where = {
        _id: userAuth?.shop?._id,
      };
    }
    queryShop({
      variables: {
        where: {
          isDeleted: 0,
          isPublic: 1,
          ..._where,
        },
      },
    });

    queryUser({
      variables: {
        where: {
          shop: parseInt(localShop?._id),
          isDeleted: 0,
        },
        limit: 20,
      },
    });
  }, [queryShop, userAuth, localShop]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    let _where = {};
    if (localShop) {
      _where = {
        shop: localShop?._id,
      };
    } else {
      _where = {
        shop: userAuth?.shop?._id,
      };
    }
    queryOrder({
      variables: {
        where: {
          ..._where,
          orderStatus: status ? status : undefined,
          createdAtBetween: [startDate, endDate],
          isDeleted: 0,
        },
        limit: numberRows,
        skip: numberRows * (currentPage - 1),
      },
    });
  }, [
    newRefresh,
    userAuth,
    localShop,
    numberRows,
    currentPage,
    startDate,
    endDate,
    status,
  ]);
  useEffect(() => {
    const results = dataItems?.orders;
    if (search) {
      const filter = results?.data?.filter((obj: any) => {
        if (
          obj?.trackingId?.includes(search) ||
          obj?.description?.includes(search) ||
          obj?.orderID?.includes(search)
        ) {
          return obj;
        }
      });
      setListOrder(filter);
    } else {
      setListOrder(results?.data || []);
    }
  }, [dataItems, search]);

  const {
    handleChange,
    errors,
    values,
    handleSubmit,
    resetForm,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      description: description ?? "",
      trackingId: trackingIds ?? "",
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: any = {};
      if (!description) {
        errors.description = "ກະລຸນາປ້ອນລາຍລະອຽດອໍເດີ່ ";
      }
      if (getArray.length > 5 || getArray.length < 3) {
        errors.description =
          "ທ່ານກົດ enter ຫຼື ປ້ອນຂໍ້ມູນເກີນ 5 ແຖວແລ້ວ  ຫຼື ຕ້ອງປ້ອນຫຼາຍກວ່າ 3 ແຖວ";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        let status = false;
        if (!obj?.description && !obj?.receiveName && !obj?.receivePhone) {
          const { data: create } = await createOrder({
            variables: {
              data: {
                description: String(description),
                shop: userAuth?.shop?._id,
                trackingId: String(trackingIds),
              },
            },
          });
          if (create) {
            status = true;
          }
        } else {
          const { data: update } = await updateOrder({
            variables: {
              data: {
                description: String(values?.description),
              },
              where: {
                _id: obj?._id,
              },
            },
          });
          if (update) {
            status = true;
          }
        }
        if (status === true) {
          setDescription("");
          setTrackingId("");
          setTimeout(() => {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setNewRefresh(!newRefresh);
            setObj({});
          }, 1000);
        } else {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      } catch (error) {
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
        console.log(error);
      }
    },
  });
  //check Enter InputData
  const getData = description ? description : "";
  const getArray = getData.split("\n");
  const getArrayLength = getArray.length;
  const handleCheckboxChange = () => {
    setCheckBox(checkBox === 1 ? 0 : 1);
  };
  useEffect(() => {
    if (getArrayLength?.length < 3) return;
    if (getArrayLength > 3 && checkBox === 1) {
      handleSubmit();
    }
  }, [checkBox, getArrayLength]);

  useEffect(() => {
    setDescription(obj?.description);
  }, [obj]);

  //pagination
  const totalPage = Math.ceil(dataItems?.orders?.total / numberRows);
  const totalItem = dataItems?.orders?.total;
  useEffect(() => {
    let page = params.get("page");
    let rows = params.get("rows");
    let startDate = params.get("startDate");
    let endDate = params.get("endDate");
    let status = params.get("status");

    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(today);
    }
    if (endDate) {
      setEndDate(endDate);
    } else {
      setEndDate(today);
    }

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(100);
    }
    if (status) {
      setStatus(String(status));
    } else {
      setStatus("PENDING");
    }
  }, [params]);

  const _printSuccess = async () => {
    if (!selectedAllItem) {
      return messageWarning("ທ່ານຍັງບໍ່ໄດ້ເລືອກຂໍ້ມູນທື່ພິມສຳເລັດ");
    }
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      `ຕ້ອງການຢືນຢັນພິມ ${currency(selectedAllItem?.length)} ແທ້ ຫຼື ບໍ່?`,
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await updateMany({
            variables: {
              data: {
                orderStatus: "PRINTED",
              },
              where: {
                idAll: selectedAllItem,
              },
            },
          });
          Notiflix.Loading.remove();
          messageSuccess("ສົ່ງພັດສະດຸສຳເລັດແລ້ວ");
          setSelectedAllItem("");
          setNewRefresh(!newRefresh);
        } catch (error) {
          Notiflix.Loading.remove();
          messageError("ມີຂໍ້ຜິດພາເເກີດຂື້ນ ກະລູນາລອງໃໝ່");
          console.log(error);
        }
      },
      () => {
        return false;
      }
    );
  };

  const _deleteOrder = (id: number) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລືບ ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await deleteOrder({
            variables: {
              where: {
                _id: Number(id),
              },
            },
          });
          messageSuccess("ການດຳເນີນງານສຳເລັດ");
          setNewRefresh(!newRefresh);
        } catch (error) {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };
  const _deleteOrderSelect = async () => {
    if (!selectedAllItem) {
      return messageWarning("ທ່ານຍັງບໍ່ໄດ້ເລືອກຂໍ້ມູນທື່ລຶບ");
    }
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      `ທ່ານຕ້ອງການລືບ  ${currency(selectedAllItem?.length)} ແທ້ ຫຼື ບໍ່?`,
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await updateMany({
            variables: {
              data: {
                isDeleted: 1,
              },
              where: {
                idAll: selectedAllItem,
              },
            },
          });
          setSelectedAllItem(0);
          Notiflix.Loading.remove();
          messageSuccess("ລຶບສຳເລັດ");
          setNewRefresh(!newRefresh);
        } catch (error) {
          Notiflix.Loading.remove();
          messageError("ມີຂໍ້ຜິດພາເເກີດຂື້ນ ກະລູນາລອງໃໝ່");
          console.log(error);
        }
      },
      () => {
        return false;
      }
    );
  };

  const handleClick = () => {
    const url = `/print_all_bill?getIds=${selectedAllItem}&limit_print=${numberRows}`;
    window.open(url, "_blank");
  };

  const handleClickOne = (id: number, bill: string) => {
    const url = `/print_all_bill?getIds=${id}&bill=${bill}&limit_print=${numberRows}`;
    window.open(url, "_blank");
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <nav aria-label="breadcrumb" className="mb-4">
          <NewInvoice />
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={() => navigate(Paths.home)}>
                ໜ້າຫຼັກ
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              ຈັດການອໍເດີ່
            </li>
            <li className="breadcrumb-item">
              {getUserRole() === "SUPER_ADMIN" || getUserRole() === "OWNER" ? (
                <>
                  {listUser?.map((res: any, index) => (
                    <>
                      {res?.role !== "SUPER_ADMIN" ? (
                        <span
                          className="box-user events fs-6 me-2 px-3"
                          key={index}
                        >
                          <i className="icon-person" /> {res?.fullName}
                          <strong className="box-total text-center text-white">
                            <CountOrderByUser
                              startDate={startDate}
                              endDate={endDate}
                              newRefresh={!newRefresh}
                              userID={res?._id}
                            />
                          </strong>
                        </span>
                      ) : null}
                    </>
                  ))}
                </>
              ) : null}
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-12 col-md-12">
            <div className="card" style={{ borderRadius: "10px" }}>
              <div
                className="card-body"
                style={{
                  backgroundColor: "#f1f1f1",
                  borderRadius: "10px",
                }}
              >
                <div className="row">
                  <div className="col-12 col-md-5 ">
                    <label htmlFor="email">ລະຫັດອໍເດີ່(ຖ້າມີ)</label>
                    <input
                      type="text"
                      placeholder="ລະຫັດອໍເດີ່"
                      className="form-control form-control-lg mb-2"
                      name="trackingId"
                      value={trackingIds}
                      onChange={(e) => setTrackingId(e.target.value)}
                      style={{
                        color: "black",
                        border: "1px solid #51ea89",
                        borderRadius: "8px",
                      }}
                    />
                    <label htmlFor="email">
                      ລາຍລະອຽດອໍເດີ່{" "}
                      <b className="text-danger">
                        * (ຊື່ລູກຄ້າ ເບີໂທ ບ່ອນຝາກ ຊື່ສິນຄ້າ ຈຳນວນເຄື່ອງ ...)
                      </b>
                    </label>
                    <textarea
                      name="description"
                      style={{
                        color: "black",
                        border: "1px solid #51ea89",
                        borderRadius: "8px",
                      }}
                      className={
                        errors.description
                          ? "form-control order-detail is-invalid"
                          : "form-control order-detail  invalid"
                      }
                      placeholder="ຊື່ລູກຄ້າ ເບີໂທ ບ່ອນຝາກ ຊື່ສິນຄ້າ ຈຳນວນເຄື່ອງ ..."
                      rows={3}
                      value={description}
                      autoFocus={true}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                    {getArray.length < 3 ? (
                      <>
                        <div
                          className="border-bottom my-2"
                          style={{ fontSize: "14px", color: "#bdbebf" }}
                        >
                          ຕ້ອງປ້ອນຫຼາຍກວ່າ 3 ແຖວຂື້ນ (ປ້ອນຊື່ ກົດ Enter ເບີໂທ
                          ກົດ Enter ບ່ອນຝາກ ...)
                        </div>
                      </>
                    ) : (
                      <>
                        {getArray.length > 5 ? (
                          <div
                            className="border-bottom my-2"
                            style={{ fontSize: "14px", color: "#bdbebf" }}
                          >
                            ທ່ານກົດ enter ຫຼື ປ້ອນຂໍ້ມູນລົງແຖວເກີນ 5 ແຖວແລ້ວ
                            (ບໍ່ຄວນເກີນ 5 ແຖວ)
                          </div>
                        ) : (
                          <>
                            <div
                              className="border-bottom"
                              style={{ fontSize: "16px", color: "#65b800" }}
                            >
                              ທ່ານປ້ອນຂໍ້ມູນ {getArray.length} ແຖວ.
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {shopData > 0 ? (
                      <>
                        <div className="input-group mt-2">
                          <input
                            type="checkbox"
                            disabled={loading}
                            className="check-box"
                            onChange={handleCheckboxChange}
                          />{" "}
                          <span className="text-success ms-1">
                            ບັນທຶກອັດຕະໂນມັດ
                          </span>
                        </div>
                        {loading ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-success w-100 btn-lg mt-2"
                            >
                              <Spinner animation="border" variant="info" />
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-success w-100 btn-lg mt-2"
                              onClick={() => handleSubmit()}
                              disabled={checkBox}
                            >
                              <i className="icon-check-circle" /> ບັນທຶກ
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <button className="btn btn-outline-primary w-100">
                          <PiWarningCircleFill className="me-1" />
                          <b style={{ fontSize: "13px" }}>
                            ຮ້ານຂອງທ່ານຖືກປິດ (ແຈ້ງຜູ້ຮັບຜິດຊອບເພື່ອກວດສອບ)
                          </b>
                        </button>
                      </>
                    )}
                  </div>
                  <div className="col-12 col-md-7">
                    <SummaryTotal
                      startDate={startDate}
                      endDate={endDate}
                      newRefresh={newRefresh}
                    />
                    <div className="row">
                      <div className="col-12 col-lg-12 mt-4">
                        <div className="btn-group w-100 pt-1">
                          <button
                            className={
                              status === "PENDING"
                                ? "btn btn-primary btn-lg"
                                : "btn btn-outline-primary"
                            }
                            onClick={() => (
                              params.set("status", "PENDING"), setParams(params)
                            )}
                          >
                            ອໍເດີ່ທີ່ສ້າງໃໝ່
                          </button>
                          <button
                            className={
                              status === "PRINTED"
                                ? "btn btn-primary btn-lg"
                                : "btn btn-outline-primary"
                            }
                            onClick={() => (
                              params.set("status", "PRINTED"), setParams(params)
                            )}
                          >
                            ອໍເດີ່ພິມບິນແລ້ວ
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="input-group">
              <Form.Control
                type="date"
                className="form-control form-control-lg"
                defaultValue={startDate}
                onChange={(e: any) => {
                  params.set("startDate", e.target.value);
                  setParams(params);
                }}
              />
              <Form.Control
                type="date"
                className="form-control form-control-lg"
                value={endDate}
                onChange={(e: any) => {
                  params.set("endDate", e.target.value);
                  setParams(params);
                }}
              />
            </div>
          </div>
          {/* of web */}
          <div className="col-12 col-lg-8  hiddenDivOfApp">
            <button
              className="btn btn-warning btn-lg fs-5 events"
              disabled={!selectedAllItem}
              onClick={handleClick}
            >
              <i className="icon-print" /> ພິມລາຍການທີ່ເລືອກ
              {selectedAllItem.length > 0 ? (
                <strong className="box-total">
                  {currency(selectedAllItem.length)}
                </strong>
              ) : null}
            </button>
            <button
              className="btn btn-success btn-lg ms-2 fs-5 events"
              onClick={() => _printSuccess()}
              disabled={!selectedAllItem}
            >
              <i className="icon-check-circle" /> ຢືນຢັນພິມບິນທີ່ເລືອກ
              {selectedAllItem.length > 0 ? (
                <strong className="box-total">
                  {currency(selectedAllItem.length)}
                </strong>
              ) : null}
            </button>
            {/* {getUserRole() === "SUPER_ADMIN" ? ( */}
            <button
              className="btn btn-danger btn-lg ms-2 fs-5 events"
              onClick={() => _deleteOrderSelect()}
              disabled={!selectedAllItem}
            >
              <i className="icon-trash" /> ລຶບບິນທີ່ເລືອກ
              {selectedAllItem.length > 0 ? (
                <strong className="box-total">
                  {currency(selectedAllItem.length)}
                </strong>
              ) : null}
            </button>
            {/*  ) : null} */}
          </div>
          {/* end Of wev */}
          {/* oF Mobile */}
          <div className="col-12 col-lg-8  hiddenDivOfWeb">
            <button
              className="btn btn-warning btn-lg w-100 my-1 events"
              disabled={!selectedAllItem}
              onClick={handleClick}
              style={{ fontSize: "14px" }}
            >
              <i className="icon-print" /> ພິມບິນ{" "}
              {selectedAllItem.length > 0 ? (
                <strong className="box-total">
                  {currency(selectedAllItem.length)}
                </strong>
              ) : null}
            </button>
            <button
              className="btn btn-success btn-lg w-100 my-1 events"
              onClick={() => _printSuccess()}
              disabled={!selectedAllItem}
            >
              <i className="icon-check-circle" /> ຢືນຢັນພິມບິນ
              {selectedAllItem.length > 0 ? (
                <strong className="box-total">
                  {currency(selectedAllItem.length)}
                </strong>
              ) : null}
            </button>
            {/* {getUserRole() === "SUPER_ADMIN" ? ( */}
            <button
              className="btn btn-danger btn-lg w-100 my-1 events"
              onClick={() => _deleteOrderSelect()}
              disabled={!selectedAllItem}
            >
              <i className="icon-trash" /> ລຶບບິນ
              {selectedAllItem.length > 0 ? (
                <strong className="box-total">
                  {currency(selectedAllItem.length)}
                </strong>
              ) : null}
            </button>
            {/* ) : null} */}
          </div>
          {/* end Of Mobile */}
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="card">
              <div className="row pt-2 px-2">
                <LimitData
                  numberRows={numberRows}
                  totalPage={totalPage}
                  currentPage={currentPage}
                  total={totalItem}
                  hiddenSearch={true}
                  col={6}
                />
              </div>
              <div className=" p-2 col-md-5 text-end float-end ">
                <input
                  type="search"
                  className="form-control form-control-lg mt-2"
                  placeholder="ຄົ້ນຫາຕາມເລກບິນ ຫຼື ຄຳອະທີບາຍ"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  {loadingData ? loadingScreen() : closeLoadingScreen()}
                  <div className="table-responsive">
                    <table className="table table-hover table-bordered table-striped m-2">
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: 20 }}>
                            <input
                              type="checkbox"
                              className="check-box"
                              id="selected-all"
                              onChange={(e) =>
                                setSelectedAllItem(ItemSelect.all(e))
                              }
                            />
                          </th>
                          <th className="text-nowrap text-center">ຈັດການ</th>
                          <th>#</th>
                          <th className="text-nowrap">ລະຫັດອໍເດີ່</th>
                          <th className="text-nowrap">ລາຍລະອຽດອໍເດີ່</th>
                          <th className="text-nowrap">ສະຖານະອໍເດີ່</th>
                          <th className="text-nowrap">ວັນທີອໍເດີ່</th>
                          <th className="text-nowrap">ຮັບອໍເດີ່ໂດຍ</th>
                          <th className="text-nowrap">ລະຫັດອໍເດີ່ປ້ອນເອງ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listOrder &&
                          listOrder?.map((item: any, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  item?.orderStatus === "PRINTED"
                                    ? "#eafce6"
                                    : "",
                              }}
                            >
                              <td className="text-center" style={{ width: 20 }}>
                                <input
                                  type="checkbox"
                                  className="check-box item-checkbox"
                                  value={item?._id}
                                  onChange={() =>
                                    setSelectedAllItem(ItemSelect.one())
                                  }
                                />
                              </td>
                              <td
                                className="text-center text-nowrap"
                                width={200}
                              >
                                <div className="btn-group btn-sm py-0">
                                  <button
                                    className="btn btn-outline-primary my-0"
                                    onClick={(e) =>
                                      handleClickOne(item?._id, item?.orderID)
                                    }
                                    title="ພິມບິນລາຍການນີ້"
                                  >
                                    <i className="icon-print" />
                                  </button>

                                  {item?.orderStatus !== "COMPLETED" &&
                                  localShop?._id ? (
                                    <>
                                      <EditItem
                                        onSuccess={() => {
                                          setNewRefresh(!newRefresh);
                                        }}
                                        sendData={item}
                                      />
                                      {getUserRole() === "SUPER_ADMIN" ||
                                      getUserRole() === "ADMIN" ||
                                      getUserRole() === "OWNER" ? (
                                        <button
                                          className="btn btn-outline-primary my-0"
                                          onClick={(e) => {
                                            _deleteOrder(item?._id);
                                          }}
                                          title="ລຶບບິນ"
                                        >
                                          <i className="icon-trash" />
                                        </button>
                                      ) : null}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </td>
                              <td
                                className="text-center text-nowrap"
                                style={{ width: 50 }}
                              >
                                {NO(index, currentPage, numberRows)}
                              </td>
                              <td
                                className="text-center text-nowrap"
                                style={{ width: 120 }}
                              >
                                {item?.orderID ?? "-"}
                              </td>
                              <td width={300} className="text-nowrap">
                                {item?.description ?? ".."}
                              </td>
                              <td
                                className="text-nowrap text-start"
                                style={{ width: 100 }}
                              >
                                <span
                                  className={
                                    item?.orderStatus === "PRINTED"
                                      ? "text-success"
                                      : item?.orderStatus === "CANCELED"
                                      ? "badges bg-lightred"
                                      : "text-info"
                                  }
                                >
                                  {item?.orderStatus === "PRINTED"
                                    ? "ພິມບິນແລ້ວ"
                                    : item?.orderStatus === "CANCELED"
                                    ? "ຍົກເລີກ"
                                    : "ລໍຖ້າພິມບິນ ..."}

                                  {item?.createdBy?.fullName &&
                                  item?.orderStatus === "PRINTED" ? (
                                    <>
                                      {" "}
                                      <hr className="my-1" /> ພິມໂດຍ:{" "}
                                      {item?.updatedBy?.fullName}
                                    </>
                                  ) : null}
                                </span>
                              </td>
                              <td
                                className="text-center text-nowrap"
                                style={{ width: 120 }}
                              >
                                {formatDateTime(item?.createdAt) ?? "- -"}
                              </td>
                              <td className="text-nowrap">
                                ຊື່: {item?.createdBy?.fullName ?? "- -"}
                                <br />
                                ເບີໂທ: {item?.createdBy?.phoneNumber ?? "- -"}
                              </td>
                              <td className="text-nowrap">
                                {item?.trackingId ?? "- -"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="container mt-3">
                    {(() => {
                      return (
                        <ResponsivePagination
                          total={totalPage}
                          current={currentPage || 1}
                          onPageChange={(page: number) => {
                            params.set("page", page.toString());
                            setParams(params);
                          }}
                        />
                      );
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
