import { gql } from "@apollo/client";

export default class Schema {
  static createData = gql`
    mutation CreateGenerate($data: GenerateCodeInput!) {
      createGenerate(data: $data) {
        status
      }
    }
  `;
  static queryBarcode = gql`
    query GenerateCodes($skip: Int, $limit: Int, $where: GenerateWhereInput) {
      generateCodes(skip: $skip, limit: $limit, where: $where) {
        total
        data {
          _id
          code
          createdAt
          description
          createdBy {
            fullName
          }
          title
          price
          shop {
            title
          }
        }
      }
    }
  `;
  static updateGenCode = gql`
    mutation UpdateGenerate($data: GenerateCodeInput!, $where: WhereById!) {
      updateGenerate(data: $data, where: $where) {
        status
      }
    }
  `;
   //shop
   static queryShop = gql`
   query Shops($where: ShopWhereInput) {
     shops(where: $where) {
       total
     }
   }
 `;
}
