import { ApolloError } from "@apollo/client";
import moment from "moment";
import Notiflix, { Confirm, Loading, Notify } from "notiflix";
import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import loadingImage from "../assets/images/loading.gif";
import _placeholderImage from "../img/placeholder.png";
export const RootVariable = {
  limit: 50,
};

export const onLoading = loadingImage;
export const replaceImage = _placeholderImage;
export const placeholderImage = (width: number, height: number) => {
  return `https://via.placeholder.com/${width}x${height}`;
};
export const loadingScreen: any = () => {
  return Loading.hourglass({
    backgroundColor: "#ff530357",
    svgColor: "#fc4e0f",
  });
};
export const closeLoadingScreen: any = () => {
  return Notiflix.Loading.remove();
};
// Local Storage Name
export enum LocalStorageName {
  token = "APP-TOKEN",
  localProvinceId = "LOCAL_PROVINCE_ID",
  localBranchId = "LOCAL_BRANCH_ID",
}

// Clear localStorage
export const clearLocalStorage = () => {
  localStorage.removeItem(LocalStorageName.token);
  localStorage.removeItem(LocalStorageName.localProvinceId);
  localStorage.removeItem(LocalStorageName.localBranchId);
};

// Notiflix
Notify.init({
  warning: { background: "var(--color-warning)" },
  success: { background: "var(--color-success)" },
  failure: { background: "var(--color-danger)" },
  info: { background: "var(--color-info)" },
  fontSize: "0.95rem",
});

export const notifySuccess = (msg: string) => {
  Notify.success(msg);
};

export const notifyError = (msg: string) => {
  Notify.failure(msg);
};

export const notifyWarning = (msg: string) => {
  Notify.warning(msg);
};

export const notifyInfo = (msg: string) => {
  Notify.info(msg);
};

export const messageSuccess = (e: string) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.success(e);
};

export const messageError = (e: string) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.failure(e);
};
export const messageWarning = (e: string) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.warning(e);
};

export const confirmShow = (title: string, action: () => void) => {
  Confirm.show(
    "ເເຈ້ງເຕືອນ",
    title,
    "ຕົກລົງ",
    "ຍົກເລີກ",
    async () => {
      action();
    },
    () => {
      return false;
    }
  );
};

//NO ITEM
export const NO = (index: number, page: number, limit: number) => {
  const no = limit * page - limit;
  if (limit > 0) {
    return currency(no + index + 1);
  } else {
    return currency(index + 1);
  }
};

// Format DateTime
export const toDayDate = (format?: string) => {
  return moment().format(format ? format : "DD-MM-YYYY");
};

export const formatDate = (date: Date, format?: string) => {
  return moment(date ? date : new Date()).format(
    format ? format : "DD-MM-YYYY"
  );
};

export const formatDateYearStart = (date: Date, format?: string) => {
  return moment(date ? date : new Date()).format(
    format ? format : "YYYY-MM-DD"
  );
};

export const formatDateTime = (date: Date | string, format?: string) => {
  return moment(date ? date : new Date()).format(
    format ? format : "DD-MM-YYYY HH:mm:ss"
  );
};

// ກຳນົດ ວັນທີ ແລະ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const toDay = () => {
  moment.locale("lo");
  var today = new Date();
  var todays = moment(today).format("YYYY/MM/DD, HH:mm");
  return todays;
};
// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const toDayDash = () => {
  var today = new Date();
  var todays = moment(today).format("YYYY-MM-DD");
  return todays;
};

// ວັນທີເດືອນປີເລີ່ມວັນທີ ເລີ່ມເດືອນ ເລີ່ມປີ
export const startOfMonth = () => {
  return moment().clone().startOf("month").format("YYYY-MM-DD");
};

// ວັນທີເດືອນປີ ທ້າຍວັນທີ ທ້າວເດືອນ ທ້າຍປີ
export const endOfMonth = () => {
  return moment().clone().endOf("month").format("YYYY-MM-DD");
};

export const startOfMonthThree = () => {
  return moment().subtract(3, "months").startOf("month").format("YYYY-MM-DD");
};
export const startEndMonthThree = () => {
  return moment().subtract(3, "months").endOf("month").format("YYYY-MM-DD");
};
// ຍອ້ອນຫລັງ 2  ເດືອ
export const startOfMonthTwo = () => {
  return moment().subtract(2, "months").startOf("month").format("YYYY-MM-DD");
};
export const startEndMonthTwo = () => {
  return moment().subtract(2, "months").endOf("month").format("YYYY-MM-DD");
};
// ຍອ້ອນຫລັງ 1  ເດືອ
export const startOfMonthOne = () => {
  return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
};
export const startEndMonthOne = () => {
  return moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
};

// Invalid Text
export const InvalidText = () => {
  return <span style={{ color: "var(--color-danger)" }}>*</span>;
};

// Format Currency
export const currency = (number: number = 0) => {
  if (!number) return "0";
  const currency = new Intl.NumberFormat("en-CA").format(number);
  return currency;
};

// Message Errors
export enum MessageErrors {
  process = "ການດຳເນິນງານບໍ່ສຳເລັດ ກະລຸນາລອງອີກຄັ້ງ",
  invalidPassword = "ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ ກະລຸນາລອງອີກຄັ້ງ",
  insert = "ບໍ່ສາມາດເພີ່ມຂໍ້ມູນໄດ້ ກະລຸນາລອງອີກຄັ້ງ",
  save = "ບໍ່ສາມາດບັນທຶກຂໍ້ມູນໄດ້ ກະລຸນາລອງອີກຄັ້ງ",
  edit = "ບໍ່ສາມາດແກ້ໄຂຂໍ້ມູນໄດ້ ກະລຸນາລອງອີກຄັ້ງ",
  update = "ບໍ່ສາມາດອັບເດດຂໍ້ມູນໄດ້ ກະລຸນາລອງອີກຄັ້ງ",
  branchNotFound = "ບັນຊີຂອງທ່ານບໍ່ມີຂໍ້ມູນສາຂາ ກະລຸນາກວດສອບຄືນ",
  delete = "ບໍ່ສາມາດລຶບຂໍ້ມູນໄດ້ ກະລຸນາລອງອີກຄັ້ງ",
  cancel = "ບໍ່ສາມາດຍົກເລີກໄດ້ ກະລຸນາລອງອີກຄັ້ງ",
  error = "ມີຂໍ້ຜິດພາດເກີດຂື້ນ ກະລຸນາລອງໃໝ່ອີກຄັ້ງ",
}

// Message Success
export enum MessageSuccess {
  process = "ການດຳເນິນງານສຳເລັດແລ້ວ",
  insert = "ເພີ່ມຂໍ້ມູນໃໝ່ສຳເລັດແລ້ວ",
  save = "ບັນທຶກຂໍ້ມູນສຳເລັດແລ້ວ",
  edit = "ແກ້ໄຂຂໍ້ມູນສຳເລັດແລ້ວ",
  update = "ອັບເດດຂໍ້ມູນສຳເລັດແລ້ວ",
  delete = "ລຶບຂໍ້ມູນສຳເລັດແລ້ວ",
  cancel = "ຍົກເລີກສຳເລັດແລ້ວ",
}

export enum MessageConfirms {
  addVillage = "ທ່ານຕ້ອງການເພີ່ມບ້ານນີ້ ແທ້ ຫຼື ບໍ່?",
  delete = "ທ່ານຕ້ອງການລຶບຂໍ້ມູນນີ້ ແທ້ ຫຼື ບໍ່?",
  cancelTransferItem = "ທ່ານຕ້ອງການຍົກເລີກເຄື່ອນຍ້າຍພັດສະດຸ ແທ້ ຫຼື ບໍ່?",
  destConfirmReceiveItem = "ທ່ານຕ້ອງການຢືນຢັນຮັບພັດສະດຸເຂົ້າສາງ ແທ້ ຫຼື ບໍ່?",
  setItemRoute = "ທ່ານຕ້ອງການເລີ່ມຈັດສາຍລາຍການພັດສະດຸທັງໝົດ ແທ້ ຫຼື ບໍ່?",
  setItemRouteOnlySelect = "ທ່ານຕ້ອງການເລີ່ມຈັດສາຍລາຍການທີ່ເລືອກໄວ້ ແທ້ ຫຼື ບໍ່?",
  lockedItem = "ທ່ານຕ້ອງການລ໋ອກຈັດສາຍ ແທ້ ຫຼື ບໍ່?",
  cancelLockedItem = "ທ່ານຕ້ອງການຍົກເລີກລ໋ອກຈັດສາຍ ແທ້ ຫຼື ບໍ່?",
  waitingToBackShop = "ທ່ານຕ້ອງການຍ້າຍພັດສະດຸໄປຍັງພັດສະດຸກຽມສົ່ງຄືນແມ່ຄ້າ ແທ້ ຫຼື ບໍ່?",
  confirmToBackShop = "ທ່ານຕ້ອງການຢືນຢັນສົ່ງພັດສະດຸຄືນແມ່ຄ້າ ແທ້ ຫຼື ບໍ່",
  cancelSent = "ທ່ານຕ້ອງການຍົກເລີກຈັດສົ່ງພັດສະດຸ ແທ້ ຫຼື ບໍ່?",
  cancelShipperToPickup = "ທ່ານຕ້ອງການຍົກເລີກການສັ່ງພະນັກງານໄປຮັບເຄື່ອງ ແທ້ ຫຼື ບໍ່?",
  cancelWaitingAssignShipper = "ທ່ານຕ້ອງການຍົກເລີກລໍຖ້າຈັດຄົນເຂົ້າສາຍ ແທ້ ຫຼື ບໍ່?",
  confirmedCodDeposit = "ທ່ານຕ້ອງການຢືນຢັນຄວາມຖືກຕ້ອງຂອງຍອດເງິນ COD ແທ້ ຫຼື ບໍ່?",
  cancelAssignedShipper = "ທ່ານຕ້ອງການຍົກເລີກຈັດຄົນເຂົ້າສາຍແທ້ ຫຼື ບໍ່?",
  cancelPostpone = "ທ່ານຕ້ອງການຍົກເລີກການເລື່ອນນັດ ແທ້ ຫຼື ບໍ່?",
  confirmSentCODFromShipper = "ທ່ານຕ້ອງການຢືນຢັນຮັບເງິນປາຍທາງທັງໝົດ ແທ້ ຫຼື ບໍ່?",
}

export enum MessageWarnings {
  dataNotFound = "ບໍ່ມີຂໍ້ມູນໃນລະບົບ ກະລຸນາລອງໃໝ່ອີກຄັ້ງ",
  duplicate = "ມີຂໍ້ມູນນີ້ໃນລະບົບແລ້ວ ກະລຸນາກວດສອບ",
}

// Item Select
export class ItemSelect {
  static all(e: React.ChangeEvent<HTMLInputElement>) {
    const elms = document.querySelectorAll<HTMLInputElement>(".item-checkbox");
    const arrays: number[] = [];
    if (elms?.length) {
      if (e.target.checked) {
        elms.forEach((elm) => {
          elm.checked = true;
          arrays.push(parseInt(elm.value));
        });
        return arrays;
      } else {
        elms.forEach((elm) => {
          elm.checked = false;
        });
        return arrays;
      }
    }
    return arrays;
  }

  static one() {
    const elms = document.querySelectorAll<HTMLInputElement>(".item-checkbox");
    let arrays: number[] = [];

    if (elms?.length) {
      elms.forEach((elm) => {
        const value = parseInt(elm.value);
        if (elm.checked) {
          arrays.push(value);
        } else {
          arrays = arrays?.filter((id) => id !== value);
        }
      });
    }
    return arrays;
  }

  static unselect() {
    const checkAll = document.getElementById("select-all") as HTMLInputElement;
    const elms = document.querySelectorAll<HTMLInputElement>(".item-checkbox");
    let arrays: number[] = [];
    if (checkAll) {
      checkAll.checked = false;
    }
    if (elms?.length) {
      elms.forEach((elm) => {
        elm.checked = false;
      });
    }
    return arrays;
  }
}

// get parse JWT
export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  const object = JSON.parse(jsonPayload);
  return object;
};

export type UserRole =
  | "SUPER_ADMIN"
  | "ADMIN"
  | "SUPER_USERS"
  | "OWNER"
  | undefined;

// type UserType = {
//   _id: number;
//   userProfile: string;
//   fullName: string;
//   phoneNumber: number;
//   role: UserRole;
//   shop: {
//     _id: number;
//     title: string;
//     address: string;
//     profileImage: string;
//     inviteCode: String;
//   };
// };

type UserType = {
  _id: number;
  userProfile: string;
  fullName: string;
  role: UserRole;
  phoneNumber: number;
  providerCode: String;
  shop: {
    _id: number;
    title: string;
    address: string;
    profileImage: string;
    providerCode: String;
  };
};

type UserRoles = {
  role: UserRole;
  title: string;
};

export const userRoles: UserRoles[] = [
  { role: "SUPER_ADMIN", title: "ບໍລິຫານສູງສຸດ" },
  { role: "ADMIN", title: "ແອັດມິນ" },
  { role: "OWNER", title: "ເຈົ້າຂອງຮ້ານ" },
];
export const userRole = (title: any) => {
  if (title === "SUPER_ADMIN") {
    return "ບໍລິຫານສູງສຸດ";
  } else if (title === "ADMIN") {
    return "ແອັດມິນ";
  } else if (title === "OWNER") {
    return "ເຈົ້າຂອງຮ້ານ";
  } else {
    return "-";
  }
};

export const getStaffLogin = () => {
  const token = localStorage.getItem(LocalStorageName.token);
  if (token) {
    const data = parseJwt(token);
    const user: UserType = {
      _id: data?._id,
      userProfile: data?.userProfile,
      fullName: data?.fullName,
      phoneNumber: data?.phoneNumber,
      providerCode:data?.providerCode,
      role: data?.role,
      shop: {
        _id: data.shop?._id,
        title: data?.shop?.title,
        profileImage: data?.shop?.profileImage,
        address: data?.shop?.address,
        providerCode: data?.providerCode,
      },
    };
    return user;
  }
  return null;
};

//get local shop
export const getLocalShop = () => {
  let local = localStorage.getItem("LOCAL_BRANCH_ID");
  if (local) {
    local = JSON.parse(local);
    return local;
  } else {
    let userLogin = getStaffLogin();
    return userLogin?.shop;
  }
};
//get local shop id
export const getLocalShopID = () => {
  let local = localStorage.getItem("LOCAL_BRANCH_ID");
  if (local) {
    local = JSON.parse(local);
    return local;
  } else {
    let userLogin = getStaffLogin();
    return userLogin?.shop?._id;
  }
};

export const getUserRole = () => {
  const role = getStaffLogin()?.role;
  return role;
};

export const convertRole = (role: string) => {
  const result = userRoles?.filter((item) => item?.role === role);
  if (result?.length) {
    return result[0]?.title;
  } else {
    return "";
  }
};

export const checkUserRole = (roles: UserRole[] | undefined) => {
  if (roles?.includes(getUserRole())) {
    return true;
  } else {
    return false;
  }
};

export const graphQLErrors = (e: any) => {
  const error = e as ApolloError;
  if (error?.graphQLErrors?.length) {
    return error?.graphQLErrors[0].toString().replace("Error: ", "");
  }
  return "";
};

// Toggle animation height
export const ToggleAnimationHeight = ({
  button,
  children,
}: {
  button: (onClick: () => void) => JSX.Element;
  children: JSX.Element | JSX.Element[];
}) => {
  const [show, setShow] = useState(false);
  const onClick = () => setShow(!show);
  return (
    <>
      <AnimateHeight height={show ? "auto" : 0}>
        <>{children}</>
      </AnimateHeight>
      {button?.(onClick)}
    </>
  );
};

// year
var start_year = new Date().getFullYear();
export const getYear = () => {
  let getYearCustom = [];
  for (let date = start_year; date > 2021; date--) {
    getYearCustom.push(date);
  }
  return getYearCustom;
};

export const _month = [
  {
    id: "",
    month: "ທັງໝົດ",
  },
  {
    id: "1",
    month: "1 (ມັງກອນ)",
  },
  {
    id: "2",
    month: "2 (ກຸມພາ)",
  },
  {
    id: "3",
    month: "3 (ມີນາ)",
  },
  {
    id: "4",
    month: "4 (ເມສາ)",
  },
  {
    id: "5",
    month: "5 (ພຶດສະພາ)",
  },
  {
    id: "6",
    month: "6 (ມີຖຸນາ)",
  },
  {
    id: "7",
    month: "7 (ກໍລະກົດ)",
  },
  {
    id: "8",
    month: "8 (ສິງຫາ)",
  },
  {
    id: "9",
    month: "9 (ກັນຍາ)",
  },
  {
    id: "10",
    month: "10 (ຕຸລາ)",
  },
  {
    id: "11",
    month: "11 (ພະຈິກ)",
  },
  {
    id: "12",
    month: "12 (ທັນວາ)",
  },
];
