import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Paths } from "../../routes/Path";
import ChangePassword from "./component/ChangePassword";
import BlockUsers from "./component/BlockUser";
import BlockShop from "./component/BlockShop";
import { getUserRole } from "../../utils";
import { BsCalendar2CheckFill, BsFillClipboardCheckFill } from "react-icons/bs";
import { FaBoxOpen, FaThList, FaUserCog } from "react-icons/fa";
import Notiflix from "notiflix";
import { IoMdLogOut } from "react-icons/io";
import { MdDateRange, MdHelp } from "react-icons/md";
import { IoTrashSharp } from "react-icons/io5";
export default function SettingsScreen() {
  const navigate = useNavigate();

  const _onLogout = async () => {
    Notiflix.Confirm.show(
      "ຢຶນຢັນ",
      "ທ່ານຕ້ອງການອອກຈາກລະບົບແທ້ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async function () {
        localStorage.clear();
        sessionStorage.clear();
        Notiflix.Loading.standard("ກຳລັງດຳເນີນການ...", { svgColor: "#ff5303" });
        setTimeout(() => {
          Notiflix.Loading.remove();
          window.location.replace("/login");
        }, 1500);
      },
      function () {}
    );
  };

  return (
    <div className="page-wrapper">
      <div className="content ms-2">
        <nav aria-label="breadcrumb" className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={() => navigate(Paths.home)}>
                ໜ້າຫຼັກ
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              ການຕັ້ງຄ່າ
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12 col-lg-6">
            <ul className="list-group">
              <li className="list-item">
                <a
                  href="javaScript:void(0);"
                  onClick={() => navigate(Paths.customer_add_order)}
                >
                  <h4>
                    <FaBoxOpen size={18} className="me-1" />
                    ອໍເດີ່ລູກຄ້າລູກຄ້າສັ່ງຊື້
                  </h4>
                </a>
              </li>
              <li className="list-item">
                <ChangePassword />
              </li>
              <li className="list-item">
                <BlockUsers />
              </li>
              {getUserRole() === "SUPER_ADMIN" || getUserRole() === "OWNER" ? (
                <li className="list-item">
                  <BlockShop />
                </li>
              ) : null}
              <div className="customOfWeb">
                <li className="list-item">
                  <a
                    href="javaScript:void(0)"
                    onClick={() => navigate(Paths.list_order)}
                  >
                    <h4>
                      <MdDateRange className="fs-5 me-1" />
                      <span>ຍອດອໍເດີ່ລາຍເດືອນ</span>
                    </h4>
                  </a>
                </li>
                <li className="list-item">
                  <a
                    href="javaScript:void(0)"
                    onClick={() => navigate(Paths.list_order_day)}
                  >
                    <h4>
                      <FaThList className="fs-6 me-2" />
                      <span>ຍອດອໍເດີ່ລາຍວັນ</span>
                    </h4>
                  </a>
                </li>
              </div>

              {getUserRole() === "SUPER_ADMIN" || getUserRole() === "OWNER" ? (
                <>
                  <div className="customOfWeb">
                    <li className="list-item">
                      <a
                        href="javaScript:void(0)"
                        onClick={() => navigate(Paths.shop)}
                      >
                        <h4>
                          <BsFillClipboardCheckFill className="fs-6" />
                          <span className="ms-2">ຈັດການຮ້ານຄ້າ</span>
                        </h4>
                      </a>
                    </li>
                    <li className="list-item">
                      <a
                        href="javaScript:void(0)"
                        onClick={() => navigate(Paths.member)}
                      >
                        <h4>
                          <FaUserCog className="fs-6" />
                          <span className="ms-2">
                            ຈັດການສະມາຊິກ <i className="fa-solid fa-user"></i>
                          </span>
                        </h4>
                      </a>
                    </li>
                    <li className="list-item">
                      <a
                        href="javaScript:void(0)"
                        onClick={() => navigate(Paths.invoices)}
                      >
                        <h4>
                          <BsCalendar2CheckFill className="fs-6" />
                          <span className="ms-2">ຊຳລະຄ່າບໍລິການ</span>
                        </h4>
                      </a>
                    </li>
                    <li className="list-item" style={{ marginLeft: -5 }}>
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate(Paths.order_deleted)}
                      >
                        <IoTrashSharp
                          style={{ fontSize: "25px" }}
                          className="me-1"
                        />
                        <span className="fs-6">ຖັງຂີ້ເຫຍຶອ</span>
                      </a>
                    </li>
                    <li className="list-item" style={{ marginLeft: -5 }}>
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate(Paths.support_team)}
                      >
                        <MdHelp style={{ fontSize: "23px" }} className="me-1" />
                        <span>ຊ່ວຍເຫຼືອ</span>
                      </a>
                    </li>
                  </div>
                </>
              ) : null}
              <li className="list-item">
                <a href="javaScript:void(0)" onClick={() => _onLogout()}>
                  <h4>
                    <IoMdLogOut className="fs-5 text-danger " />
                    <span className="ms-2 text-danger">ອອກຈາກລະບົບ</span>
                  </h4>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
