import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "./Schema";
import { useEffect, useState } from "react";

export default function CountOrderByUser({
  startDate,
  endDate,
  newRefresh,
  userID,
}: any) {
  const [totalOrder, setTotalOrder]: any = useState(null);

  const [queryOrder, { loading: loadingData }] = useLazyQuery(
    Schema.summaryTotal,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setTotalOrder(data?.summaryTotal);
      },
    }
  );

  useEffect(() => {
    if (!startDate || !endDate) return;
    queryOrder({
      variables: {
        where: {
          createdAtBetween: [startDate, endDate],
          createdBy: userID,
          isDeleted: 0,
        },
      },
    });
  }, [queryOrder, startDate, endDate, userID, newRefresh]);
  return (
    <>
      <span style={{fontSize:12}}>{totalOrder?.totalPrint ? totalOrder?.totalPrint : 0}</span>
    </>
  );
}
