import Compressor from "compressorjs";
// Compress image
export default async function compressImage(file: any) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.9,
      maxWidth: 1920,
      maxHeight: 1920,
      success: (result: any) => {
        const _file = new File([result], result?.name, { type: result?.type });
        resolve(_file);
      },
      error: (error:any) => {
        reject(error);
      },
    });
  });
}
