import { gql } from "@apollo/client";

export default class Schema {
  static queryOrderSummary = gql`
    query GetSummaryDay($where: whereOfDay, $limit: Int, $skip: Int) {
      summaryTotalOFDays(where: $where, limit: $limit, skip: $skip) {
        getSummaryDay {
          getDay
          isDeleted
          shopId
          totalOrder
        }
      }
    }
  `;
  static queryOrder = gql`
    query Orders($where: OrderWhereInput, $limit: Int, $skip: Int) {
      orders(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          orderID
          receiveName
          receivePhone
          isPrinted
          isDeleted
          description
          orderStatus
          createdAt
          trackingId
          createdBy {
            _id
            fullName
            phoneNumber
          }
          shop {
            _id
            title
            shopID
            address
            description
            phoneNumber
            profileImage
          }
        }
      }
    }
  `;
}
