import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { url_path_IBank, url_path_IBank_Order } from "../../utils/url";
import { FaMoneyBillTransfer } from "react-icons/fa6";

export default function FileBuy({ sentPathImage, width, height }: any) {
  const [show, setShow] = useState<any>(false);

  return (
    <React.Fragment>
      <button className="btn btn-outline-primary btn-sm" onClick={() => setShow(true)}>
      <FaMoneyBillTransfer className="me-1" />
        ເບີ່ງຫຼັກຖານໂອນ
      </button>

      <Modal size="xl" show={show} animation={false}>
        <Modal.Header>
          <h3 className="text-center">ຫຼັກຖານການຊຳລະ</h3>
          <a
            href="javascript:void(0)"
            className="float-end"
            onClick={() => {
              setShow(false);
            }}
          >
            <i
              className="icon icon-x text-white"
              style={{ fontSize: "55px" }}
            />
          </a>
        </Modal.Header>
        <Modal.Body className="">
          <img
            src={sentPathImage ? url_path_IBank_Order + sentPathImage : ""}
            style={{width: "100%", height: "100%"}}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
