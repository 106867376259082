import "./Style.scss";
import { BsArrowRight } from "react-icons/bs";
import { FaAngleLeft, FaUserPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  InvalidText,
  LocalStorageName,
  getUserRole,
  messageError,
  messageSuccess,
  onLoading,
  placeholderImage,
} from "../../utils";
import { url_path_images, url_upload_images } from "../../utils/url";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import axios from "axios";
import compressImage from "../../utils/UploadFile";
import { useState } from "react";
import Schema from "../shop/Schema";
export default function RegisterScreen() {
  const navigator: any = useNavigate();
  const [image, setImage] = useState("");

  const [createShop, { loading: createTing }]: any = useMutation(
    Schema.createShop
  );
  const handleChangeImageUpload = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setImage(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res: any = await axios.post(url_upload_images, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setImage(filename);
        } else {
          setImage("");
          messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  const { handleChange, errors, values, handleSubmit, resetForm } = useFormik({
    initialValues: {
      profileImage: "",
      title: "",
      phoneNumber: "",
      pageName: "",
      address: "",
    },
    enableReinitialize: false,
    validate: (values) => {
      const errors: any = {};
      if (!values.title) {
        errors.title = "ກະລຸນາປ້ອນຊື່ຮ້ານ";
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = "ກະລຸນາປ້ອນຂໍ້ມູນຕິດຕໍ່";
      }
      if (!values.pageName) {
        errors.pageName = "ກະລຸນາປ້ອນເພຈ";
      }
      if (!values.address) {
        errors.address = "ກະລຸນາປ້ອນທີ່ຢູ່";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const { data: created } = await createShop({
          variables: {
            data: {
              profileImage: String(image),
              title: values?.title,
              phoneNumber: values?.phoneNumber,
              pageName: values?.pageName,
              address: values?.address,
            },
          },
        });
        if (created?.createShop?.status) {
          messageSuccess("ດຳເນີນງານສຳເລັດແລ້ວ");
          resetForm();
          localStorage.setItem(
            "SHOP",
            JSON.stringify(created?.createShop?.data)
          );
          setTimeout(() => {
            navigator("/register/user");
          }, 300);
        } else {
          messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
        }
      } catch (error) {
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
      }
    },
  });
  return (
    <div className="app-landing">
      <div className="header-container row">
        <div className="col-12 col-lg-4 text-center">
          <h2 className="text-success fw-bolder">
            <button
              className="btn text-white"
              onClick={() => {
                navigator("/home");
              }}
            >
              <FaAngleLeft className="text-secondary fs-3 text-start" />
            </button>{" "}
            ລະບົບຈັດການອໍເດີ່{" "}
            <strong className="text-primary">Happy Shop</strong>
          </h2>
        </div>
      </div>
      <div className="row container">
        <div className="col-12">
          <div className="card">
            <div className="card-header border-bottom py-2 bg-primary text-white">
              <h5>
                <FaUserPlus /> ລົງທະບຽນຮ້ານຄ້າ
              </h5>
            </div>
            <div className="card-body">
              <form>
                <div className="col-12 col-lg-4 col-md-12 col-sm-12">
                  <div className="form-group">
                    <div style={{ textAlign: "center" }}>
                      <input
                        type="file"
                        id="file-upload"
                        onChange={handleChangeImageUpload}
                        name="file"
                        accept="image/png, image/gif, image/jpeg"
                        hidden
                      />
                      <label htmlFor="file-upload">
                        <div
                          style={{
                            height: 200,
                            width: 200,
                            cursor: "pointer",
                            display: "flex",
                            backgroundImage: `url(${onLoading})`,
                            justifyContent: "center",
                            backgroundPosition: "center",
                            backgroundSize: "100%",
                            textAlign: "center",
                            borderRadius: 7,
                          }}
                        >
                          {image ? (
                            <img
                              src={
                                image
                                  ? url_path_images + image
                                  : placeholderImage(200, 200)
                              }
                              alt={image}
                              style={{
                                height: 200,
                                width: 200,
                                borderRadius: 7,
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                backgroundImage: `url(${placeholderImage(
                                  200,
                                  200
                                )})`,
                                display: "flex",
                                height: 200,
                                width: 200,
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 7,
                                backgroundSize: "100%",
                              }}
                            ></div>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label>
                    <i className="fas fa-layer-group me-1" />
                    ຊື່ຮ້ານ {InvalidText()}
                  </label>
                  <input
                    type="text"
                    autoFocus
                    className={
                      errors.title
                        ? "form-control form-control-lg is-invalid"
                        : "form-control form-control-lg  invalid"
                    }
                    placeholder="ປ້ອນຊື່ຮ້ານ"
                    name="title"
                    value={String(values.title)}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label>
                    <i className="fas fa-layer-group me-1" />
                    ຊື່ເພຈ໌ {InvalidText()}
                  </label>
                  <input
                    type="text"
                    className={
                      errors.pageName
                        ? "form-control form-control-lg is-invalid"
                        : "form-control form-control-lg  invalid"
                    }
                    placeholder="ຊື່ເພຈ໌"
                    name="pageName"
                    value={String(values.pageName)}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label>
                    <i className="fas fa-layer-group me-1" />
                    ເບີຕິດຕໍ່ {InvalidText()}
                  </label>
                  <input
                    type="number"
                    className={
                      errors.phoneNumber
                        ? "form-control form-control-lg is-invalid"
                        : "form-control form-control-lg  invalid"
                    }
                    onWheel={(e: any) => e.target.blur()}
                    placeholder="ປ້ອນເບີຕິດຕໍ່"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
                {getUserRole() === "SUPER_ADMIN" && (
                  <>
                    <div className="mb-3">
                      <label>
                        <i className="fas fa-layer-group me-1" />
                        ຊື່ເພຈ໌ {InvalidText()}
                      </label>
                      <input
                        type="text"
                        className={
                          errors.pageName
                            ? "form-control form-control-lg is-invalid"
                            : "form-control form-control-lg  invalid"
                        }
                        placeholder="ຊື່ເພຈ໌"
                        name="pageName"
                        value={String(values.pageName)}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}
                <div className="mb-3">
                  <label>
                    <i className="fas fa-layer-group me-1" />
                    ທີ່ຢູ່ {InvalidText()}
                  </label>
                  <textarea
                    name="address"
                    className={
                      errors.address
                        ? "form-control form-control-lg is-invalid"
                        : "form-control form-control-lg  invalid"
                    }
                    placeholder="ປ້ອນທີ່ຢູ່"
                    rows={4}
                    value={String(values.address)}
                    onChange={handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="card-footer p-2">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={() => handleSubmit()}
                disabled={createTing}
              >
                <BsArrowRight /> ຖັດໄປ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
