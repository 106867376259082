import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { Paths } from "./Path";
import NavBar from "../layouts/Navbar";
import Sidebar from "../layouts/Sidebar";
import HomeScreen from "../screens/home";
import SettingsScreen from "../screens/settings";
import InvoiceScreen from "../screens/invioces";
import ShopScreen from "../screens/shop";
import MemberScreen from "../screens/members";
import { LocalStorageName } from "../utils";
import React from "react";
import Login from "../screens/login";
import PrintComponents from "../screens/printbill";
import ListOrder from "../screens/listorderOfMoth";
import DetailItemOfMoth from "../screens/listorderOfMoth/DetailItem";
import ListOrderDay from "../screens/listorderOfDay";
import OrderDeleted from "../screens/orderDeleted";
import LandingScreen from "../screens/login/landing";
import GenerateCode from "../screens/generateBarcode";
import PrintBranCode from "../screens/generateBarcode/PrintBarcode";
import OrderDetailForDay from "../screens/listorderOfDay/DetailItem";
import RegisterScreen from "../screens/login/register";
import RegisterUserScreen from "../screens/login/registerUser";
import CustomerAdd from "../screens/ordercustomer";
import AddOrderOfCustomer from "../screens/ordercustomer/AddOrder";
import SupportTeam from "../screens/support";
import MemberGroup from "../screens/memberGroup";
import AddMemberGroup from "../screens/memberGroup/AddMemberGroup";
import CommissionScreen from "../screens/commission";
import CategoryScreen from "../screens/categorys";
import ItemsScreen from "../screens/items";
import ImportItemsScreen from "../screens/importItems";
import ExportItemsScreen from "../screens/exportItems";
import StockItemsScreen from "../screens/stockItems";
// import PrintComponents from "../screens/printbill";
// Public Route
const publicRoute = createBrowserRouter([
  // { path: "/", element: <Navigate to={Paths.landing} /> },
  { path: "/", element: <Navigate to={Paths.login} /> },
  { path: Paths.landing, element: <LandingScreen /> },
  { path: Paths.login, element: <Login /> },
  { path: Paths.register_shop, element: <RegisterScreen /> },
  { path: Paths.register_user, element: <RegisterUserScreen /> },
  { path: Paths.customer_create, element: <AddOrderOfCustomer /> },
  // Not Found
  { path: "*", element: <Navigate to={Paths.login} /> },
  // { path: "*", element: <Navigate to={Paths.landing} /> },
]);

const privateRoute = createBrowserRouter([
  { path: "/", element: <Navigate to={Paths.home} /> },
  { path: Paths.home, element: <Layout element={<HomeScreen />} /> },
  { path: Paths.invoices, element: <Layout element={<InvoiceScreen />} /> },
  { path: Paths.list_order, element: <Layout element={<ListOrder />} /> },
  {
    path: Paths.list_order_day,
    element: <Layout element={<ListOrderDay />} />,
  },
  { path: Paths.shop, element: <Layout element={<ShopScreen />} /> },
  { path: Paths.member, element: <Layout element={<MemberScreen />} /> },
  { path: Paths.member_group, element: <Layout element={<MemberGroup />} /> },
  { path: Paths.settings, element: <Layout element={<SettingsScreen />} /> },
  { path: Paths.order_deleted, element: <Layout element={<OrderDeleted />} /> },
  { path: Paths.generate_code, element: <Layout element={<GenerateCode />} /> },
  {
    path: Paths.detail_item,
    element: <Layout element={<DetailItemOfMoth />} />,
  },
  {
    path: Paths.detail_item_day,
    element: <Layout element={<OrderDetailForDay />} />,
  },
  {
    path: Paths.print_all_bill,
    element: <LayoutPrint element={<PrintComponents />} />,
  },
  {
    path: Paths.print_generate_code,
    element: <LayoutPrint element={<PrintBranCode />} />,
  },
  {
    path: Paths.customer_add_order,
    element: <LayoutPrint element={<CustomerAdd />} />,
  },
  {
    path: Paths.member_add_shop,
    element: <LayoutPrint element={<AddMemberGroup />} />,
  },
  {
    path: Paths.customer_create,
    element: <LayoutPrint element={<AddOrderOfCustomer />} />,
  },
  {
    path: Paths.support_team,
    element: <Layout element={<SupportTeam />} />,
  },
  {
    path: Paths.commission,
    element: <Layout element={<CommissionScreen />} />,
  },
  //Stocks
  {
    path: Paths.category,
    element: <Layout element={<CategoryScreen />} />,
  },
  {
    path: Paths.items,
    element: <Layout element={<ItemsScreen />} />,
  },
  {
    path: Paths.importItems,
    element: <Layout element={<ImportItemsScreen />} />,
  },
  {
    path: Paths.exportItems,
    element: <Layout element={<ExportItemsScreen />} />,
  },
  {
    path: Paths.stockItems,
    element: <Layout element={<StockItemsScreen />} />,
  },
]);
function Layout({ element }: any) {
  return (
    <div>
      <NavBar />
      <Sidebar />
      <div className="main-wrapper">{element}</div>
    </div>
  );
}

function LayoutPrint({ element }: any) {
  return <>{element}</>;
}

export default function RoutesApp() {
  const token = localStorage.getItem(LocalStorageName.token);
  return (
    <React.Fragment>
      {token ? (
        <RouterProvider router={privateRoute} />
      ) : (
        <RouterProvider router={publicRoute} />
      )}
    </React.Fragment>
  );
}
