import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Paths } from "../../routes/Path";
import { useLazyQuery, useMutation } from "@apollo/client";
import Pagination from "react-responsive-pagination";
import Schema from "./Schema";
import {
  MessageErrors,
  NO,
  RootVariable,
  closeLoadingScreen,
  currency,
  formatDateTime,
  getLocalShopID,
  getUserRole,
  loadingScreen,
  messageError,
  messageSuccess,
} from "../../utils";
import LimitData from "../../utils/components/LimitData";
import EditCategory from "./EditCategory";
import Notiflix from "notiflix";
import AddCategory from "./AddCategory";
export default function CategoryScreen() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(RootVariable.limit);
  const [listCategory, setListCategory] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [onCompleted, setOnCompleted] = useState(false);
  const [searchValue, setSearchValues] = useState("");
  const [deleteCategory, { loading: deleting }] = useMutation(
    Schema.DeleteCategory
  );
  const [queryCategory, { loading }] = useLazyQuery(Schema.Categories, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setListCategory(data?.categories?.data);
      setTotalItem(data?.categories?.total);
    },
  });

  useEffect(() => {
    const shopID = getLocalShopID();
    let where = {};
    if (getUserRole() === "SUPER_ADMIN" || getUserRole() === "OWNER") {
      where = {};
    } else {
      where = {
        shop: shopID,
      };
    }
    queryCategory({
      variables: {
        where: {
          title: searchValue ? searchValue : undefined,
          ...where,
        },
        limit: numberRows,
        skip: numberRows * (currentPage - 1),
      },
    });
  }, [queryCategory, onCompleted, searchValue, currentPage, numberRows]);
  const totalPage = Math.ceil(totalItem / numberRows);
  useEffect(() => {
    let page = params.get("page");
    let rows = params.get("rows");
    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(RootVariable.limit);
    }
  }, [params]);

  if (loading) {
    loadingScreen();
  } else {
    closeLoadingScreen();
  }

  const handleDelete = (id: number) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລືບ ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await deleteCategory({
            variables: {
              where: {
                _id: Number(id),
              },
            },
          });
          messageSuccess("ການດຳເນີນງານສຳເລັດ");
          setOnCompleted(!onCompleted);
        } catch (error) {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <div className="page-wrapper">
      <div className="content ms-2">
        <nav aria-label="breadcrumb" className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={() => navigate(Paths.home)}>
                ໜ້າຫຼັກ
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              ໝວດສິນຄ້າ
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-lg-2 col-md-3 col-ms-4 col-xs-6 col-4">
            <AddCategory
              onCompleted={onCompleted}
              setOnCompleted={(res: boolean) => setOnCompleted(res)}
            />
          </div>
          <div className="col-lg-10 col-md-9 col-ms-8 col-xs-6 col-8">
            <input
              type="search"
              placeholder="ຄົ້ນຫາ..."
              className="form-control search-input"
              value={searchValue}
              onChange={(e) => setSearchValues(e.target.value)}
            />
          </div>
          <div className="col-12">
            <div className="section-heading mt-2">
              <div className="row">
                <LimitData
                  numberRows={numberRows}
                  totalPage={totalPage}
                  currentPage={currentPage}
                  total={totalItem}
                  hiddenSearch={true}
                  col={6}
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>ລຳດັບ</th>
                    <th className="text-nowrap">ປະເພດ</th>
                    <th className="text-nowrap text-center">ລາຍລະອຽດ</th>
                    <th className="text-nowrap">ວັນທີ່</th>
                    <th className="text-nowrap text-center">ຈັດການ</th>
                  </tr>
                </thead>
                <tbody>
                  {listCategory?.map((item: any, index: number) => (
                    <>
                      <tr data-item="list" key={index}>
                        <td className="text-center">
                          {NO(index, currentPage, numberRows)}
                        </td>
                        <td>{item?.title ? item?.title : " "}</td>
                        <td>{item?.description ? item?.description : "-"}</td>
                        <td className="text-nowrap ">
                          {item?.createdAt
                            ? formatDateTime(item?.createdAt)
                            : "-"}
                        </td>
                        <td className="text-end">
                          <div className="btn-group btn-sm py-0">
                            <EditCategory
                              item={item}
                              onSuccess={() => {
                                setOnCompleted(!onCompleted);
                              }}
                            />
                            {getUserRole() === "SUPER_ADMIN" && (
                              <>
                                <button
                                  className="btn btn-outline-primary btn-sm my-0"
                                  type="button"
                                  onClick={() => handleDelete(item?._id)}
                                >
                                  <i className="icon-trash" />
                                </button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="container mt-3">
              {(() => {
                return (
                  <Pagination
                    total={totalPage}
                    current={currentPage || 1}
                    onPageChange={(page) => {
                      params.set("page", page.toString());
                      setParams(params);
                    }}
                  />
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
