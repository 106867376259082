import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import { useEffect, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import { ImArrowLeft2 } from "react-icons/im";

import {
  ItemSelect,
  NO,
  closeLoadingScreen,
  currency,
  endOfMonth,
  formatDateTime,
  getLocalShop,
  getStaffLogin,
  loadingScreen,
  startOfMonth,
} from "../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootVariable } from "../../utils";
import LimitData from "../../utils/components/LimitData";
import { Paths } from "../../routes/Path";
import NoData from "../../utils/components/NoData";
import PrintData from "./PrintData";
import AddData from "./AddData";
import EditData from "./EditData";
import { PiWarningCircleFill } from "react-icons/pi";

export default function GenerateCode() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [userAuth, setUserAuth] = useState<any>({});
  const [localShop, setLocalShop] = useState<any>();

  const [startDate, setStartDate]: any = useState(null);
  const [endDate, setEndDate]: any = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(RootVariable.limit);
  const [totalItem, setTotalItem] = useState(0);
  const [selectedAllItem, setSelectedAllItem] = useState<any>(0);
  const [listData, setListData] = useState([]);
  const [newRefresh, setNewRefresh] = useState(false);
  const [shopData, setDataShop]: any = useState([]);

  useEffect(() => {
    setUserAuth(getStaffLogin());
    setLocalShop(getLocalShop());
  }, []);
  const [queryShop] = useLazyQuery(Schema.queryShop, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setDataShop(data?.shops?.total);
    },
  });

  const [queryOrder, { loading: loadingData }] = useLazyQuery(
    Schema.queryBarcode,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setListData(data?.generateCodes?.data);
        setTotalItem(data?.generateCodes?.total);
      },
    }
  );

  useEffect(() => {
    queryShop({
      variables: {
        where: {
          isDeleted: 0,
          isPublic: 1,
        },
        limit: 100,
      },
    });
  }, [queryShop]);
  useEffect(() => {
    let _where = {};
    if (localShop) {
      _where = {
        shop: localShop?._id,
      };
    } else {
      _where = {
        shop: userAuth?.shop?._id,
      };
    }
    queryOrder({
      variables: {
        where: {
          ..._where,
        },
        limit: numberRows,
        skip: numberRows * (currentPage - 1),
      },
    });
  }, [
    queryOrder,
    newRefresh,
    userAuth,
    numberRows,
    currentPage,
  ]);
  //pagination
  const totalPage = Math.ceil(totalItem / numberRows);
  useEffect(() => {
    let page = params.get("page");
    let rows = params.get("rows");
    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(startOfMonth());
    }
    if (endDate) {
      setEndDate(endDate);
    } else {
      setEndDate(endOfMonth());
    }

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(RootVariable.limit);
    }
  }, [params]);

  const handleClick = () => {
    const url = `/print_generate_code?getIds=${selectedAllItem}`;
    window.open(url, "_blank");
  };

  const handleClickOne = (id: number, bill: string) => {
    const url = `/print_generate_code?getIds=${id}&bill=${bill}`;
    window.open(url, "_blank");
  };

  return (
    <div className="page-wrapper">
      <div className="content ms-2">
        <nav aria-label="breadcrumb" className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a
                href="javascript:void(0)"
                onClick={() => navigate(Paths.list_order)}
              >
                <ImArrowLeft2 /> ກັບຄຶນ
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              ລາຍງານຂໍ້ມູນສິນຄ້າທີ່ສ້າງ Barcode
            </li>
          </ol>
        </nav>
        <div className="row my-3">
          {/* of web */}
          {shopData > 0 ? (
            <>
              <div className="col-12 col-lg-6 mt-4">
                <AddData
                  onSuccess={() => {
                    setNewRefresh(!newRefresh);
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-12 col-lg-6 mt-4">
                <button className="btn btn-outline-primary w-100">
                  <PiWarningCircleFill className="me-1" />
                  <b style={{ fontSize: "13px" }}>
                    ຮ້ານຂອງທ່ານຖືກປິດ (ແຈ້ງຜູ້ຮັບຜິດຊອບເພື່ອກວດສອບ)
                  </b>
                </button>
              </div>
            </>
          )}

          <div className="col-12 col-lg-6 mt-4 hiddenDivOfApp text-end">
            <button
              className="btn btn-warning btn-lg fs-5 events"
              disabled={!selectedAllItem}
              onClick={handleClick}
            >
              <i className="icon-print" /> ພິມລາຍການທີ່ເລືອກ
              {selectedAllItem.length > 0 ? (
                <strong className="box-total">
                  {currency(selectedAllItem.length)}
                </strong>
              ) : null}
            </button>
            <PrintData
              currentPage={currentPage}
              total={totalItem}
              listData={listData}
              col={6}
            />
          </div>
          {/* end Of wev */}
          {/* oF Mobile */}
          <div className="col-12 col-lg-12  hiddenDivOfWeb d-flex">
            <button
              className="btn btn-warning btn-lg events"
              disabled={!selectedAllItem}
              onClick={handleClick}
            >
              <i className="icon-print" /> ພິມບິນ
              {selectedAllItem.length > 0 ? (
                <strong className="box-total">
                  {currency(selectedAllItem.length)}
                </strong>
              ) : null}
            </button>
            <PrintData total={totalItem} listData={listData} col={6} />
          </div>
          {/* end Of Mobile */}
        </div>
        <div className="row my-3">
          <LimitData
            numberRows={numberRows}
            totalPage={totalPage}
            currentPage={currentPage}
            total={totalItem}
            hiddenSearch={true}
            col={6}
          />
        </div>

        <div className="row">
          <div className="col-12 p-2 card">
            {loadingData ? loadingScreen() : closeLoadingScreen()}
            <div className="table-responsive">
              <table className="table table-hover table-striped">
                <thead>
                  <tr>
                    <th className="text-center">
                      <input
                        type="checkbox"
                        className="check-box"
                        id="selected-all"
                        onChange={(e) => setSelectedAllItem(ItemSelect.all(e))}
                      />
                    </th>
                    <th>#</th>
                    <th className="text-center">barcode</th>
                    <th className="text-nowrap">ຊື່ສິນຄ້າ</th>
                    <th className="text-nowrap">ລາຄາ</th>
                    <th>ຜູ້ສ້າງ</th>
                    <th>ວັນທີອໍເດີ່</th>
                    <th>ຄຳອະທິບາຍ</th>
                    <th className="text-center">ພິມ</th>
                    <th className="text-center">ຈັດການ</th>
                  </tr>
                </thead>
                <tbody>
                  {listData &&
                    listData?.map((item: any, index) => (
                      <tr
                        style={{
                          backgroundColor:
                            item?.orderStatus === "PRINTED" ? "#eafce6" : "",
                        }}
                      >
                        <td className="text-center" style={{ width: 20 }}>
                          <input
                            type="checkbox"
                            className="check-box item-checkbox"
                            value={item?._id}
                            onChange={() =>
                              setSelectedAllItem(ItemSelect.one())
                            }
                          />
                        </td>
                        <td className="text-center text-nowrap">
                          {NO(index, currentPage, numberRows)}
                        </td>
                        <td className="text-center text-nowrap">
                          {item?.code ?? "-"}
                        </td>
                        <td>{currency(item?.price) ?? ".."}</td>
                        <td>{item?.title ?? ".."}</td>
                        <td className="text-nowrap">
                          {item?.createdBy?.fullName ?? "- -"}
                        </td>
                        <td
                          className="text-center text-nowrap"
                          style={{ width: 120 }}
                        >
                          {formatDateTime(item?.createdAt) ?? "- -"}
                        </td>
                        <td
                          className="text-center text-nowrap"
                          style={{ width: 120 }}
                        >
                          {item?.description ?? "- -"}
                        </td>
                        <td className="text-center text-nowrap">
                          <div className="btn-group btn-sm py-0">
                            <button
                              className="btn btn-outline-primary my-0"
                              onClick={(e) =>
                                handleClickOne(item?._id, item?.code)
                              }
                              title="ພິມບິນລາຍການນີ້"
                            >
                              <i className="icon-print" />
                            </button>
                          </div>
                        </td>
                        <td className="text-center">
                          <EditData
                            onSuccess={() => {
                              setNewRefresh(!newRefresh);
                            }}
                            _data={item}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="container mt-3">
              {(() => {
                return (
                  <ResponsivePagination
                    total={totalPage}
                    current={currentPage || 1}
                    onPageChange={(page: number) => {
                      params.set("page", page.toString());
                      setParams(params);
                    }}
                  />
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
