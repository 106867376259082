import { useEffect, useState } from "react";
import { MdAddTask } from "react-icons/md";
import { Col, Form, Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import InputNumberFormat from "../../utils/components/InputNumberFormat";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useMutation } from "@apollo/client";
import Schema from "./Schema";
import {
  LocalStorageName,
  messageError,
  messageSuccess,
  placeholderImage,
} from "../../utils";
import { url_path_IBank, url_upload_IBank } from "../../utils/url";
import compressImage from "../../utils/UploadFile";
import axios from "axios";
import SelectPackage from "../../utils/components/selectPackage";

export default function ConFirmUse({ onSuccess, idShop }: any) {
  const [updateData, { loading }] = useMutation(Schema.updateShop);
  const [show, setShow] = useState<any>(false);
  const [fileMoney, setFileMoney] = useState<any>("");
  const [packages, setPackages] = useState<any>({});
  const {
    handleChange,
    errors,
    values,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      description: "",
      fileMoney: "",
      package: "",
      packagePrice: "",
    },
    enableReinitialize: false,
    validate: (values) => {
      const errors: any = {};
      if (!fileMoney) {
        errors.fileMoney = "ກະລຸນາອັບໂຫຼດຫຼັກຖານການຈ່າຍເງິນ";
      }
      if (!packages?._id) {
        errors.package = "ກະລຸນາເລືອກແພັກເກັຈບໍລິການ";
      }
      if (!values.description) {
        errors.description = "ກະລຸນາປ້ອນຄຳອະທິບາຍ";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await updateData({
          variables: {
            data: {
              status: "LIVE",
              comFirmPaid: 1,
              firstFileMoney: fileMoney,
              summary: 1,
              package: Number(packages?._id),
              packagePrice: Number(packages?.packagePrice),
            },
            where: {
              _id: parseInt(idShop),
            },
          },
        });
        messageSuccess("ດຳເນີນງານສຳເລັດແລ້ວ");
        setShow(false);
        resetForm();
        if (onSuccess) onSuccess();
      } catch (error) {
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
      }
    },
  });

  const handleChangeMoneyUpload = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setFileMoney(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res: any = await axios.post(url_upload_IBank, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setFileMoney(filename);
        } else {
          setFileMoney("");
          messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  return (
    <>
      <button className="btn btn-primary my-0" onClick={() => setShow(true)}>
        <i className="icon-check-circle me-1" />
        ຢືນຢັນໃຊ້ງານຈິງ
      </button>
      <Modal show={show} size="lg">
        <Modal.Header className="bg-primary text-white">
          <h3 className="text-center">ຟອມຊຳລະຄ່າບໍລິການ</h3>
          {loading ? (
            <></>
          ) : (
            <>
              <a
                href="javascript:void(0)"
                className="float-end"
                onClick={() => setShow(false)}
              >
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          <div>
            <Col md={12}>
              <div className="col-12 col-lg-12 p-2 card">
                <div className="form-group">
                  <div style={{ textAlign: "center" }}>
                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleChangeMoneyUpload}
                      name="file"
                      accept="image/png, image/jpeg"
                      // hidden
                    />
                    <br />
                    <i className="text-danger">{errors.fileMoney}</i>
                    <label htmlFor="file-upload">
                      {fileMoney && (
                        <div
                          style={{
                            height: 200,
                            width: 200,
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            backgroundPosition: "center",
                            backgroundSize: "100%",
                            textAlign: "center",
                            borderRadius: 7,
                          }}
                        >
                          <img
                            src={
                              fileMoney
                                ? url_path_IBank + fileMoney
                                : placeholderImage(200, 200)
                            }
                            alt={fileMoney}
                            style={{
                              height: 200,
                              width: 200,
                              borderRadius: 7,
                            }}
                          />
                        </div>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12}>
              <Form.Group
                className="mt-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fs-5">ແພັກເກັຈບໍລິການ</Form.Label>
                {/* <Form.Control
                  placeholder="..."
                  name="package"
                  type="number"
                  onWheel={(e:any) => e.target.blur()}
                  onChange={handleChange}
                  value={values.package}
                  className={
                    errors.package
                      ? "is-invalid form-control form-control-lg"
                      : "form-control form-control-lg"
                  }
                /> */}
                <SelectPackage
                  value={packages?._id}
                  onChange={(obj: any) => {
                    if (obj?._id) {
                      setPackages(obj);
                    }
                  }}
                  getSearch={0}
                />

                <i className="text-danger">{errors.package}</i>
              </Form.Group>
              {/* <Form.Group
                className="mt-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fs-5">ຈຳນວນເດືອນ</Form.Label>
                <Form.Control
                  placeholder="..."
                  type="number"
                  onWheel={(e:any) => e.target.blur()}
                  name="packagePrice"
                  onChange={handleChange}
                  value={values.packagePrice}
                  className={
                    errors.packagePrice
                      ? "is-invalid form-control form-control-lg"
                      : "form-control form-control-lg"
                  }
                />
                <i className="text-danger">{errors.packagePrice}</i>
              </Form.Group> */}

              <Form.Group
                className="mt-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fs-5">ຄຳອະທິບາຍ</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="..."
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                  // className="form-control"
                  className={
                    errors.description
                      ? "is-invalid form-control"
                      : "form-control"
                  }
                />
                <i className="text-danger">{errors.description}</i>
              </Form.Group>
            </Col>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <>
              <Spinner animation="border" variant="primary" />
            </>
          ) : (
            <>
              <button
                className="btn btn-success btn-lg"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isSubmitting}
              >
                <AiOutlineCheckCircle style={{ fontSize: "28px" }} /> ຢືນຢັນຊຳລະ
              </button>
              <button
                className="btn btn-danger btn-lg"
                onClick={() => {
                  setShow(false);
                }}
                disabled={isSubmitting}
              >
                <i className="icon-x me-1" /> ຍົກເລີກ
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
