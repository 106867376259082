import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import Schema from "./Schema";
import {
  InvalidText,
  LocalStorageName,
  getUserRole,
  messageError,
  messageSuccess,
  onLoading,
  placeholderImage,
} from "../../utils";
import { url_path_images, url_upload_images } from "../../utils/url";
import compressImage from "../../utils/UploadFile";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import SelectPackage from "../../utils/components/selectPackage";
import { set } from "lodash";

export default function UpdateShops({ onSuccess, _data }: any) {
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [packages, setPackages] = useState<any>({});

  const [updateShop, { loading: createTing }]: any = useMutation(
    Schema.updateShop
  );

  useEffect(() => {
    setPackages(_data?.package);
  }, [_data]);
  const handleChangeImageUpload = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      setUploading(true);
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setImage(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res: any = await axios.post(url_upload_images, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setImage(filename);
        } else {
          setImage("");
          messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };
  const {
    handleChange,
    errors,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      title: "",
      phoneNumber: "",
      pageName: "",
      address: "",
      description: "",
      profileImage: "",
      package: "",
    },
    enableReinitialize: false,
    validate: (values) => {
      const errors: any = {};
      if (!values.title) {
        errors.title = "ກະລຸນາປ້ອນຊື່ຮ້ານ";
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = "ກະລຸນາປ້ອນຂໍ້ມູນຕິດຕໍ່";
      }
      if (!packages?._id) {
        errors.packages = "ກະລຸນາເລືອກແພັກເກັຈ";
      }
      if (!values.pageName) {
        errors.pageName = "ກະລຸນາປ້ອນເພຈ";
      }
      if (!values.address) {
        errors.address = "ກະລຸນາປ້ອນທີ່ຢູ່";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const { data: update } = await updateShop({
          variables: {
            data: {
              title: values?.title,
              phoneNumber: values?.phoneNumber,
              pageName: values?.pageName,
              address: values?.address,
              description: values?.description,
              profileImage: String(image),
              package: Number(packages?.countTime),
              packagePrice: Number(packages?.packagePrice),
            },
            where: {
              _id: parseInt(_data?._id),
            },
          },
        });

        if (update?.updateShop?.status) {
          messageSuccess("ດຳເນີນງານສຳເລັດແລ້ວ");
          setShow(false);
          if (onSuccess) onSuccess();
        } else {
          messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
        }
      } catch (error) {
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
      }
    },
  });
  useEffect(() => {
    if (!_data) return;
    setFieldValue("title", _data?.title);
    setFieldValue("phoneNumber", _data?.phoneNumber);
    setFieldValue("address", _data?.address);
    setFieldValue("pageName", _data?.pageName);
    setFieldValue("description", _data?.description);
    setFieldValue("packagePrice", _data?.packagePrice);
    setImage(_data?.profileImage);
  }, [_data]);
  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm"
        onClick={() => setShow(true)}
      >
        <i className="icon-edit" />
      </button>
      <Modal show={show} size="lg">
        <Modal.Header
          onHide={() => {
            setShow(false);
            resetForm();
          }}
        >
          <h3 className="text-center">
            <i className="icon-edit me-1" />
            ແກ້ໄຂຂໍ້ມູນຮ້ານຄ້າ
          </h3>
          {createTing ? (
            <>
              <a href="javascript:void(0)" className="float-end">
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                href="javascript:void(0)"
                className="float-end"
                onClick={() => {
                  setShow(false);
                }}
              >
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <form>
              <div className="col-12 col-lg-4 col-md-12 col-sm-12">
                <div className="form-group">
                  <div style={{ textAlign: "center" }}>
                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleChangeImageUpload}
                      name="file"
                      accept="image/png, image/gif, image/jpeg"
                      hidden
                    />
                    <label htmlFor="file-upload">
                      <div
                        style={{
                          height: 200,
                          width: 200,
                          cursor: "pointer",
                          display: "flex",
                          backgroundImage: `url(${onLoading})`,
                          justifyContent: "center",
                          backgroundPosition: "center",
                          backgroundSize: "100%",
                          textAlign: "center",
                          borderRadius: 7,
                        }}
                      >
                        {image ? (
                          <img
                            src={
                              image
                                ? url_path_images + image
                                : placeholderImage(200, 200)
                            }
                            alt={image}
                            style={{
                              height: 200,
                              width: 200,
                              borderRadius: 7,
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              backgroundImage: `url(${placeholderImage(
                                200,
                                200
                              )})`,
                              display: "flex",
                              height: 200,
                              width: 200,
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 7,
                              backgroundSize: "100%",
                            }}
                          ></div>
                        )}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ຊື່ຮ້ານ {InvalidText()}
                </label>
                <input
                  type="text"
                  className={
                    errors.title
                      ? "form-control is-invalid"
                      : "form-control  invalid"
                  }
                  placeholder="ປ້ອນຊື່ຮ້ານ"
                  name="title"
                  value={String(values.title)}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ເບີຕິດຕໍ່ {InvalidText()}
                </label>
                <input
                  type="number"
                  className={
                    errors.phoneNumber
                      ? "form-control is-invalid"
                      : "form-control  invalid"
                  }
                  placeholder="ປ້ອນເບີຕິດຕໍ່"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ຊື່ເພຈ໌ {InvalidText()}
                </label>
                <input
                  type="text"
                  className={
                    errors.pageName
                      ? "form-control is-invalid"
                      : "form-control  invalid"
                  }
                  placeholder="ຊື່ເພຈ໌"
                  name="pageName"
                  value={String(values.pageName)}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ແພັກເກັຈບໍລິການ {InvalidText()}
                </label>
                <SelectPackage
                  value={packages?._id}
                  onChange={(obj: any) => {
                    if (obj?._id) {
                      setPackages(obj);
                    }
                  }}
                  getSearch={0}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ທີ່ຢູ່
                </label>
                <textarea
                  name="address"
                  className={
                    errors.address
                      ? "form-control is-invalid"
                      : "form-control  invalid"
                  }
                  placeholder="ປ້ອນທີ່ຢູ່"
                  rows={4}
                  value={String(values.address)}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>
                  <i className="fas fa-layer-group me-1" />
                  ລາຍລະອຽດ
                </label>
                <textarea
                  name="description"
                  className="form-control"
                  placeholder="ລາຍລະອຽດອໍເດີ"
                  rows={4}
                  value={String(values.description)}
                  onChange={handleChange}
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-lg"
            disabled={createTing}
            onClick={() => handleSubmit()}
          >
            <i className="icon-check-circle" />
            <span className="ms-1">ບັນທຶກ</span>
          </button>
          <button
            type="button"
            className="btn btn-light btn-lg"
            disabled={createTing}
            onClick={() => (setShow(false), resetForm())}
          >
            <i className="icon-x" />
            <span className="ms-1">ຍົກເລີກ</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
