import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "./Schema";
import { useEffect, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import {
  closeLoadingScreen,
  getLocalShop,
  getUserRole,
  loadingScreen,
  placeholderImage,
} from "../../utils";
import {
  NO,
  endOfMonth,
  formatDateTime,
  getStaffLogin,
  notifyError,
  notifySuccess,
  startOfMonth,
  userRole,
} from "../../utils";
import male from "../../assets/images/male.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootVariable } from "../../utils";
import LimitData from "../../utils/components/LimitData";
import { Confirm } from "notiflix";
import AddUser from "./AddUser";
import UpdateUser from "./UpdateUser";
import ExportUsers from "./ExportUser";
import { Paths } from "../../routes/Path";
import { url_path_images } from "../../utils/url";
import PreviewFileUpLoad from "../../utils/components/PreviewFileUpLoad";

export default function MemberScreen() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [startDate, setStartDate]: any = useState(null);
  const [endDate, setEndDate]: any = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(RootVariable.limit);
  const [totalItem, setTotalItem] = useState(0);
  const [status, setStatus] = useState("");
  const [searchValue, setSearchValues] = useState("");
  const [listUser, setListUser] = useState([]);
  const [newRefresh, setNewRefresh] = useState(false);
  const [userAuth, setUserAuth]: any = useState({});

  const [localShop, setLocalShop] = useState<any>();

  useEffect(() => {
    setLocalShop(getLocalShop());
  }, []);

  const [deleteUsers]: any = useMutation(Schema.deleteUser);

  const [queryUser, { loading: loadingData }] = useLazyQuery(Schema.queryUser, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setListUser(data?.users?.data);
      setTotalItem(data?.users?.total);
    },
  });
  useEffect(() => {
    setUserAuth(getStaffLogin());
  }, []);
  const getRole = getUserRole() === "SUPER_ADMIN";
  useEffect(() => {
    if (getRole) {
      queryUser({
        variables: {
          where: {
            fullName: searchValue ? searchValue : undefined,
            isDeleted: 0,
          },
          limit: numberRows,
          skip: numberRows * (currentPage - 1),
        },
      });
    } else {
      queryUser({
        variables: {
          where: {
            fullName: searchValue ? searchValue : undefined,
            shop: userAuth?.shop?._id,
            isDeleted: 0,
          },
          limit: numberRows,
          skip: numberRows * (currentPage - 1),
        },
      });
    }
  }, [
    queryUser,
    newRefresh,
    searchValue,
    userAuth,
    numberRows,
    currentPage,
    startDate,
    endDate,
    status,
  ]);

  //pagination
  const totalPage = Math.ceil(totalItem / numberRows);
  useEffect(() => {
    let page = params.get("page");
    let rows = params.get("rows");
    let startDate = params.get("startDate");
    let endDate = params.get("endDate");
    let status = params.get("status");
    const _search = params.get("search");
    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(startOfMonth());
    }
    if (endDate) {
      setEndDate(endDate);
    } else {
      setEndDate(endOfMonth());
    }

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(RootVariable.limit);
    }

    if (status) {
      setStatus(String(status));
    } else {
      setStatus("PENDING");
    }
    setSearchValues(_search ? String(_search) : "");
  }, [params]);

  const deleteUser = (id: number) => {
    console.log(id);
    Confirm.show(
      "ເເຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການຢືນຢັນລາຍການນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        const _deleted = await deleteUsers({
          variables: {
            where: {
              _id: id,
            },
          },
        });
        if (_deleted) {
          notifySuccess("ການດຳເນີນງານສຳເລັດ");
          setNewRefresh(!newRefresh);
        } else {
          notifyError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <div className="page-wrapper">
      <div className="content ms-2">
        <nav aria-label="breadcrumb" className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={() => navigate(Paths.home)}>
                ໜ້າຫຼັກ
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              ຈັດການສະມາຊິກ
            </li>
          </ol>
        </nav>
        {/* of Web*/}
        <div className="row my-3  hiddenDivOfApp">
          {getUserRole() === "ADMIN" ||
          getUserRole() === "SUPER_ADMIN" ||
          getUserRole() === "OWNER" ? (
            <>
              <div className="col-2">
                <AddUser
                  onSuccess={() => {
                    setNewRefresh(!newRefresh);
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="col-2">
            <ExportUsers
              searchValue={searchValue}
              numberRows={numberRows}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div className="col-md-8 mt-1">
            <div className="form-group">
              <input
                type="search"
                placeholder="ຄົ້ນຫາດ້ວຍຊື່..."
                className="form-control"
                onChange={(e) => {
                  params.set("search", e.target.value);
                  setParams(params);
                }}
              />
            </div>
          </div>
        </div>
        {/* of App */}
        <div className="row hiddenDivOfWeb">
          <div className="col-md-12  mt-2">
            <div className="row">
              <div className="col-6">
                {getUserRole() === "ADMIN" ||
                getUserRole() === "SUPER_ADMIN" ||
                getUserRole() === "OWNER" ? (
                  <>
                    <AddUser
                      onSuccess={() => {
                        setNewRefresh(!newRefresh);
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-6">
                {getUserRole() === "ADMIN" ||
                getUserRole() === "SUPER_ADMIN" ||
                getUserRole() === "OWNER" ? (
                  <>
                    <ExportUsers
                      searchValue={searchValue}
                      numberRows={numberRows}
                      startDate={startDate}
                      endDate={endDate}
                      listUser={listUser}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-1">
            <div className="form-group">
              <label>ຄົນຫາດ້ວຍຊື່</label>
              <input
                type="search"
                placeholder="ຄົ້ນຫາດ້ວຍຊື່..."
                className="form-control "
                onChange={(e) => {
                  params.set("search", e.target.value);
                  setParams(params);
                }}
              />
            </div>
          </div>
          {/* <div className="col-5">
            <SearchDateRange isToday />
          </div> */}
        </div>
        <div className="row my-2">
          <LimitData
            numberRows={numberRows}
            totalPage={totalPage}
            currentPage={currentPage}
            total={totalItem}
            hiddenSearch={true}
            col={6}
          />
        </div>

        <div className="row">
          <div className="col-12 p-2 card">
            {loadingData ? loadingScreen() : closeLoadingScreen()}
            <div className="table-responsive">
              <table className="table table-hover table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="text-nowrap">ໂປຣໄຟລ໌</th>
                    <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                    <th>ເບີໂທ</th>
                    <th>ສິດການໃຊ້ງາານ</th>
                    <th>ບ່ອນປະຈຳ</th>
                    <th>ວັນທີ່ສ້າງ</th>
                    <th>ສ້າງໂດຍ</th>
                    <th>ຈັດການ</th>
                  </tr>
                </thead>
                <tbody>
                  {listUser?.map((item: any, index) => (
                    <tr>
                      <td className="text-center text-nowrap">
                        {NO(index, currentPage, numberRows)}
                      </td>
                      <td
                        className="text-center text-nowrap p-1"
                        style={{ width: 10 }}
                      >
                        <div className="profile-image">
                          <img
                            src={
                              item?.profileImage
                                ? url_path_images + item?.profileImage
                                : placeholderImage(100, 100)
                            }
                            className="image"
                            data-darkbox={
                              item?.profileImage
                                ? url_path_images + item?.profileImage
                                : placeholderImage(520, 520)
                            }
                            data-darkbox-group="two"
                          />
                        </div>
                      </td>
                      <td className="text-nowrap">{item?.fullName ?? " "}</td>
                      <td className="text-center text-nowrap">
                        {item?.phoneNumber ?? " "}
                      </td>
                      <td className="text-center text-nowrap">
                        {userRole(item?.role) ?? " "}
                      </td>
                      <td className="text-nowrap">
                        {item?.shop?.title ?? " "}
                      </td>
                      <td className="text-center">
                        {formatDateTime(item?.createdAt) ?? " "}
                      </td>
                      <td className="text-nowrap">
                        {item?.createdBy?.fullName ?? "-"}
                      </td>
                      <td className="text-center text-nowrap" width={200}>
                        <div className="btn-group btn-sm py-0">
                          {getUserRole() === "ADMIN" ||
                          getUserRole() === "OWNER" ? (
                            <>
                              {item?.shop?._id === localShop?._id && (
                                <>
                                  <UpdateUser
                                    onSuccess={() => {
                                      setNewRefresh(!newRefresh);
                                    }}
                                    _data={item}
                                    defaultValue={item?.shop?.title}
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {getUserRole() === "SUPER_ADMIN" && (
                                <>
                                  <UpdateUser
                                    onSuccess={() => {
                                      setNewRefresh(!newRefresh);
                                    }}
                                    _data={item}
                                    defaultValue={item?.shop?.title}
                                  />
                                </>
                              )}
                            </>
                          )}

                          {getUserRole() === "SUPER_ADMIN" && (
                            <>
                              <button
                                className="btn btn-outline-primary btn-sm my-0"
                                type="button"
                                onClick={() => deleteUser(item?._id)}
                              >
                                <i className="icon-trash" />
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="container mt-3">
              {(() => {
                return (
                  <ResponsivePagination
                    total={totalPage}
                    current={currentPage || 1}
                    onPageChange={(page: number) => {
                      params.set("page", page.toString());
                      setParams(params);
                    }}
                  />
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
