import React, { useEffect, useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { formatDateTime, userRole } from "../../utils";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";

export default function ExportUsers({ listUser }: any) {
  const elmRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });

  let today = moment().format("YYYY-MM-DD");
  let time = moment().format("HH:mm:ss");
  const [show, setShow] = useState(false);
  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-secondary"
        style={{ position: "relative", top: "0px" }}
        onClick={() => setShow(true)}
      >
        <i className="icon-print me-2" />
        ພິມລາຍງານ
      </button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fas fa-info-circle me-1" />
            ລາຍງານຂໍ້ມູນ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-md-12">
              <button
                onClick={handlePrint}
                type="button"
                className="btn btn-dark ml-3 me-2"
              >
                <i className="icon-print mr-1"></i> ພິມ
              </button>
              <DownloadTableExcel
                filename="users table"
                sheet="ລາຍງານຂໍ້ມູນ"
                currentTableRef={elmRef.current}
              >
                <button className="btn btn btn-success"> Export excel </button>
              </DownloadTableExcel>
            </div>
          </Row>
          <div id="table-to-xls" className="container-print" ref={elmRef}>
            <div style={{ paddingBottom: "5px" }} />
            <div className="table-responsive">
              <table className="table-sm" border={1}>
                <thead>
                  <tr>
                    <td colSpan={6} style={{ textAlign: "right", border: "0" }}>
                      <h4>
                        {" "}
                        ລາຍງານຂໍ້ມູນພະນັກງານຂອງ ຮ້ານ{" "}
                        {listUser?.[0]?.shop?.title}
                      </h4>
                    </td>
                    <td style={{ border: "0", textAlign: "right" }}>
                      <span className="text-center">
                        ວັນທີ: {formatDateTime(today)}
                      </span>
                      <br />
                      <span className="text-center">ເວລາ: {time}</span>
                    </td>
                  </tr>
                  <tr className="text-dark">
                    <th className="text-dark">#</th>
                    <th className="text-dark"> ຊື່ ແລະ ນາມສະກຸນ</th>
                    <th className="text-dark">ເບີໂທ</th>
                    <th className="text-dark">ສິດການໃຊ້ງາານ</th>
                    <th className="text-dark">ບ່ອນປະຈຳ</th>
                    <th className="text-dark">ວັນທີ່ສ້າງ</th>
                    <th className="text-dark">ສ້າງໂດຍ</th>
                  </tr>
                </thead>
                <tbody>
                  {listUser?.map((item: any, index: any) => (
                    <>
                      <tr key={index}>
                        <td className="text-center text-nowrap">{index + 1}</td>
                        <td className="text-center text-nowrap">
                          {item?.fullName ?? " "}
                        </td>
                        <td className="text-start text-nowrap">
                          {item?.phoneNumber ?? " "}
                        </td>
                        <td className="text-center text-nowrap">
                          {userRole(item?.role) ?? " "}
                        </td>
                        <td className="text-start">
                          {item?.shop?.title ?? " "}
                        </td>
                        <td className="text-start">
                          {formatDateTime(item?.createdAt) ?? " "}
                        </td>
                        <td className="text-start">
                          {item?.createdBy?.fullName ?? "-"}
                        </td>
                      </tr>
                    </>
                  ))}
                  <br />
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
