import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Schema from "./Schema";
import { messageError, messageSuccess } from "../../utils";
import { NumericFormat } from "react-number-format";

export default function EditItem({ sendData, onSuccess }: any) {
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState<any>();
  const [trackingIds, setTrackingId] = useState<any>();
  const [updateOrder, { loading: createTing }] = useMutation(
    Schema.updateOrder
  );

  const {
    handleChange,
    errors,
    values,
    handleSubmit,
    resetForm,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      description: "",
      trackingId: "",
      codKIP: 0,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: any = {};
      if (!description) {
        errors.description = "ກະລຸນາປ້ອນລາຍລະອຽດອໍເດີ່ ";
      }
      if (getArray.length > 5 || getArray.length < 3) {
        errors.description =
          "ທ່ານກົດ enter ຫຼື ປ້ອນຂໍ້ມູນເກີນ 5 ແຖວແລ້ວ  ຫຼື ຕ້ອງປ້ອນຫຼາຍກວ່າ 3 ແຖວ";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await updateOrder({
          variables: {
            data: {
              description: String(description),
              trackingId: String(trackingIds),
            },
            where: {
              _id: sendData?._id,
            },
          },
        });
        messageSuccess("ແກ້ໄຂຂໍ້ມຸນສຳດລັດ");
        onSuccess();
        setShow(false);
      } catch (error) {
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
        console.log(error);
      }
    },
  });

  //check Enter InputData
  const getData = description ? description : "";
  const getArray = getData.split("\n");

  useEffect(() => {
    if (!sendData) return;
    setDescription(sendData?.description);
    setTrackingId(sendData?.trackingId);
  }, [sendData]);
  return (
    <>
      <button
        className="btn btn-outline-primary my-0"
        onClick={() => setShow(true)}
        title="ແກ້ໄຂບິນ"
      >
        <i className="icon-edit" />
      </button>
      <Modal show={show} size="lg" centered>
        <Modal.Header
          onHide={() => {
            setShow(false);
          }}
        >
          <h3 className="text-center">
            <i className="icon-edit me-1" />
            ແກ້ໄຂຂໍ້ມູນ
          </h3>
          {createTing ? (
            <>
              <a href="javascript:void(0)" className="float-end">
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                href="javascript:void(0)"
                className="float-end"
                onClick={() => {
                  setShow(false);
                }}
              >
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="col-md-12">
              <label htmlFor="email">ລະຫັດອໍເດີ່ ( ຖ້າມີ )</label>
              <input
                type="text"
                placeholder="ລະຫັດອໍເດີ່"
                className="form-control form-control-lg mb-2"
                name="trackingId"
                value={trackingIds}
                onChange={(e) => setTrackingId(e.target.value)}
              />
              <label htmlFor="email">
                ລາຍລະອຽດ{" "}
                <b className="text-danger">
                  *(ຊື່ລູກຄ້າ ເບີໂທ ບ່ອນຝາກ ຊື່ສິນຄ້າ ຈຳນວນເຄື່ອງ...)
                </b>
              </label>
              <textarea
                name="description"
                style={{
                  backgroundColor: "#f8f9fa",
                  color: "black",
                  border: "1px solid #90948d",
                }}
                className={
                  errors.description
                    ? "form-control order-detail is-invalid"
                    : "form-control order-detail  invalid"
                }
                placeholder="ຊື່ ເບີໂທ  ບ່ອນຝາກ ຊື່ສິນຄ້າ  ຈະນວນເຄື່ອງ ..."
                rows={3}
                value={description}
                autoFocus={true}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
              {getArray.length < 3 ? (
                <>
                  <div
                    className="border-bottom"
                    style={{ fontSize: "14px", color: "#201cff" }}
                  >
                    ຕ້ອງປ້ອນຫຼາຍກວ່າ 3 ແຖວຂື້ນ (ປ້ອນຊື່ ກົດ Enter ເບີໂທ ກົດ
                    Enter ບ່ອນຝາກ ...)
                  </div>
                </>
              ) : (
                <>
                  {getArray.length > 5 ? (
                    <div
                      className="border-bottom"
                      style={{ fontSize: "14px", color: "#201cff" }}
                    >
                      ທ່ານກົດ enter ຫຼື ປ້ອນຂໍ້ມູນລົງແຖວເກີນ 5 ແຖວແລ້ວ
                      (ບໍ່ຄວນເກີນ 5 ແຖວ)
                    </div>
                  ) : (
                    <>
                      <div
                        className="border-bottom"
                        style={{ fontSize: "16px", color: "#65b800" }}
                      >
                        ທ່ານປ້ອນຂໍ້ມູນ {getArray.length} ແຖວ.
                      </div>
                    </>
                  )}
                </>
              )}
              {createTing ? (
                <>
                  <button
                    type="button"
                    className="btn btn-success w-100 btn-lg mt-2"
                  >
                    <Spinner animation="border" variant="info" />
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-success w-100 btn-lg mt-2"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    <i className="icon-check-circle" /> ຢືນຢັນແກ້ໄຂ
                  </button>
                </>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
