import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "./Schema";
import { useEffect, useState } from "react";
import { currency } from "../../utils";
import { ClockLoader } from "react-spinners";
import { RiMoneyDollarCircleFill } from "react-icons/ri";

export default function SummaryTotal({ code, shop_id }: any) {
  const [summary, setSummary] = useState<any>(0);

  const [queryShop, { loading: loadingData }] = useLazyQuery(
    Schema.summaryMemberShop,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setSummary(data?.summaryOfMember);
      },
    }
  );

  useEffect(() => {
    queryShop({
      variables: {
        where: {
          inviteCode: code,
        },
      },
    });
  }, [queryShop, code, shop_id]);
  return (
    <>
      <div className="row mt-4">
        <div className="col-lg-3 col-12 d-flex hiddenDivOfApp">
          <div className="dash-count das2">
            <div className="dash-counts">
              <h1>
                {0 ? (
                  <ClockLoader color="#ffff" size={40} />
                ) : (
                  <>{summary?.liveTotal ? currency(summary?.liveTotal) : 0}</>
                )}
              </h1>
              <h4 className="mt-2">ຮ້ານທັງໝົດ</h4>
            </div>
            <div className="dash-imgs">
              <svg
                width="72px"
                height="72px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#a7a5a5"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M22 19V22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19Z"
                    fill="#f5f5f5"
                    stroke="#f5f5f5"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    opacity="0.6"
                    d="M8 11H4V18H8V11Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    opacity="0.4"
                    d="M12 11H8V18H12V11Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    opacity="0.6"
                    d="M16 11H12V18H16V11Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    opacity="0.4"
                    d="M20 11H16V18H20V11Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    d="M23 22.75H1C0.59 22.75 0.25 22.41 0.25 22C0.25 21.59 0.59 21.25 1 21.25H23C23.41 21.25 23.75 21.59 23.75 22C23.75 22.41 23.41 22.75 23 22.75Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    d="M21.37 5.74982L12.37 2.14984C12.17 2.06984 11.83 2.06984 11.63 2.14984L2.63 5.74982C2.28 5.88982 2 6.29981 2 6.67981V9.99982C2 10.5498 2.45 10.9998 3 10.9998H21C21.55 10.9998 22 10.5498 22 9.99982V6.67981C22 6.29981 21.72 5.88982 21.37 5.74982ZM12 8.49982C11.17 8.49982 10.5 7.82982 10.5 6.99982C10.5 6.16982 11.17 5.49982 12 5.49982C12.83 5.49982 13.5 6.16982 13.5 6.99982C13.5 7.82982 12.83 8.49982 12 8.49982Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12 d-flex">
          <div className="dash-count das3">
            <div className="dash-counts">
              <h1>
                {0 ? (
                  <ClockLoader color="#ffff" size={40} />
                ) : (
                  <>
                    {summary?.activeTotal ? currency(summary?.activeTotal) : 0}
                  </>
                )}
              </h1>
              <h4 className="mt-2">ຮ້ານທີ່ກຳລັງໃຊ້ງານ</h4>
            </div>
            <div className="dash-imgs">
              <svg
                width="72px"
                height="72px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#a7a5a5"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M22 19V22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19Z"
                    fill="#f5f5f5"
                    stroke="#f5f5f5"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    opacity="0.6"
                    d="M8 11H4V18H8V11Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    opacity="0.4"
                    d="M12 11H8V18H12V11Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    opacity="0.6"
                    d="M16 11H12V18H16V11Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    opacity="0.4"
                    d="M20 11H16V18H20V11Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    d="M23 22.75H1C0.59 22.75 0.25 22.41 0.25 22C0.25 21.59 0.59 21.25 1 21.25H23C23.41 21.25 23.75 21.59 23.75 22C23.75 22.41 23.41 22.75 23 22.75Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    d="M21.37 5.74982L12.37 2.14984C12.17 2.06984 11.83 2.06984 11.63 2.14984L2.63 5.74982C2.28 5.88982 2 6.29981 2 6.67981V9.99982C2 10.5498 2.45 10.9998 3 10.9998H21C21.55 10.9998 22 10.5498 22 9.99982V6.67981C22 6.29981 21.72 5.88982 21.37 5.74982ZM12 8.49982C11.17 8.49982 10.5 7.82982 10.5 6.99982C10.5 6.16982 11.17 5.49982 12 5.49982C12.83 5.49982 13.5 6.16982 13.5 6.99982C13.5 7.82982 12.83 8.49982 12 8.49982Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12 d-flex hiddenDivOfApp">
          <div className="dash-count das4">
            <div className="dash-counts">
              <h1>
                {0 ? (
                  <ClockLoader color="#ffff" size={40} />
                ) : (
                  <>
                    {summary?.inActiveTotal
                      ? currency(summary?.inActiveTotal)
                      : 0}
                  </>
                )}
              </h1>
              <h4 className="mt-2">ຮ້ານທີ່ປິດໃຊ້ງານ</h4>
            </div>
            <div className="dash-imgs">
              <svg
                width="72px"
                height="72px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#a7a5a5"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M22 19V22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19Z"
                    fill="#f5f5f5"
                    stroke="#f5f5f5"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    opacity="0.6"
                    d="M8 11H4V18H8V11Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    opacity="0.4"
                    d="M12 11H8V18H12V11Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    opacity="0.6"
                    d="M16 11H12V18H16V11Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    opacity="0.4"
                    d="M20 11H16V18H20V11Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    d="M23 22.75H1C0.59 22.75 0.25 22.41 0.25 22C0.25 21.59 0.59 21.25 1 21.25H23C23.41 21.25 23.75 21.59 23.75 22C23.75 22.41 23.41 22.75 23 22.75Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                  <path
                    d="M21.37 5.74982L12.37 2.14984C12.17 2.06984 11.83 2.06984 11.63 2.14984L2.63 5.74982C2.28 5.88982 2 6.29981 2 6.67981V9.99982C2 10.5498 2.45 10.9998 3 10.9998H21C21.55 10.9998 22 10.5498 22 9.99982V6.67981C22 6.29981 21.72 5.88982 21.37 5.74982ZM12 8.49982C11.17 8.49982 10.5 7.82982 10.5 6.99982C10.5 6.16982 11.17 5.49982 12 5.49982C12.83 5.49982 13.5 6.16982 13.5 6.99982C13.5 7.82982 12.83 8.49982 12 8.49982Z"
                    fill="#f5f5f5"
                  ></path>{" "}
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12 d-flex hiddenDivOfApp">
          <div className="dash-count das4 bg-info">
            <div className="dash-counts">
              <h3>ຍອດເງິນທັງໝົດ: {summary?.TotalCommission ? currency(summary?.TotalCommission) : 0}</h3>
              <h4 className="mt-2">ຄອມມິດຊັ່ນ: {currency(summary?.TotalCommission * 25 / 100)} ກີບ</h4>
            </div>
            <div className="dash-imgs">
              <RiMoneyDollarCircleFill />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
