import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "./Schema";
import { useEffect, useState } from "react";
import { currency, getStaffLogin } from "../../utils";
import { ClockLoader } from "react-spinners";
import { BsBox2Heart } from "react-icons/bs";
import { IoBagCheckSharp } from "react-icons/io5";
import { SlSocialDropbox } from "react-icons/sl";
import { Paths } from "../../routes/Path";

export default function SummaryTotal({ newRefresh }: any) {
  const [summary, setSummary] = useState<any>(0);
  const [summaryConfirm, setSummaryConfirm] = useState<any>(0);
  const [userAuth, setUserAuth]: any = useState({});

  const [queryByCustomer, { loading: loadingData }] = useLazyQuery(
    Schema.summaryOrderAdd,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setSummary(data?.orderCustomerCreates?.total);
      },
    }
  );

  const [queryByCustomerConfirm, { loading: loading }] = useLazyQuery(
    Schema.summaryOrderAdd,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setSummaryConfirm(data?.orderCustomerCreates?.total);
      },
    }
  );

  useEffect(() => {
    setUserAuth(getStaffLogin());
  }, []);

  useEffect(() => {
    queryByCustomer({
      variables: {
        where: {
          isConfirm: 0,
          shop: userAuth?.shop?._id,
        },
      },
    });
  }, [queryByCustomer, newRefresh]);
  useEffect(() => {
    queryByCustomerConfirm({
      variables: {
        where: {
          isConfirm: 1,
          shop: userAuth?.shop?._id,
        },
      },
    });
  }, [queryByCustomerConfirm, newRefresh]);


  return (
    <>
      <div className="row mt-4">
        <div className="col-lg-3 col-12 d-flex">
          <div
            className="dash-count das1"
            style={{ backgroundColor: "#ff5303" }}
          >
            <div className="dash-counts">
              <h1>
                {loadingData ? (
                  <ClockLoader color="#ffff" size={40} />
                ) : (
                  <>{summary ? currency(summary) : 0}</>
                )}
              </h1>
              <h4 className="mt-2">ອໍເດີ່ໃໝ່</h4>
            </div>
            <div className="dash-imgs">
              <SlSocialDropbox />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12 d-flex">
          <div className="dash-count das2">
            <div className="dash-counts">
              <h1>
                {0 ? (
                  <ClockLoader color="#ffff" size={40} />
                ) : (
                  <>{summaryConfirm ? currency(summaryConfirm) : 0}</>
                )}
              </h1>
              <h4 className="mt-2">ອໍເດີ່ຮັບແລ້ວ</h4>
            </div>
            <div className="dash-imgs">
              <IoBagCheckSharp size={60} />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12 d-flex">
          <div className="dash-count " style={{ backgroundColor: "#ebe4e6" }}>
            <div className="dash-counts">
              <h4 className="text-black">
                ກ໋ອບລິ້ງລຸ່ມນີ້ສົ່ງໃຫ້ລູກຄ້າສັ້ງຊື້
              </h4>
              <h1>
                <strong className="user-select-all text-danger customFont">
                  {`https://happyshope.live${Paths.customer_create}?shop=${userAuth?.shop?._id}&add_order`}{" "}
                </strong>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
