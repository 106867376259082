import { useLazyQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Schema from "./Schema";
import {
  InvalidText,
  LocalStorageName,
  messageError,
  messageSuccess,
  placeholderImage,
} from "../../utils";
import { useSearchParams } from "react-router-dom";
import InputNumberFormat from "../../utils/components/InputNumberFormat";
import compressImage from "../../utils/UploadFile";
import axios from "axios";
import { url_path_IBank_Order, url_upload_IBank_Order } from "../../utils/url";

export default function AddOrderOfCustomer({ onSuccess }: any) {
  const [fileMoney, setFileMoney] = useState<any>("");
  const [getIsPaid, setGetIsPaid] = useState<any>(0);
  const [getCOD, setGetCOD] = useState<any>(0);
  const [listShop, setListShop] = useState<any>(0);
  const [gitId, setGetShop]: any = useState({});
  const [params, setParams] = useSearchParams();

  const [createOrderByCustomer, { loading: createTing }]: any = useMutation(
    Schema.createOrderOfCustomer
  );
  const [queryShop, { loading: loadingData }] = useLazyQuery(
    Schema.select_shop,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setListShop(data?.shopPublics?.data);
      },
    }
  );

  useEffect(() => {
    if (gitId) {
      queryShop({
        variables: {
          where: { _id: parseInt(gitId) },
        },
      });
    }
  }, [queryShop, gitId]);

  console.log("listShop", listShop);

  const {
    handleChange,
    errors,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      title: "",
      name: "",
      tell: "",
      shop: "",
      companySend: "",
      cod: 0,
      files_bank: "",
    },
    enableReinitialize: false,
    validate: (values) => {
      const errors: any = {};
      if (!values.title) {
        errors.title = "ກະລຸນາປ້ອນຊື່ຮ້ານ";
      }
      if (!values.name) {
        errors.name = "ກະລຸນາປ້ອນຂໍ້ມູນຕິດຕໍ່";
      }
      if (!values.tell) {
        errors.tell = "ກະລຸນາປ້ອນເພຈ";
      }
      if (!values.companySend) {
        errors.companySend = "ກະລຸນາປ້ອນທີ່ຢູ່";
      }
      if (getIsPaid === 1 && !fileMoney) {
        errors.files_bank = "ກະລຸນາອັບໂຫຼດຫຼັກຖານການໂອນ";
      }
      if (getIsPaid === 0 && !getCOD) {
        errors.cod = "ກະລຸນາປ້ອນຈຳນວນເງິນ COD";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await createOrderByCustomer({
          variables: {
            data: {
              title: values.title,
              name: values.name,
              tell: String(values.tell),
              shop: parseInt(gitId),
              companySend: values.companySend,
              cod: getCOD,
              files_bank: fileMoney,
              isPaid: getIsPaid,
            },
          },
        });
        messageSuccess("ສັ່ງສຳເລັດແລ້ວ");
        setGetCOD(0);
        resetForm();
        setFileMoney("");
      } catch (error) {
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
      }
    },
  });

  useEffect(() => {
    if (getIsPaid === 1) {
      setGetCOD(0);
    }
    if (getIsPaid === 0) {
      setFileMoney("");
    }
    let getId = params.get("shop");
    setGetShop(getId);
  }, [params, getIsPaid, getCOD]);

  const handleChangeMoneyUpload = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setFileMoney(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res: any = await axios.post(url_upload_IBank_Order, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setFileMoney(filename);
        } else {
          setFileMoney("");
          messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  return (
    <>
      <div
        className="header"
        // style={{ backgroundColor: "#ff5303" }}
      >
        <div className="">
          <div className="row">
            <div className="col-3 text-start">
              <img
                src="assets/img/logo.png"
                alt=""
                style={{ height: 55, width: "auto" }}
              />
            </div>
            <div className="col-6 text-center">
              <h2 className="text-center pt-2">ແບບຟອມສັ່ງຊື້ສິນຄ້າ</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5  p-3 ">
        <div
          className="card p-2"
          style={{
            // border: "1px solid orange",
            boxShadow: "inset 0 0 2px orange",
          }}
        >
          ຊື່ເພຈ FB: {listShop[0]?.pageName}
          <br />
          ໂທ & ແອັບ: {listShop[0]?.phoneNumber ?? ""}
          <br />
          {listShop[0]?.accountName && (
            <>
              <br /> {listShop[0]?.accountName}
            </>
          )}
          {listShop[0]?.accountKIP && (
            <>
              <br />
              {listShop[0]?.accountKIP}
            </>
          )}
          {listShop[0]?.accountTHB && (
            <>
              {" "}
              <br />
              {listShop[0]?.accountTHB}
            </>
          )}
          {listShop[0]?.accountUSD && (
            <>
              <br />
              {listShop[0]?.accountUSD}
            </>
          )}
        </div>
        <div className="row">
          <form>
            <div className="mb-3">
              <label>
                <i className="fas fa-layer-group me-1" />
                ຊື່ສິນຄ້າ / ຈຳນວນ {InvalidText()}
              </label>
              <input
                type="search"
                className={
                  errors.title
                    ? "form-control form-control-lg is-invalid"
                    : "form-control form-control-lg  is-valid"
                }
                onWheel={(e: any) => e.target.blur()}
                placeholder="ປ້ອນຊື່ສິນຄ້າ"
                name="title"
                value={values.title}
                onChange={handleChange}
              />
            </div>
            <div style={{ display: "flex" }}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="flexRadioDefault1"
                  value={getIsPaid}
                  checked={getIsPaid === 0}
                  onClick={(e) => setGetIsPaid(0)}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  ເກັບປາຍທາງ COD
                </label>
              </div>
              <div className="form-check ms-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="charge_on_shop"
                  id="flexRadioDefault2"
                  value={getIsPaid}
                  checked={getIsPaid === 1}
                  onClick={(e) => setGetIsPaid(1)}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  ໂອນແລ້ວ
                </label>
              </div>
            </div>
            {getIsPaid === 1 && (
              <>
                <div className="col-12 col-lg-4 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>
                      <i className="fas fa-layer-group me-1" />
                      ກົດ box ລຸ່ມນີ້ເພື່ອອັບໂຫຼດຫຼັກຖານໂອນ {InvalidText()}
                    </label>
                    <div style={{ textAlign: "center" }}>
                      <input
                        type="file"
                        id="file-upload"
                        onChange={handleChangeMoneyUpload}
                        name="file"
                        accept="image/png, image/jpeg"
                        // hidden
                      />
                      <br />
                      <i className="text-danger">{errors.files_bank}</i>
                      <label htmlFor="file-upload">
                        {fileMoney && (
                          <div
                            style={{
                              height: 200,
                              width: 200,
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              backgroundPosition: "center",
                              backgroundSize: "100%",
                              textAlign: "center",
                              borderRadius: 7,
                            }}
                          >
                            <img
                              src={
                                fileMoney
                                  ? url_path_IBank_Order + fileMoney
                                  : placeholderImage(200, 200)
                              }
                              alt={fileMoney}
                              style={{
                                height: 200,
                                width: 200,
                                borderRadius: 7,
                              }}
                            />
                          </div>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="mb-3">
              <label>
                <i className="fas fa-layer-group me-1" />
                ປ້ອນຈຳນວນເງິນ COD {InvalidText()}
              </label>
              <InputNumberFormat
                placeholder="0"
                value={getCOD}
                onChange={(val: any) => setGetCOD(val)}
                className={`form-control form-control-lg ${
                  errors.cod ? "is-invalid" : "is-valid"
                }`}
                //   onWheel={(e) => e.target.blur()}
              />
            </div>

            <div className="mb-3">
              <label>
                <i className="fas fa-layer-group me-1" />
                ຊື່ລູກຄ້າ {InvalidText()}
              </label>
              <input
                type="text"
                autoFocus
                className={
                  errors.name
                    ? "form-control form-control-lg is-invalid"
                    : "form-control form-control-lg  is-valid"
                }
                placeholder="ປ້ອນຊື່ລູກຄ້າ"
                name="name"
                value={String(values.name)}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label>
                <i className="fas fa-layer-group me-1" />
                ເບີໂທ {InvalidText()}
              </label>
              <input
                type="number"
                className={
                  errors.tell
                    ? "form-control form-control-lg is-invalid"
                    : "form-control form-control-lg  is-valid"
                }
                onWheel={(e: any) => e.target.blur()}
                placeholder="ປ້ອນເບີຕິດຕໍ່"
                name="tell"
                value={values.tell}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label>
                <i className="fas fa-layer-group me-1" />
                ຝາກຂົນສົ່ງ {InvalidText()}
              </label>
              <textarea
                rows={3}
                className={
                  errors.companySend
                    ? "form-control form-control-lg is-invalid"
                    : "form-control form-control-lg  is-valid"
                }
                onWheel={(e: any) => e.target.blur()}
                placeholder="ປ້ອນສາຂາ ທີ່ຕ້ອງການຝາກໄປຫາ"
                name="companySend"
                value={values.companySend}
                onChange={handleChange}
              ></textarea>
            </div>
          </form>
        </div>
        <div className="text-end">
          <button
            type="button"
            className="btn btn-success btn-lg w-100"
            disabled={createTing}
            onClick={() => handleSubmit()}
          >
            <i className="icon-check-circle" />
            <span className="ms-1">ຢືນຢັນສັ່ງຊື້</span>
          </button>
        </div>
      </div>
    </>
  );
}
