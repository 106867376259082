import { Button, Card, Form } from "react-bootstrap";
import "./Style.scss";
import logo from ".";
import { LuLogIn } from "react-icons/lu";
import { FiUserPlus } from "react-icons/fi";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import intro from "../../assets/images/shopping2.gif"

export default function LandingScreen() {
  const navigator: any = useNavigate();
  return (
    <div className="app-landing">
      <div className="header-container row">
        <div className="col-12 col-lg-4 text-center">
          <h2 className="text-success fw-bolder">
            ລະບົບຈັດການອໍເດີ່{" "}
            <strong className="text-primary">Happy Shop</strong>
          </h2>
        </div>
        <div className="col-12 col-lg-8 text-end">
          <button type="button" className="btn btn-primary" onClick={()=>navigator('/login')}>
            <LuLogIn /> ເຂົ້າສູ່ລະບົບ
          </button>
          <button type="button" className="btn btn-success mx-4"  onClick={()=>navigator('/register/shop')}>
            <FiUserPlus /> ລົງທະບຽນ
          </button>
        </div>
      </div>
      <div className="row container">
        <div className="col-12">
          <img src={intro} className="w-100"/>
        </div>
        <div className="col-12 col-md-4 col-sm-6 col-lg-4">
          <div className="card">
            <div className="card-header border-bottom py-2 bg-primary text-white">
              <h5>
                <MdOutlineWorkspacePremium /> ແພັກເກັຈທີ່ 1
              </h5>
            </div>
            <div className="card-body">
              <ul className="list-group">
                <li className="list-item">
                  <span>ຈັດການອໍເດີ່ທົ່ວໄປ</span>
                  <span>
                    <i className="icon-check-circle text-success" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ເພີ່ມ User</span>
                  <span>
                    <i className="icon-check-circle text-success" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ເພີ່ມຮ້ານຄ້າ</span>
                  <span>
                    <i className="icon-x-circle text-danger" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ໂຄສະນາ</span>
                  <span>
                    <i className="icon-x-circle text-danger" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ລະບົບສະຕ໋ອກເຄື່ອງ</span>
                  <span>
                    <i className="icon-x-circle text-danger" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ລິ້ງກັບລະບົບຂົນສົ່ງ</span>
                  <span>
                    <i className="icon-x-circle text-danger" />
                  </span>
                </li>
              </ul>
              <div className="w-100 text-end">
                <strong className="fs-4 text-primary">100,000 ກີບ/ເດືອນ</strong>
              </div>
            </div>
            <div className="card-footer p-2">
              {/* <button type="button" className="btn btn-primary w-100" disabled={true} onClick={()=>navigator('/register')}>
                <BsFillCreditCardFill /> ນຳໃຊ້ແພັກເກັຈ
              </button> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 col-sm-6 col-lg-4">
          <div className="card">
            <div className="card-header border-bottom py-2 bg-primary text-white">
              <h5>
                <MdOutlineWorkspacePremium /> ແພັກເກັຈທີ່ 2
              </h5>
            </div>
            <div className="card-body">
              <ul className="list-group">
                <li className="list-item">
                  <span>ຈັດການອໍເດີ່ທົ່ວໄປ</span>
                  <span>
                    <i className="icon-check-circle text-success" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ເພີ່ມ User</span>
                  <span>
                    <i className="icon-check-circle text-success" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ເພີ່ມຮ້ານຄ້າ</span>
                  <span>
                    <i className="icon-check-circle text-success" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ໂຄສະນາ</span>
                  <span>
                  <i className="icon-check-circle text-success" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ລະບົບສະຕ໋ອກເຄື່ອງ</span>
                  <span>
                    <i className="icon-x-circle text-danger" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ລິ້ງກັບລະບົບຂົນສົ່ງ</span>
                  <span>
                    <i className="icon-x-circle text-danger" />
                  </span>
                </li>
              </ul>
              <div className="w-100 text-end">
                <strong className="fs-4 text-primary">300,000 ກີບ/ເດືອນ</strong>
              </div>
            </div>
            {/* <div className="card-footer p-2">
              <button type="button" className="btn btn-primary w-100" disabled={true} onClick={()=>navigator('/register')}>
                <BsFillCreditCardFill /> ນຳໃຊ້ແພັກເກັຈ
              </button>
            </div> */}
          </div>
        </div>
        <div className="col-12 col-md-4 col-sm-6 col-lg-4">
          <div className="card">
            <div className="card-header border-bottom py-2 bg-primary text-white">
              <h5>
                <MdOutlineWorkspacePremium /> ແພັກເກັຈທີ່ 3
              </h5>
            </div>
            <div className="card-body">
              <ul className="list-group">
                <li className="list-item">
                  <span>ຈັດການອໍເດີ່ທົ່ວໄປ</span>
                  <span>
                    <i className="icon-check-circle text-success" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ເພີ່ມ User</span>
                  <span>
                    <i className="icon-check-circle text-success" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ເພີ່ມຮ້ານຄ້າ</span>
                  <span>
                    <i className="icon-check-circle text-success" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ໂຄສະນາ</span>
                  <span>
                  <i className="icon-check-circle text-success" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ລະບົບສະຕ໋ອກເຄື່ອງ</span>
                  <span>
                    <i className="icon-check-circle text-success" />
                  </span>
                </li>
                <li className="list-item">
                  <span>ລິ້ງກັບລະບົບຂົນສົ່ງ</span>
                  <span>
                    <i className="icon-check-circle text-success" />
                  </span>
                </li>
              </ul>
              <div className="w-100 text-end">
                <strong className="fs-4 text-primary">500,000 ກີບ/ເດືອນ</strong>
              </div>
            </div>
            <div className="card-footer p-2">
              {/* <button type="button" className="btn btn-primary w-100" disabled={true} onClick={()=>navigator('/register')}>
                <BsFillCreditCardFill /> ນຳໃຊ້ແພັກເກັຈ
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
