import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { url_path_images, url_path_images_user } from "../url";
import male from "../../assets/images/male.png";
import { placeholderImage } from "..";

export default function PreviewFileUpLoad({
  sentPathImage,
  type,
  width,
  height,
}) {
  const [show, setShow] = useState(false);
  return (
    <React.Fragment>
      <img
        onClick={() => setShow(true)}
        className="center"
        style={{
          borderRadius: 10,
          cursor: "pointer",
          inlineSize: `${width}px!important`,
          blockSize: `${height}px!important`,
        }}
        src={
          sentPathImage && type === "USER"
            ? url_path_images_user + sentPathImage
            : sentPathImage && type === "SHOP"
            ? url_path_images + sentPathImage
            : placeholderImage(200,200)
        }
        alt="Profile"
        loading="lazy"
      />
      <Modal size="xl" show={show} animation={false}>
        <Modal.Header>
          <h3 className="text-center">ລາຍລະອຽດຮູບພາບ</h3>
          <a
            href="javascript:void(0)"
            className="float-end"
            onClick={() => {
              setShow(false);
            }}
          >
            <i
              className="icon icon-x text-white"
              style={{ fontSize: "55px" }}
            />
          </a>
        </Modal.Header>
        <Modal.Body className="">
          <img
            src={
              sentPathImage && type === "USER"
                ? url_path_images_user + sentPathImage
                : sentPathImage && type === "SHOP"
                ? url_path_images + sentPathImage
                : placeholderImage(200,200)
            }
            className="w-100"
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
