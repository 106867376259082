import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Paths } from "../../routes/Path";
import ResponsivePagination from "react-responsive-pagination";
import {
  NO,
  RootVariable,
  closeLoadingScreen,
  currency,
  endOfMonth,
  formatDateTime,
  getLocalShop,
  getStaffLogin,
  getUserRole,
  loadingScreen,
  messageError,
  messageSuccess,
  placeholderImage,
  startOfMonth,
} from "../../utils";
import Schema from "./Schema";
import { useLazyQuery, useMutation } from "@apollo/client";
import SearchDateRange from "../../utils/components/SearchDateRange";
import LimitData from "../../utils/components/LimitData";
import Notiflix from "notiflix";
import SelectShop from "../../utils/components/selectShop";
import { url_path_images } from "../../utils/url";
import SummaryCommission from "./SummaryCommission";
import InvoiceReportScreen from "./InvoiceExport";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import CreateSummaryBIll from "./CreateSummaryBIll";
import { IoMdClose } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import CreateSummaryOne from "./CreateSummaryOne";
export default function CommissionScreen() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [startDate, setStartDate]: any = useState(null);
  const [endDate, setEndDate]: any = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(RootVariable.limit);
  const [totalItem, setTotalItem] = useState(0);

  const [listCommission, setListCommission]: any = useState([]);
  const [newRefresh, setNewRefresh] = useState(false);

  const [updateCommission] = useMutation(Schema.updateCommission);
  const [deletedCommission] = useMutation(Schema.deleteCommission);

  const [userAuth, setUserAuth] = useState<any>({});
  const [localShop, setLocalShop] = useState<any>();
  const [queryCommission, { loading: loadingData }] = useLazyQuery(
    Schema.Commissions,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setListCommission(data?.commissions?.data);
        setTotalItem(data?.commissions?.total);
      },
    }
  );

  useEffect(() => {
    setUserAuth(getStaffLogin());
    setLocalShop(getLocalShop());
  }, []);
  useEffect(() => {
    if (!startDate || !endDate) return;
    let _where = {};
    if (getUserRole() === "SUPER_ADMIN" || getUserRole() === "OWNER") {
      _where = {};
    } else {
      _where = {
        shop: userAuth?.shop?._id ? parseInt(userAuth?.shop?._id) : undefined,
      };
    }
    queryCommission({
      variables: {
        where: {
          createdAtBetWeen: [startDate, endDate],
          isDeleted: 0,
          // ..._where,
        },
        limit: numberRows,
        skip: numberRows * (currentPage - 1),
      },
    });
  }, [
    queryCommission,
    currentPage,
    numberRows,
    startDate,
    endDate,
    newRefresh,
    userAuth,
    localShop,
  ]);
  //pagination
  const totalPage = Math.ceil(totalItem / numberRows);
  useEffect(() => {
    let page = params.get("page");
    let rows = params.get("rows");
    let startDate = params.get("startDate");
    let endDate = params.get("endDate");
    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(startOfMonth());
    }
    if (endDate) {
      setEndDate(endDate);
    } else {
      setEndDate(endOfMonth());
    }

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(RootVariable.limit);
    }
  }, [params]);

  const ConfirmCommission = (id: number) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການຢືນຢັນລາຍການນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await updateCommission({
            variables: {
              data: {
                status: "CONFIRMED",
              },
              where: {
                _id: Number(id),
              },
            },
          });
          messageSuccess("ການດຳເນີນງານສຳເລັດ");
          setNewRefresh(!newRefresh);
        } catch (error) {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };
  const payCommission = (id: number) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການຊຳລະລາຍການນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await updateCommission({
            variables: {
              data: {
                paid: 1,
              },
              where: {
                _id: Number(id),
              },
            },
          });
          messageSuccess("ການດຳເນີນງານສຳເລັດ");
          setNewRefresh(!newRefresh);
        } catch (error) {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };
  const DeletedData = (id: number) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລືນລາຍການນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await deletedCommission({
            variables: {
              where: {
                _id: Number(id),
              },
            },
          });
          messageSuccess("ການດຳເນີນງານສຳເລັດ");
          setNewRefresh(!newRefresh);
        } catch (error) {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  const Rejected = (id: number) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການປະຕິເສດລາຍການນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await updateCommission({
            variables: {
              data: {
                status: "REJECTED",
              },
              where: {
                _id: Number(id),
              },
            },
          });
          messageSuccess("ການດຳເນີນງານສຳເລັດ");
          setNewRefresh(!newRefresh);
        } catch (error) {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <nav aria-label="breadcrumb" className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={() => navigate(Paths.home)}>
                ໜ້າຫຼັກ
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              ການຊຳລະຄ່າບໍລິການ
            </li>
          </ol>
        </nav>
        {getUserRole() === "SUPER_ADMIN" && (
          <>
            {/* ofWeb */}
            <div className="row hiddenDivOfApp">
              <div className="col-md-5 col-12 mt-1">
                <div className="form-group">
                  <SelectShop
                    value={localShop?._id}
                    onChange={(obj: any) => {
                      setLocalShop(obj);
                    }}
                    getSearch={1}
                  />
                </div>
              </div>
              <div className="col-md-5 col-12 pt-2">
                <div className="form-group">
                  <SearchDateRange />
                </div>
              </div>
            </div>
            <SummaryCommission startDate={startDate} endDate={endDate} />
            {/* of App */}
            <div className="row my-3 hiddenDivOfWeb">
              <div className="col-md-12 mt-1">
                <label>ຄົນຫາດ້ວຍຊື່</label>
                <input
                  type="search"
                  placeholder="ຄົ້ນຫາດ້ວຍຊື່..."
                  className="form-control form-control-lg"
                  onChange={(e) => {
                    params.set("search", e.target.value);
                    setParams(params);
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div>
          <CreateSummaryBIll onSuccess={() => setNewRefresh(!newRefresh)} />
          <CreateSummaryOne onSuccess={() => setNewRefresh(!newRefresh)} />
        </div>
        <hr />
        <div className="row my-3">
          <LimitData
            numberRows={numberRows}
            totalPage={totalPage}
            currentPage={currentPage}
            total={totalItem}
            hiddenSearch={true}
            col={6}
          />
        </div>

        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="card p-2">
              {loadingData ? loadingScreen() : closeLoadingScreen()}
              <>
                <div className="table-responsive">
                  <table className="table table-hover table-striped border">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ໂປຣໄຟລ໌</th>
                        <th>ຮ້ານ</th>
                        <th>ຈຳນວນຮ້ານ</th>
                        <th>ລວມຍອດຂາຍ</th>
                        <th>ເປີເຊັນ</th>
                        <th>ລວມຍອດຄ່າຄອມມິສຊັ່ນ</th>
                        <th>ສະຖານະ</th>
                        <th>ວັນທີຊຳລະ</th>
                        <th>ຄຳອະທິບາຍ</th>
                        <th className="text-center">ຈັດການ</th>
                        <th>ພີມ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listCommission &&
                        listCommission?.map((item: any, index: number) => (
                          <tr>
                            <td className="text-center text-nowrap border">
                              {NO(index, currentPage, numberRows)}
                            </td>
                            <td>
                              <div className="profile-image">
                                <img
                                  src={
                                    url_path_images + item?.shop?.profileImage
                                  }
                                  className="image"
                                  data-darkbox={
                                    item?.shop?.profileImage
                                      ? url_path_images +
                                        item?.shop?.profileImage
                                      : placeholderImage(520, 520)
                                  }
                                  data-darkbox-group="two"
                                />
                              </div>
                            </td>
                            <td className="text-nowrap border">
                              {item?.shop?.title ?? "-"}
                              <br />
                              {item?.shop?.phoneNumber ?? "-"}
                            </td>
                            <td
                              className="text-center text-nowrap border"
                              style={{ width: 100 }}
                            >
                              {currency(item?.totalShop) ?? 0}
                            </td>
                            <td className="text-end text-nowrap border">
                              {currency(item?.totalAmount) ?? 0}
                            </td>
                            <td className="text-end text-nowrap border">
                              {currency(item?.percent) ?? 0}%
                            </td>
                            <td className="text-end text-nowrap border">
                              {currency(item?.totalCommission) ?? 0}
                            </td>
                            <td
                              className={`text-center text-nowrap border ${
                                item?.isPaid === 1
                                  ? "text-success"
                                  : "text-danger"
                              }`}
                              style={{ width: 100 }}
                            >
                              {item?.isPaid === 1 ? (
                                "ຊຳລະແລ້ວ"
                              ) : (
                                <>
                                  {item?.status == "PENDING" ? (
                                    <>
                                      <button
                                        className="btn btn-success btn-sm me-1"
                                        onClick={() =>
                                          ConfirmCommission(item?._id)
                                        }
                                      >
                                        <i className="icon icon-check"></i>{" "}
                                        ຢືນຢັນຖືກຕ້ອງ
                                      </button>
                                      <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => Rejected(item?._id)}
                                      >
                                        <IoMdClose className="fs-4" />
                                        ປະຕິເສດ
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <b className="text-danger">ປະຕິເສດແລ້ວ</b>
                                    </>
                                  )}
                                </>
                              )}

                              {item?.isPaid === 0 &&
                                item?.status == "CONFIRMED" &&
                                userAuth?.role === "SUPER_ADMIN" && (
                                  <>
                                    <hr />
                                    <button
                                      className="btn btn-success btn-sm me-1"
                                      onClick={() => payCommission(item?._id)}
                                    >
                                      <i className="icon icon-check"></i>{" "}
                                      ຢືນຢັນຈ່າຍ
                                    </button>
                                  </>
                                )}
                            </td>
                            <td
                              className="text-center text-nowrap border"
                              style={{ width: 100 }}
                            >
                              {item?.paidDate
                                ? formatDateTime(item?.paidDate)
                                : "- -"}
                            </td>
                            <td
                              className="text-center text-nowrap border"
                              width={200}
                            >
                              {item?.description ? item?.description : "- -"}
                            </td>
                            <td
                              className="text-center text-nowrap border"
                              width={200}
                            >
                              {item?.isDeleted === 0  && item?.status == "REJECTED" ? (
                                <>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                      DeletedData(item?._id);
                                    }}
                                  >
                                    <MdDelete className="fs-4" />
                                  </button>
                                </>
                              ) : (
                                "- -"
                              )}
                            </td>
                            <td
                              className="text-center text-nowrap border"
                              width={200}
                            >
                              <div
                                className="btn-group btn-sm py-0"
                                title="ພິມບິນ"
                              >
                                <InvoiceReportScreen data={item} />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="container mt-3">
                  {(() => {
                    return (
                      <ResponsivePagination
                        total={totalPage}
                        current={currentPage || 1}
                        onPageChange={(page: number) => {
                          params.set("page", page.toString());
                          setParams(params);
                        }}
                      />
                    );
                  })()}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
