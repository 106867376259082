import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import Schema from "./Schema";
import { getLocalShop, getStaffLogin, getUserRole } from "..";

type ResponseOnChange = {
  value: number;
  label: string;
};

type ReceiverAddressProps = {
  containerStyle?: React.CSSProperties;
  containerClass?: string;
  disabled?: boolean;
  value?: number;
  getSearch: number;
  onChange?: (e: ResponseOnChange) => void;
  styleConfig?: StylesConfig;
};

export default function SelectShop({
  containerStyle,
  containerClass,
  disabled,
  value,
  getSearch,
  onChange,
}: ReceiverAddressProps) {
  const [selectedOption, setSelectedOption] = useState<any>();
  const [userAuth, setUserAuth] = useState<any>({});
  const [localShop, setLocalShop] = useState<any>();

  const [items, setItems] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [fetchData, { data, loading }] = useLazyQuery(Schema.selectShop, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    setUserAuth(getStaffLogin());
    setLocalShop(getLocalShop());
  }, []);

  useEffect(() => {
    if (getUserRole() === "SUPER_ADMIN") {
      fetchData({
        variables: {
          where: {
            title: searchValue ? searchValue : undefined,
            // isPublic: 1,
            isDeleted: 0,
          },
          limit: 1000,
        },
      });
    } else {
      fetchData({
        variables: {
          where: {
            _id: userAuth?.shop?._id,
            title: searchValue ? searchValue : undefined,
            isPublic: 1,
            isDeleted: 0,
          },
          limit: 30,
        },
      });
    }
  }, [fetchData, searchValue]);

  useEffect(() => {
    if (!data?.shops?.data?.length) {
      setItems([]);
      return;
    }
    if (getSearch) {
      const newData = data?.shops?.data?.map((item: any) => {
        return {
          ...item,
          value: item?._id,
          label:
            item?.title + " |  " + item?.pageName + " | " + item?.phoneNumber,
        };
      });
      setItems([{ value: "", label: "ທັງໝົດ" }, ...newData]);
    } else {
      const newData = data?.shops?.data?.map((item: any) => {
        return {
          ...item,
          value: item?._id,
          label: item?.title + " | " + item?.phoneNumber,
        };
      });
      setItems(newData);
    }
  }, [data]);

  //set value
  useEffect(() => {
    if (value) {
      const result = items?.filter((item: any) => item?._id === Number(value));
      setSelectedOption(result[0] || null);
    } else {
      setSelectedOption(null);
    }
  }, [items, value]);

  return (
    <div
      style={{ minWidth: 200, ...containerStyle }}
      className={containerClass}
    >
      <Select
        isDisabled={disabled}
        value={selectedOption}
        isLoading={loading}
        placeholder={
          loading ? "ກຳລັງໂຫຼດ..." : "ປ້ອນຊື່ຮ້ານ ຫຼື FB-Page ຫຼື ເບີໂທຮ້ານ"
        }
        onChange={(res: any) => {
          onChange?.(res);
        }}
        options={items}
      />
    </div>
  );
}
