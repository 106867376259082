import { useLazyQuery, useMutation } from "@apollo/client";
import Notiflix from "notiflix";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Schema from "../../members/Schema";
import {
  getLocalShop,
  getStaffLogin,
  getUserRole,
  messageError,
  messageSuccess,
} from "../../../utils";
import { useSearchParams } from "react-router-dom";
export default function BlockUsers() {
  const [params, setParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [users, setListUser] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchValuePhone, setSearchValuePhone] = useState("");
  const [newLoadData, setNewLoadData] = useState(false);
  const inputPhone: any = useRef();
  const [localShop, setLocalShop] = useState<any>();

  useEffect(() => {
    setLocalShop(getLocalShop());
  }, []);

  const [queryOrder] = useLazyQuery(Schema.queryUser, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setListUser(data?.users?.data);
    },
  });
  const [updateUser, { loading: updateTing }]: any = useMutation(
    Schema.updateUser
  );

  const [userAuth, setUserAuth]: any = useState({});
  useEffect(() => {
    setUserAuth(getStaffLogin());
  }, []);
  const getRole = getUserRole() === "SUPER_ADMIN";
  useEffect(() => {
    if (getRole) {
      queryOrder({
        variables: {
          where: {
            phoneNumber: searchValuePhone
              ? parseInt(searchValuePhone)
              : undefined,
            fullName: searchValue ? searchValue : undefined,
            isDeleted: 0,
          },
          limit: 100,
        },
      });
    } else {
      queryOrder({
        variables: {
          where: {
            phoneNumber: searchValuePhone
              ? parseInt(searchValuePhone)
              : undefined,
            fullName: searchValue ? searchValue : undefined,
            shop: userAuth?.shop?._id,
            isDeleted: 0,
          },
          limit: 100,
        },
      });
    }
  }, [queryOrder, newLoadData, searchValuePhone, searchValue, userAuth]);

  useEffect(() => {
    const _search = params.get("search");
    const _phone = params.get("phone");
    setSearchValue(_search ? String(_search) : "");
    setSearchValuePhone(_phone ? String(_phone) : "");
  }, [params]);

  const _turnOnBranch = (id: any) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການປົດບລ໋ອກ user ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      () => {
        try {
          const _updated = updateUser({
            variables: {
              data: {
                isPublic: 1,
              },
              where: {
                _id: parseInt(id),
              },
            },
          });
          if (_updated) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setNewLoadData(!newLoadData);
          }
        } catch (error) {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  const _turnOffBranch = (id: any) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການບລ໋ອກ user ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      () => {
        try {
          const _updated = updateUser({
            variables: {
              data: {
                isPublic: 0,
              },
              where: {
                _id: parseInt(id),
              },
            },
          });
          if (_updated) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setNewLoadData(!newLoadData);
          }
        } catch (error) {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <React.Fragment>
      {getUserRole() === "SUPER_ADMIN" || getUserRole() === "OWNER" ? (
        <a href="javaScript:void(0)" onClick={() => setShow(true)}>
          <h4>
            <i className="icon-block me-2" />
            ບລ໋ອກພະນັກງານ
          </h4>
        </a>
      ) : null}
      <Modal
        centered
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        backdrop="static"
        size="lg"
      >
        <Modal.Header>
          <h3 className="text-center">
            <i className="icon-user-plus me-1" />
            ບລ໋ອກພະນັກງານ
          </h3>
          {updateTing ? (
            <>
              <a href="javascript:void(0)" className="float-end">
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                href="javascript:void(0)"
                className="float-end"
                onClick={() => {
                  setShow(false);
                }}
              >
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-3">
            <button className="btn btn-primary">
              <i className="icon-search1" />
            </button>
            <input
              type="search"
              className="form-control"
              id="name3"
              placeholder="ປ້ອນເບີໂທ"
              ref={inputPhone}
              style={{ border: "1px solid #bcbdb5" }}
              onChange={(e) => {
                params.set("phone", e.target.value);
                setParams(params);
              }}
            />
            <button className="btn btn-primary">
              <i className="icon-search1" />
            </button>
            <input
              type="search"
              placeholder="ຄົ້ນຫາດ້ວຍຊື່..."
              className="form-control"
              onChange={(e) => {
                params.set("search", e.target.value);
                setParams(params);
              }}
            />
          </div>

          <div className="table-responsive">
            <table className="table table-bordered table-sm">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-nowrap">ຊື່ ແລະ ນາມສະກຸນ</th>
                  <th className="text-nowrap">ເບີໂທ</th>
                  <th className="text-nowrap">ຈັດການ</th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-nowrap">
                      {item?.fullName ? item?.fullName : "-"}
                    </td>
                    <td className="text-nowrap">
                      {item?.phoneNumber ? item?.phoneNumber : "-"}
                    </td>
                    <td
                      style={{ textAlign: "center", width: "60px" }}
                      className="text-nowrap"
                    >
                      <div className="form-check form-switch">
                        {item?.shop?._id === localShop?._id &&
                        item?._id !== userAuth?._id ? (
                          <>
                            <input
                              id="_dm-dbOfflineContact"
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                Number(item?.isPublic) === 0 ? false : true
                              }
                              onChange={
                                item?.isPublic === 0
                                  ? () => _turnOnBranch(item?._id)
                                  : () => _turnOffBranch(item?._id)
                              }
                            />
                          </>
                        ) : (
                          <>
                            {getUserRole() === "SUPER_ADMIN" && (
                              <>
                                <input
                                  id="_dm-dbOfflineContact"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    Number(item?.isPublic) === 0 ? false : true
                                  }
                                  onChange={
                                    item?.isPublic === 0
                                      ? () => _turnOnBranch(item?._id)
                                      : () => _turnOffBranch(item?._id)
                                  }
                                />
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
