import { useLazyQuery } from "@apollo/client";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import "./index.scss";
import _ from "lodash";
import Schema from "./Schema";
import PrintBill from "./PrintAllBarcode";

export default function PrintComponents() {
  const [items, setItems] = useState<any>([]);
  const [searchParams] = useSearchParams();
  const itemIds = searchParams.get("getIds");
  const getLimit:any = searchParams.get("limit_print");
  const [fetchData, { data: itemsData, loading }] = useLazyQuery(
    Schema.queryOrder,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  useEffect(() => {
    if (!itemIds)return;
      fetchData({
        variables: {
          where: {
            allID: itemIds,
          },
          limit: parseInt(getLimit),
        },
      });
  }, [itemIds,getLimit]);
  useEffect(() => {
    const results = itemsData?.orders?.data;
    setItems(results || []);
    if (!results) return;
      Notiflix.Loading.remove();
      setTimeout(() => {
        window.print();
      }, 400);
    
  }, [itemsData]);

  useEffect(() => {
    if (loading) {
      Notiflix.Loading.standard("ກຳລັງໂຫຼດຂໍ້ມູນ...");
    } else {
      Notiflix.Loading.remove();
    }
  }, [loading]);

  return (
    <>
        {items?.map((obj:any, index:any) => (
          <PrintBill 
          key={index} data={obj}
           />
          ))}
    </>
  );
}
