import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import { useEffect, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import { ImArrowLeft2 } from "react-icons/im";

import {
  ItemSelect,
  NO,
  closeLoadingScreen,
  currency,
  endOfMonth,
  formatDateTime,
  getStaffLogin,
  loadingScreen,
  startOfMonth,
} from "../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootVariable } from "../../utils";
import LimitData from "../../utils/components/LimitData";
import { Paths } from "../../routes/Path";
import PrintData from "./PrintData";

export default function DetailItemOfMoth() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [userAuth, setUserAuth] = useState<any>({});

  const [startDate, setStartDate]: any = useState(null);
  const [endDate, setEndDate]: any = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(RootVariable.limit);
  const [selectedAllItem, setSelectedAllItem] = useState<any>(0);
  const [listOrder, setListOrder] = useState([]);
  const [newRefresh, setNewRefresh] = useState(false);
  const [getId, setId] = useState<any>(null);
  const [getMoth, setMoth] = useState<any>(null);
  const [getYear, setYear] = useState<any>(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setUserAuth(getStaffLogin());
  }, []);

  const [queryOrder, { data: dataItems, loading: loadingData }] = useLazyQuery(
    Schema.queryOrder,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    let _where = {};
    if (getId) {
      _where = {
        shop: parseInt(getId),
      };
    } else {
      _where = {
        shop: userAuth?.shop?._id,
      };
    }
    queryOrder({
      variables: {
        where: {
          ..._where,
          isDeleted: 0,
          orderStatus: "PRINTED",
          forMoth: parseInt(getMoth) ? parseInt(getMoth) : undefined,
          forYear: parseInt(getYear),
        },
        limit: numberRows,
        skip: numberRows * (currentPage - 1),
      },
    });
  }, [
    queryOrder,
    newRefresh,
    userAuth,
    numberRows,
    currentPage,
    getMoth,
    getYear,
    getId,
  ]);

  useEffect(() => {
    const results = dataItems?.orders;
    if (search) {
      const filter = results?.data?.filter((obj: any) => {
        if (
          obj?.trackingId?.includes(search) ||
          obj?.description?.includes(search) ||
          obj?.orderID?.includes(search)
        ) {
          return obj;
        }
      });
      setListOrder(filter);
    } else {
      setListOrder(results?.data || []);
    }
  }, [dataItems, search]);

  //pagination
  const totalItem = dataItems?.orders?.total;
  const totalPage = Math.ceil(totalItem / numberRows);
  useEffect(() => {
    let page = params.get("page");
    let rows = params.get("rows");
    let getId = params.get("get_id_s");
    let getMoth = params.get("for_m");
    let getY = params.get("of_y");
    setId(getId);
    setYear(getY);
    setMoth(getMoth);

    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(startOfMonth());
    }
    if (endDate) {
      setEndDate(endDate);
    } else {
      setEndDate(endOfMonth());
    }

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(RootVariable.limit);
    }
  }, [params]);

  const handleClick = () => {
    const url = `/print_all_bill?getIds=${selectedAllItem}`;
    window.open(url, "_blank");
  };

  const handleClickOne = (id: number, bill: string) => {
    const url = `/print_all_bill?getIds=${id}&bill=${bill}`;
    window.open(url, "_blank");
  };

  return (
    <div className="page-wrapper">
      <div className="content ms-2">
        <nav aria-label="breadcrumb" className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a
                href="javascript:void(0)"
                onClick={() => navigate(Paths.list_order)}
              >
                <ImArrowLeft2 /> ກັບຄຶນ
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              ລາຍງານລາຍລະອຽດອໍເດີ່ພາຍໃນເດືອນ{" "}
              <strong className=" text-danger">{getMoth}</strong>
            </li>
          </ol>
        </nav>
        <div className="row my-3">
          {/* of web */}
          <div className="col-12 col-lg-8 mt-4 hiddenDivOfApp">
            <button
              className="btn btn-warning btn-lg fs-5 events"
              disabled={!selectedAllItem}
              onClick={handleClick}
            >
              <i className="icon-print" /> ພິມລາຍການທີ່ເລືອກ
              {selectedAllItem.length > 0 ? (
                <strong className="box-total">
                  {currency(selectedAllItem.length)}
                </strong>
              ) : null}
            </button>
            <PrintData
              currentPage={currentPage}
              total={totalItem}
              listOrder={listOrder}
              col={6}
            />
          </div>
          {/* end Of wev */}
          {/* oF Mobile */}
          <div className="col-12 col-lg-12  hiddenDivOfWeb d-flex">
            <button
              className="btn btn-warning btn-lg events"
              disabled={!selectedAllItem}
              onClick={handleClick}
            >
              <i className="icon-print" /> ພິມບິນ
              {selectedAllItem.length > 0 ? (
                <strong className="box-total">
                  {currency(selectedAllItem.length)}
                </strong>
              ) : null}
            </button>
            <PrintData total={totalItem} listOrder={listOrder} col={6} />
          </div>
          {/* end Of Mobile */}
        </div>
        <div className="row my-3">
          <LimitData
            numberRows={numberRows}
            totalPage={totalPage}
            currentPage={currentPage}
            total={totalItem}
            hiddenSearch={true}
            col={6}
          />
          <div className=" p-2 col-md-5 text-end float-end ">
            <input
              type="search"
              className="form-control form-control-lg mt-2"
              placeholder="ຄົ້ນຫາຕາມເລກບິນ ຫຼື ຄຳອະທີບາຍ"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 p-2 card">
            {loadingData ? loadingScreen() : closeLoadingScreen()}
            <div className="table-responsive">
              <table className="table table-hover table-striped">
                <thead>
                  <tr>
                    <th className="text-center">
                      <input
                        type="checkbox"
                        className="check-box"
                        id="selected-all"
                        onChange={(e) => setSelectedAllItem(ItemSelect.all(e))}
                      />
                    </th>
                    <th>ຈັດການ</th>
                    <th>#</th>
                    <th>ລະຫັດອໍເດີ່</th>
                    <th className="text-nowrap">ລະຫັດອໍເດີ່ຄິເອງ</th>
                    <th className="text-nowrap">ລາຍລະອຽດອໍເດີ່</th>
                    <th>ສະຖານະອໍເດີ່</th>
                    <th>ວັນທີອໍເດີ່</th>
                    <th className="text-nowrap">ຮັບອໍເດີ່ໂດຍ</th>
                  </tr>
                </thead>
                <tbody>
                  {listOrder &&
                    listOrder?.map((item: any, index) => (
                      <tr
                        style={{
                          backgroundColor:
                            item?.orderStatus === "PRINTED" ? "#eafce6" : "",
                        }}
                      >
                        <td className="text-center" style={{ width: 20 }}>
                          <input
                            type="checkbox"
                            className="check-box item-checkbox"
                            value={item?._id}
                            onChange={() =>
                              setSelectedAllItem(ItemSelect.one())
                            }
                          />
                        </td>
                        <td className="text-center text-nowrap" width={200}>
                          <div className="btn-group btn-sm py-0">
                            <button
                              className="btn btn-outline-primary my-0"
                              onClick={(e) =>
                                handleClickOne(item?._id, item?.orderID)
                              }
                              title="ພິມບິນລາຍການນີ້"
                            >
                              <i className="icon-print" />
                            </button>
                          </div>
                        </td>
                        <td
                          className="text-center text-nowrap"
                          style={{ width: 50 }}
                        >
                          {NO(index, currentPage, numberRows)}
                        </td>
                        <td
                          className="text-center text-nowrap"
                          style={{ width: 120 }}
                        >
                          {item?.orderID ?? "-"}
                        </td>
                        <td>{item?.trackingId ?? "- -"}</td>
                        <td width={300}>{item?.description ?? ".."}</td>
                        <td
                          className="text-center text-nowrap"
                          style={{ width: 100 }}
                        >
                          <span
                            className={
                              item?.orderStatus === "PRINTED"
                                ? "text-success"
                                : item?.orderStatus === "CANCELED"
                                ? "badges bg-lightred"
                                : "text-info"
                            }
                          >
                            {item?.orderStatus === "PRINTED"
                              ? "ພິມບິນແລ້ວ"
                              : item?.orderStatus === "CANCELED"
                              ? "ຍົກເລີກ"
                              : "ລໍຖ້າພິມບິນ"}
                          </span>
                        </td>
                        <td
                          className="text-center text-nowrap"
                          style={{ width: 120 }}
                        >
                          {formatDateTime(item?.createdAt) ?? "- -"}
                        </td>
                        <td className="text-nowrap" style={{ width: 120 }}>
                          {item?.createdBy?.fullName ?? "- -"}
                          <br />
                          {item?.createdBy?.phoneNumber ?? "- -"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="container mt-3">
              {(() => {
                return (
                  <ResponsivePagination
                    total={totalPage}
                    current={currentPage || 1}
                    onPageChange={(page: number) => {
                      params.set("page", page.toString());
                      setParams(params);
                    }}
                  />
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
