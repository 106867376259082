import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Paths } from "../../routes/Path";
import ResponsivePagination from "react-responsive-pagination";
import {
  NO,
  RootVariable,
  closeLoadingScreen,
  currency,
  endOfMonth,
  formatDateTime,
  getLocalShop,
  getStaffLogin,
  getUserRole,
  loadingScreen,
  messageError,
  messageSuccess,
  startOfMonth,
  userRole,
} from "../../utils";
import Schema from "./Schema";
import { useLazyQuery, useMutation } from "@apollo/client";
import SearchDateRange from "../../utils/components/SearchDateRange";
import LimitData from "../../utils/components/LimitData";
import Notiflix from "notiflix";
import InvoiceReportScreen from "./InvoiceExport";
import NoData from "../../utils/components/NoData";
import PayPackagePrice from "./PayPackagePrice";
import FileTransfer from "./FileTransfer";
import SelectShop from "../../utils/components/selectShop";
import SummaryInvoice from "./SummaryInvoice";
import moment from "moment";
export default function InvoiceScreen() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(RootVariable.limit);
  const [startDate, setStartDate]: any = useState(null);
  const [endDate, setEndDate]: any = useState(null);
  const [totalItem, setTotalItem] = useState(0);

  const [invoice, setInvoice]: any = useState([]);
  const [lastInvoice, setLastInvoice]: any = useState([]);
  const [newRefresh, setNewRefresh] = useState(false);

  const [updateInvoice] = useMutation(Schema.updateInvoice);
  const [createInvoice] = useMutation(Schema.createInvoice);

  const [userAuth, setUserAuth] = useState<any>({});
  const [localShop, setLocalShop] = useState<any>();
  const [queryInvoice, { loading: loadingData }] = useLazyQuery(
    Schema.Invoice,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setInvoice(data?.invoices?.data);
        setTotalItem(data?.invoices?.total);
      },
    }
  );
  const [queryLastInvoice, { loading: loadingLastData }] = useLazyQuery(
    Schema.Invoice,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setLastInvoice(data?.invoices?.data[0]);
      },
    }
  );

  // useEffect(() => {
  //   queryLastInvoice({
  //     variables: {
  //       where: {
  //         isDeleted: 0,
  //         isPaid: 1,
  //         isReceive: 1,
  //         shop: parseInt(localShop?._id),
  //       },
  //       limit: 1,
  //     },
  //   });
  // }, [localShop]);

  useEffect(() => {
    queryLastInvoice({
      variables: {
        where: {
          isDeleted: 0,
          // isPaid: 1,
          // isReceive: 1,
          // shop: parseInt(localShop?._id),
        },
        limit: 1,
      },
    });
  }, [localShop]);

  useEffect(() => {
    setUserAuth(getStaffLogin());
    setLocalShop(getLocalShop());
  }, []);
  useEffect(() => {
    if (!startDate || !endDate) return;
    let _where = {};
    if (localShop) {
      _where = {
        shop: localShop?._id ? parseInt(localShop?._id) : undefined,
      };
    } else {
      _where = {
        shop: userAuth?.shop?._id ? parseInt(userAuth?.shop?._id) : undefined,
      };
    }
    queryInvoice({
      variables: {
        where: {
          isDeleted: 0,
          invoiceDateBetween: [startDate, endDate],
          ..._where,
        },
        limit: numberRows,
        skip: numberRows * (currentPage - 1),
      },
    });
  }, [
    queryInvoice,
    currentPage,
    numberRows,
    startDate,
    endDate,
    newRefresh,
    userAuth,
    localShop,
  ]);

  //pagination
  const totalPage = Math.ceil(totalItem / numberRows);
  useEffect(() => {
    let page = params.get("page");
    let rows = params.get("rows");
    let startDate = params.get("startDate");
    let endDate = params.get("endDate");
    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(startOfMonth());
    }
    if (endDate) {
      setEndDate(endDate);
    } else {
      setEndDate(endOfMonth());
    }

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(RootVariable.limit);
    }
  }, [params]);

  const _receiveMoney = (id: number) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການຊຳລະລາຍການນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await updateInvoice({
            variables: {
              data: {
                isReceive: 1,
                receiveMoneyName: userAuth?.fullName,
              },
              where: {
                _id: Number(id),
              },
            },
          });
          messageSuccess("ການດຳເນີນງານສຳເລັດ");
          setNewRefresh(!newRefresh);
        } catch (error) {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };
  const createNewInvoice = () => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການສ້າງບິນໃໝ່ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          await createInvoice({
            variables: {
              data: {
                shop: parseInt(localShop?._id),
              },
            },
          });
          messageSuccess("ການດຳເນີນງານສຳເລັດ");
          setNewRefresh(!newRefresh);
        } catch (error) {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  const checkDay = (date: string) => {
    if (!date) {
      date = startOfMonth();
    }
    const now = moment();
    const paidOfDate = moment(date).format("DD/MM/YYYY HH:mm:ss");
    const then = moment(paidOfDate, "DD/MM/YYYY HH:mm:ss");
    //here i try the difference
    const diffTime = moment.duration(now.diff(then));
    return diffTime.days();
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <nav aria-label="breadcrumb" className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={() => navigate(Paths.home)}>
                ໜ້າຫຼັກ
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              ການຊຳລະຄ່າບໍລິການ
            </li>
          </ol>
        </nav>
        {getUserRole() === "SUPER_ADMIN" && (
          <>
            {/* ofWeb */}
            <div className="row hiddenDivOfApp">
              {/* <div className="col-md-2 mt-1">
                <button
                  className="btn btn-primary btn-lg w-100"
                  onClick={() => createNewInvoice()}
                  disabled={
                    checkDay(lastInvoice?.paidOfDate) < 1 ||
                    getUserRole() !== "SUPER_ADMIN"
                  }
                >
                  <i className="icon-plus-circle" /> ສ້າງບິນໃໝ່
                </button>
              </div> */}
              <div className="col-md-5 col-12 mt-1">
                <div className="form-group">
                  <SelectShop
                    value={localShop?._id}
                    onChange={(obj: any) => {
                      setLocalShop(obj);
                    }}
                    getSearch={1}
                  />
                </div>
              </div>
              <div className="col-md-5 col-12 pt-2">
                <div className="form-group">
                  <SearchDateRange />
                </div>
              </div>
            </div>
            <SummaryInvoice startDate={startDate} endDate={endDate} />
            {/* of App */}
            <div className="row my-3 hiddenDivOfWeb">
              <div className="col-md-12 mt-1">
                <label>ຄົນຫາດ້ວຍຊື່</label>
                <input
                  type="search"
                  placeholder="ຄົ້ນຫາດ້ວຍຊື່..."
                  className="form-control form-control-lg"
                  onChange={(e) => {
                    params.set("search", e.target.value);
                    setParams(params);
                  }}
                />
              </div>
            </div>
            <hr />
          </>
        )}

        <div className="row my-3">
          <LimitData
            numberRows={numberRows}
            totalPage={totalPage}
            currentPage={currentPage}
            total={totalItem}
            hiddenSearch={true}
            col={6}
          />
        </div>

        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="card p-2">
              {loadingData ? loadingScreen() : closeLoadingScreen()}
              <>
                <div className="table-responsive">
                  <table className="table table-hover table-striped border">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ຮ້ານ</th>
                        <th>ເລກທີບິນ</th>
                        <th>ແພັກເກັຈ</th>
                        <th>ວັນທີສະຫຼຸບບິນ</th>
                        <th>ຈຳນວນ  USER</th>
                        <th>ຈຳນວນເງິນ</th>
                        <th>ວັນທີ່ຈ່າຍຫຼ້າສຸດ</th>
                        <th>ວັນທີ່ຕ້ອງຈ່າຍ</th>
                        <th>ສະຖານະ</th>
                        <th>ວັນທີຊຳລະ</th>
                        <th>ຄຳອະທິບາຍ</th>
                        <th>ຈັດການ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice &&
                        invoice?.map((item: any, index: number) => (
                          <tr>
                            <td className="text-center text-nowrap border">
                              {NO(index, currentPage, numberRows)}
                            </td>
                            <td className="text-nowrap border">
                              {item?.shop?.title ?? "-"}
                              <br />
                              {item?.shop?.phoneNumber ?? "-"}
                            </td>
                            <td
                              className="text-center text-nowrap border"
                              style={{ width: 100 }}
                            >
                              {item?.invoiceID ?? "-"}
                            </td>
                            <td
                              className="text-center text-nowrap border"
                              style={{ width: 100 }}
                            >
                              {item?.package?.packageName
                                ? item?.package?.packageName
                                : "-"}{" "}
                              ເດືອນ
                            </td>
                            <td
                              className="text-center text-nowrap border"
                              style={{ width: 100 }}
                            >
                              {formatDateTime(item?.invoiceDate) ?? "-"}
                            </td>
                            <td className="text-end text-nowrap border">
                              {currency(item?.total_user) ?? 0} *  {currency(item?.price_of_user) ?? 0}  ກີບ
                            </td>
                            <td className="text-end text-nowrap border">
                              {currency(item?.totalAmount) ?? 0} ກີບ
                            </td>
                            <td
                              className="text-center text-nowrap border"
                              style={{ width: 100 }}
                            >
                              {item?.paidDate
                                ? formatDateTime(item?.paidDate)
                                : "- -"}
                            </td>
                            <td
                              className="text-center text-nowrap border"
                              width={200}
                            >
                              {item?.nextPayDate
                                ? formatDateTime(item?.nextPayDate)
                                : "- -"}
                            </td>
                            <td
                              className="text-center text-nowrap border"
                              width={200}
                            >
                              {item?.isPaid === 1 ? (
                                <>
                                  {item?.isReceive === 1 ? (
                                    <>
                                      <b className="text-success">
                                        ຊຳລະສຳເລັດ{" "}
                                      </b>
                                    </>
                                  ) : (
                                    <>
                                      {item?.isReceive === 0 && (
                                        <>
                                          {getUserRole() === "SUPER_ADMIN" ? (
                                            <>
                                              <button
                                                type="button"
                                                className="btn btn-success my-0"
                                                onClick={() =>
                                                  _receiveMoney(item?._id)
                                                }
                                              >
                                                <i className="icon-check-circle me-1" />
                                                ຢືນຢັນຮັບເງິນ
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              <b className="text-danger">
                                                ລໍຊຳລະ...
                                              </b>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <PayPackagePrice
                                    idShop={item?._id}
                                    onSuccess={() => setNewRefresh(!newRefresh)}
                                  />
                                </>
                              )}
                            </td>
                            <td className="border">
                              {item?.fileMoney ? (
                                <>
                                  <FileTransfer
                                    sentPathImage={
                                      item?.fileMoney ? item?.fileMoney : ""
                                    }
                                    width={120}
                                    height={120}
                                  />
                                </>
                              ) : (
                                <>
                                  <b> No file</b>
                                </>
                              )}
                            </td>
                            <td
                              className="text-center text-nowrap border"
                              width={200}
                            >
                              {item?.description ?? "- -"}
                            </td>
                            <td
                              className="text-center text-nowrap border"
                              width={200}
                            >
                              <div
                                className="btn-group btn-sm py-0"
                                title="ພິມບິນ"
                              >
                                <InvoiceReportScreen data={item} />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="container mt-3">
                  {(() => {
                    return (
                      <ResponsivePagination
                        total={totalPage}
                        current={currentPage || 1}
                        onPageChange={(page: number) => {
                          params.set("page", page.toString());
                          setParams(params);
                        }}
                      />
                    );
                  })()}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
