import "./Style.scss";
import { FaRegTimesCircle, FaUserPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaRegCircleCheck } from "react-icons/fa6";
import {
  InvalidText,
  LocalStorageName,
  clearLocalStorage,
  getUserRole,
  messageError,
  messageSuccess,
  notifyError,
  notifySuccess,
  onLoading,
  placeholderImage,
} from "../../utils";
import {
  url_path_images,
  url_path_images_user,
  url_upload_images,
  url_upload_images_user,
} from "../../utils/url";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import axios from "axios";
import compressImage from "../../utils/UploadFile";
import { useEffect, useState } from "react";
import Schema from "../members/Schema";
import { Form } from "react-bootstrap";
import { Confirm } from "notiflix";
export default function RegisterUserScreen() {
  const navigator: any = useNavigate();
  const [image, setImage] = useState("");
  const [localShop, setLocalShop] = useState<any>();
  useEffect(() => {
    const local: any = localStorage.getItem("SHOP");
    const res: any = JSON.parse(local);
    setLocalShop(res);
  }, []);


  const [createUser, { loading: createTing }]: any = useMutation(
    Schema.createUser
  );

  const [deleteShopPing, { loading: deleteTing }]: any = useMutation(
    Schema.deleteUser
  );

  type Values = {
    fullName: string;
    phoneNumber: number;
    password: string;
    role: string;
    shop: number;
    userProfile: string;
  };
  type Errors = {
    fullName?: string;
    phoneNumber?: string;
    password?: string;
    shop?: string;
  };

  const handleChangeImageUpload = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setImage(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res: any = await axios.post(url_upload_images_user, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setImage(filename);
        } else {
          setImage("");
          messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  // useFormik
  const {
    values,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik<Values>({
    initialValues: {
      fullName: "",
      phoneNumber: 0,
      password: "",
      role: "",
      shop: 0,
      userProfile: "",
    },
    validate: (values: Values) => {
      const errors: Errors = {};
      if (!values?.fullName) errors.fullName = "ກາລຸນາປ້ອນຊື່";
      if (!values?.phoneNumber) errors.phoneNumber = "ກາລຸນາປ້ອນເບີໂທ";
      if (!values?.password) errors.password = "ກະລຸນາປ້ອນລະຫັດຜ່ານ";
      return errors;
    },

    onSubmit: async (values: Values) => {
      try {
        const { data: created } = await createUser({
          variables: {
            data: {
              userProfile: String(image),
              fullName: values?.fullName,
              phoneNumber: values?.phoneNumber,
              password: values?.password,
              shop: values?.shop ? values?.shop : localShop?._id,
              role: "OWNER",
            },
          },
        });
        if (created) {
          messageSuccess("ດຳເນີນງານສຳເລັດແລ້ວ");
          resetForm();
          setTimeout(() => {
            navigator("/login");
          }, 300);
        } else {
          messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
        }
      } catch (error) {
        console.log(error);
        messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
      }
    },
  });

  const deleteShop = () => {
    Confirm.show(
      "ເເຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການຍົກເລີກນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        const deleteShop = await deleteShopPing({
          variables: {
            where: {
              _id: parseInt(localShop?._id),
            },
          },
        });
        if (deleteShop) {
          notifySuccess("ການດຳເນີນງານສຳເລັດ");
          setTimeout(() => {
            navigator("/landing");
            clearLocalStorage()
          }, 300);
        } else {
          notifyError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <div className="app-landing">
      <div className="header-container row">
        <div className="col-12 col-lg-4 text-center">
          <h2 className="text-success fw-bolder">
            ລະບົບຈັດການອໍເດີ່{" "}
            <strong className="text-primary">Happy Shop</strong>
          </h2>
        </div>
      </div>
      <div className="row container">
        <div className="col-12">
          <div className="card">
            <div className="card-header border-bottom py-2 bg-primary text-white">
              <h5>
                <FaUserPlus /> ລົງທະບຽນຜູ້ໃຊ້ລະບົບ
              </h5>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <div className="form-group">
                      <div style={{ textAlign: "center" }}>
                        <input
                          type="file"
                          id="file-upload"
                          onChange={handleChangeImageUpload}
                          name="file"
                          accept="image/png, image/gif, image/jpeg"
                          hidden
                        />
                        <label htmlFor="file-upload">
                          <div
                            style={{
                              height: 200,
                              width: 200,
                              cursor: "pointer",
                              display: "flex",
                              backgroundImage: `url(${onLoading})`,
                              justifyContent: "center",
                              backgroundPosition: "center",
                              backgroundSize: "100%",
                              textAlign: "center",
                              borderRadius: 7,
                            }}
                          >
                            {image ? (
                              <img
                                src={
                                  image
                                    ? url_path_images_user + image
                                    : placeholderImage(200, 200)
                                }
                                alt={image}
                                style={{
                                  height: 200,
                                  width: 200,
                                  borderRadius: 7,
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  backgroundImage: `url(${placeholderImage(
                                    200,
                                    200
                                  )})`,
                                  display: "flex",
                                  height: 200,
                                  width: 200,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: 7,
                                  backgroundSize: "100%",
                                }}
                              ></div>
                            )}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-8">
                    <h4>ໄອດີ: {localShop?.shopID}</h4>
                    <h4>ຊື່ຮ້ານ: {localShop?.title}</h4>
                    <h4>ຊື່ເພຈ໌: {localShop?.pageName}</h4>
                    <h4>ເບີໂທຕິດຕໍ່: {localShop?.phoneNumber}</h4>
                    <hr />
                  </div>
                </div>
                <Form.Group className="mb-2">
                  <Form.Label>ຊື່ ແລະ ນາມສະກຸນ {InvalidText()}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="ຊື່ ແລະ ນາມສະກຸນ"
                    onWheel={(e) => e.currentTarget.blur()}
                    name="fullName"
                    autoFocus
                    value={values?.fullName || ""}
                    onChange={handleChange}
                    isInvalid={errors?.fullName ? true : false}
                    required
                    className="form-control form-control-lg"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fullName}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-2">
                  <Form.Label>ເບີຕິດຕໍ່ {InvalidText()}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="ປ້ອນເບີຕິດຕໍ່"
                    onWheel={(e) => e.currentTarget.blur()}
                    name="phoneNumber"
                    value={values?.phoneNumber || ""}
                    onChange={handleChange}
                    isInvalid={errors?.phoneNumber ? true : false}
                    required
                    className="form-control form-control-lg"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phoneNumber}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>ລະຫັດຜ່ານ {InvalidText()}</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="ລະຫັດຜ່ານ"
                    onWheel={(e) => e.currentTarget.blur()}
                    name="password"
                    value={values?.password || ""}
                    onChange={handleChange}
                    isInvalid={errors?.password ? true : false}
                    required
                    className="form-control form-control-lg"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
              </form>
            </div>
            <div className="card-footer p-2">
              <div className="row">
                <div className="col-6">
                  {" "}
                  <button
                    type="button"
                    className="btn btn-success w-100"
                    onClick={() => handleSubmit()}
                    disabled={createTing}
                  >
                    <FaRegCircleCheck /> ບັນທຶກ
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-danger w-100"
                    onClick={() => deleteShop()}
                    disabled={createTing}
                  >
                    <FaRegTimesCircle /> ຍົກເລີກ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
