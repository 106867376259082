import { useLazyQuery, useMutation } from "@apollo/client";
import Notiflix from "notiflix";
import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Schema from "../../members/Schema";
import { useSearchParams } from "react-router-dom";
import {
  getLocalShop,
  getStaffLogin,
  getUserRole,
  messageError,
  messageSuccess,
} from "../../../utils";
export default function ChangePassword() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [params, setParams] = useSearchParams();
  const [newLoadData, setNewLoadData] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [getIndex, setGetIndex] = useState(0);
  const [text, setText] = useState("");
  const [listUser, setListUser] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchValuePhone, setSearchValuePhone] = useState("");
  const [localShop, setLocalShop] = useState<any>();
  const [userAuth, setUserAuth]: any = useState({});

  const [queryOrder] = useLazyQuery(Schema.queryUser, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setListUser(data?.users?.data);
    },
  });
  const [updateUser, { loading: updateTing }]: any = useMutation(
    Schema.updateUser
  );

  useEffect(() => {
    setLocalShop(getLocalShop());
  }, []);
  useEffect(() => {
    setUserAuth(getStaffLogin());
  }, []);

  const getRole = getUserRole() === "SUPER_ADMIN";
  useEffect(() => {
    let whereInput = {};
    let whereId = {};
    if (searchValue) {
      whereId = {
        fullName: searchValue ? searchValue : undefined,
      };
    }
    if (searchValuePhone) {
      whereInput = {
        phoneNumber: searchValuePhone ? parseInt(searchValuePhone) : undefined,
      };
    }
    // queryOrder({
    //   variables: {
    //     where: {
    //       ...whereInput,
    //       ...whereId,
    //     },
    //     limit: 10,
    //   },
    // });
    if (getRole) {
      queryOrder({
        variables: {
          where: {
            phoneNumber: searchValuePhone
              ? parseInt(searchValuePhone)
              : undefined,
            fullName: searchValue ? searchValue : undefined,
            isDeleted: 0,
          },
          limit: 100,
        },
      });
    } else {
      queryOrder({
        variables: {
          where: {
            ...whereInput,
            ...whereId,
            shop: userAuth?.shop?._id,
            isDeleted: 0,
          },
          limit: 100,
        },
      });
    }
  }, [queryOrder, searchValuePhone, searchValue, localShop, userAuth]);

  useEffect(() => {
    const _search = params.get("search");
    const _phone = params.get("phone");
    setSearchValue(_search ? String(_search) : "");
    setSearchValuePhone(_phone ? String(_phone) : "");
  }, [params]);

  const _changeRole = (id: any) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການປ່ຽນລະຫັດຜ່ານ user ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      () => {
        try {
          const _updated: any = updateUser({
            variables: {
              data: {
                password: String(text),
              },
              where: {
                _id: parseInt(id),
              },
            },
          });
          if (_updated) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setText(text);
            setNewLoadData(!newLoadData);
            setEditStatus(false);
            setShow(false);
          }
        } catch (error) {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };
  return (
    <React.Fragment>
      {getUserRole() === "SUPER_ADMIN" || getUserRole() === "OWNER" ? (
        <a href="javaScript:void(0);" onClick={() => setShow(true)}>
          <h4>
            <i className="icon-lock me-2" />
            ປ່ຽນລະຫັດຜ່ານພະນັກງານ
          </h4>
        </a>
      ) : null}
      <Modal
        centered
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        backdrop="static"
        size="lg"
      >
        <Modal.Header>
          <h3 className="text-center">
            <i className="icon-user-plus me-1" />
            ປ່ຽນລະຫັດຜ່ານພະນັກງານ
          </h3>
          {updateTing ? (
            <>
              <a href="javascript:void(0)" className="float-end">
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                href="javascript:void(0)"
                className="float-end"
                onClick={() => {
                  setShow(false);
                }}
              >
                <i
                  className="icon icon-x text-white"
                  style={{ fontSize: "55px" }}
                />
              </a>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-3">
            <button className="btn btn-primary">
              <i className="icon-search1" />
            </button>
            <input
              type="search"
              placeholder="ຄົ້ນຫາດ້ວຍເບີ..."
              className="form-control me-3"
              onChange={(e) => {
                params.set("phone", e.target.value);
                setParams(params);
              }}
            />
            <button className="btn btn-primary">
              <i className="icon-search1" />
            </button>
            <input
              type="search"
              placeholder="ຄົ້ນຫາດ້ວຍຊື່..."
              className="form-control"
              onChange={(e) => {
                params.set("search", e.target.value);
                setParams(params);
              }}
            />
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-sm">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-nowrap">ຊື່ ແລະ ນາມສະກຸນ</th>
                  <th className="text-nowrap">ເບີໂທ</th>
                  <th className="text-nowrap">ລະຫັດຜ່ານ</th>
                  <th className="text-nowrap">ຈັດການ</th>
                </tr>
              </thead>
              <tbody>
                {listUser?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-nowrap">
                      {item?.fullName ? item?.fullName : "-"}
                    </td>
                    <td className="text-nowrap">
                      {item?.phoneNumber ? item?.phoneNumber : "-"}
                    </td>
                    <td className="text-center text-nowrap">
                      {editStatus && getIndex === item?._id ? (
                        <input
                          type="text"
                          autoFocus
                          onChange={(e) => setText(e.target.value)}
                          className="form-control text-center"
                          placeholder="* * * * * *"
                        />
                      ) : (
                        "* * * * * *"
                      )}
                    </td>

                    <td style={{ textAlign: "center" }} className="text-nowrap">
                      {editStatus && getIndex === item?._id ? (
                        <>
                          {editStatus &&
                          getIndex === item?._id &&
                          text.length > 0 ? (
                            <>
                              <a
                                href="javaScript:void(0)"
                                onClick={() => _changeRole(item?._id)}
                                style={{ textDecoration: "none" }}
                              >
                                <i className="icon-check text-success fa-2x" />
                              </a>
                            </>
                          ) : (
                            <>
                              <a
                                href="javaScript:void(0)"
                                onClick={() => {
                                  setEditStatus(false);
                                }}
                                style={{ textDecoration: "none" }}
                              >
                                ກະລູນາປ້ອນລະຫັດຜ່ານ
                              </a>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {item?.shop?._id === localShop?._id ? (
                            <a
                              href="javaScript:void(0)"
                              onClick={() => {
                                setEditStatus(true);
                                setGetIndex(item?._id);
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <i className="icon-edit text-primary" />
                            </a>
                          ) : (
                            <>
                              {getUserRole() === "SUPER_ADMIN" && (
                                <>
                                  <a
                                    href="javaScript:void(0)"
                                    onClick={() => {
                                      setEditStatus(true);
                                      setGetIndex(item?._id);
                                    }}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <i className="icon-edit text-primary" />
                                  </a>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
