import { gql } from "@apollo/client";

export default class Schema {
  static queryCreateOrder = gql`
    query OrderCustomerCreates(
      $skip: Int
      $where: GenerateWhereInput
      $limit: Int
    ) {
      orderCustomerCreates(skip: $skip, where: $where, limit: $limit) {
        total
        data {
          _id
          cod
          companySend
          createdAt
          isDeleted
          isConfirm
          name
          files_bank
          isPaid
          shop {
            title
            _id
          }
          title
          tell
        }
      }
    }
  `;

  static summaryOrderAdd = gql`
    query OrderCustomerCreates(
      $skip: Int
      $where: GenerateWhereInput
      $limit: Int
    ) {
      orderCustomerCreates(skip: $skip, where: $where, limit: $limit) {
        total
      }
    }
  `;

  static createOrderOfCustomer = gql`
    mutation CreateOrderOfCustomer($data: OrderCustomerCreateInput!) {
      createOrderOfCustomer(data: $data) {
        status
      }
    }
  `;

  static updateManyOrder = gql`
    mutation UpdateManyOrderCustomerCreate(
      $data: OrderUpdateOne!
      $where: allUpdate!
    ) {
      updateManyOrderCustomerCreate(data: $data, where: $where) {
        status
      }
    }
  `;
  static editData = gql`
    mutation UpdateOrderCustomerAdd(
      $data: OrderCustomerCreateInput!
      $where: WhereById!
    ) {
      updateOrderCustomerAdd(data: $data, where: $where) {
        status
      }
    }
  `;

  static select_shop = gql`
    query Data($where: ShopPublicWhere) {
      shopPublics(where: $where) {
        data {
          _id
          title
          accountKIP
          accountName
          accountTHB
          accountUSD
          address
          description
          pageName
          phoneNumber
          profileImage
        }
      }
    }
  `;
   static updateShopAccount = gql`
   mutation UpdateShop($data: ShopInput!, $where: WhereById!) {
     updateShop(data: $data, where: $where) {
       status
     }
   }
 `;
}
