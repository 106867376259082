import Schema from "./Schema";
import { useEffect, useState } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../routes/Path";
import { getStaffLogin } from "../../utils";
import whatsapp from "../../img/WhatsApp.png";
import facebook from "../../img/fb.jpeg";

export default function SupportTeam() {
  const navigate = useNavigate();
  const [userAuth, setUserAuth] = useState<any>({});

  useEffect(() => {
    setUserAuth(getStaffLogin());
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content ms-2">
        <nav aria-label="breadcrumb" className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={() => navigate(Paths.home)}>
                ໜ້າຫຼັກ
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              ຕິດຕໍ່ຫາທີມງານ
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="p-2 card">
            <h3>
              <img
                style={{ width: 20 }}
                src={facebook}
                alt=""
                className="mt-2 me-2"
              />
              Page: happyShoppe
              <a
                className="ms-2"
                href="https://www.facebook.com/profile.php?id=61550846035190&mibextid=LQQJ4d"
                target="blank"
              >
                <u className="text-info">ກົດລີ້ງ .</u>
              </a>
            </h3>
          </div>
          <div className="p-2 card" style={{marginTop:-18}}>
            <h3>
              <img
                style={{ width: 25 }}
                src={whatsapp}
                alt=""
                className="d-inline me-2"
              />
              ທີມ support ອຸປະກອນ:
              <a
                className="ms-2"
                href={`https://api.whatsapp.com/send?phone=8562052556559&text=ສະບາຍດີ ແຊັດຈາກຮ້ານ ${userAuth?.shop?.title} `}
                target="blank"
              >
                <u className="text-info">02052556559</u>
              </a>
            </h3>
          </div>
          <div className="p-2 card" style={{marginTop:-18}}>
            <h3>
              <img
                style={{ width: 25 }}
                src={whatsapp}
                alt=""
                className="d-inline me-2"
              />
              ທີມພັດທະນາລະບົບ:
              <a
                className="text-link ms-2 text-start"
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=8562076499909&text=ສະບາຍດີ ແຊັດຈາກຮ້ານ ${userAuth?.shop?.title} `}
              >
                <u className="text-info">02076499909</u>
              </a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
